import _defineProperty2 from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { warning } from '@jutro/logger';
import { uniqueInnerId, ContextConsumer } from '@jutro/platform';
import { chevronAlignProptypes, intlMessageShape } from '@jutro/prop-types';
import { TranslatorContext } from '@jutro/locale';
import styles from "./Accordion.module.css";
import { Collapse } from '../collapse/Collapse';
import { AccordionContext } from './AccordionContext';
import { Chevron } from '../chevron/Chevron';
import { getKeyPressHandler } from '../../accessibility/getKeyPressHandler';
import { Icon } from '../Icon/Icon';
export var AccordionCard = function (_PureComponent) {
  _inherits(AccordionCard, _PureComponent);
  var _super = _createSuper(AccordionCard);
  function AccordionCard() {
    var _this;
    _classCallCheck(this, AccordionCard);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    _this = _super.call.apply(_super, [this].concat(args));
    _defineProperty(_assertThisInitialized(_this), "translator", _this.context);
    _defineProperty(_assertThisInitialized(_this), "state", {
      focused: false
    });
    _defineProperty(_assertThisInitialized(_this), "toggleFunc", function (onToggleOpen) {
      var id = _this.props.id;
      if (onToggleOpen) {
        onToggleOpen(id);
      } else {
        warning("The Card with id='".concat(id, "' was clicked but does not have an 'onToggleOpen' function"));
      }
    });
    _defineProperty(_assertThisInitialized(_this), "getHeadingAccessibilityProperties", function (isOpen) {
      var _uniqueInnerId = uniqueInnerId(_this.props.id, 'contentId'),
        contentId = _uniqueInnerId.contentId;
      return {
        'aria-controls': contentId,
        'aria-expanded': isOpen
      };
    });
    _defineProperty(_assertThisInitialized(_this), "getCardContentAccessibilityProperties", function () {
      var _uniqueInnerId2 = uniqueInnerId(_this.props.id, 'headingId'),
        headingId = _uniqueInnerId2.headingId;
      return {
        'aria-labelledby': headingId,
        'aria-live': 'assertive'
      };
    });
    _defineProperty(_assertThisInitialized(_this), "renderHeader", function (isOpen, size) {
      var _this$props = _this.props,
        renderHeader = _this$props.renderHeader,
        chevron = _this$props.chevron,
        chevronAlignment = _this$props.chevronAlignment,
        errorState = _this$props.errorState,
        cardId = _this$props.id,
        title = _this$props.title,
        isCollapsible = _this$props.isCollapsible;
      var className = styles.header;
      var _assertThisInitialize = _assertThisInitialized(_this),
        translator = _assertThisInitialize.translator;
      var titleText = typeof title === 'object' ? translator(title) : title;
      if (renderHeader) {
        return renderHeader(isOpen, cardId, className);
      }
      var chevronNode = chevron && isCollapsible && React.createElement(Chevron, {
        className: styles.chevron,
        isOpen: isOpen,
        size: size
      });
      return React.createElement(React.Fragment, null, chevronAlignment === 'left' && chevronNode, React.createElement("span", {
        className: className
      }, titleText), errorState && React.createElement(Icon, {
        className: styles.errorIcon,
        icon: "mi-error-outline"
      }), chevronAlignment === 'right' && chevronNode);
    });
    _defineProperty(_assertThisInitialized(_this), "handleClick", function (evt, toggleAccordionOpen) {
      _this.toggleFunc(toggleAccordionOpen);
      evt.preventDefault();
    });
    _defineProperty(_assertThisInitialized(_this), "handleFocus", function () {
      _this.setState({
        focused: true
      });
    });
    _defineProperty(_assertThisInitialized(_this), "handleBlur", function () {
      _this.setState({
        focused: false
      });
    });
    _defineProperty(_assertThisInitialized(_this), "renderAccordionCard", function (context) {
      var _this$props2 = _this.props,
        id = _this$props2.id,
        className = _this$props2.className,
        collapseClassName = _this$props2.collapseClassName,
        cardBodyClassName = _this$props2.cardBodyClassName,
        cardHeadingClassName = _this$props2.cardHeadingClassName,
        cardTitleClassName = _this$props2.cardTitleClassName,
        errorState = _this$props2.errorState,
        isCollapsible = _this$props2.isCollapsible;
      var isAccordionOpen = context.isAccordionOpen,
        toggleAccordionOpen = context.toggleAccordionOpen,
        showFrame = context.showFrame;
      var _uniqueInnerId3 = uniqueInnerId(id, 'headingId', 'contentId'),
        headingId = _uniqueInnerId3.headingId,
        contentId = _uniqueInnerId3.contentId;
      var isOpen = isAccordionOpen(id);
      var accordionCardClass = cx(_defineProperty2(_defineProperty2({}, styles.accordionFrame, showFrame), "isOpen", isOpen), className);
      var accordionTitleClass = cx(styles.cardTitle, cardTitleClassName, _defineProperty2(_defineProperty2(_defineProperty2({
        isOpen: isOpen
      }, styles.headerFocused, _this.state.focused), styles.headerError, errorState), styles.accordionToggle, isCollapsible));
      var clickCallback = function clickCallback(evt) {
        if (evt.detail > 0) _this.handleClick(evt, toggleAccordionOpen);
      };
      var keyPressCallback = function keyPressCallback(evt) {
        return _this.handleClick(evt, toggleAccordionOpen);
      };
      var cardHeadingClassNames = cx(styles.cardHeading, cardHeadingClassName);
      var cardBodyClassNames = cx(styles.cardBody, cardBodyClassName);
      var collapsibleHeadingProps = {
        onClick: clickCallback,
        onKeyPress: getKeyPressHandler(keyPressCallback)
      };
      return React.createElement("div", {
        id: id,
        className: accordionCardClass
      }, React.createElement("div", _extends({
        id: headingId,
        className: cardHeadingClassNames,
        onFocus: _this.handleFocus,
        onBlur: _this.handleBlur,
        role: "menuitem",
        tabIndex: "0"
      }, _this.getHeadingAccessibilityProperties(isOpen), isCollapsible && collapsibleHeadingProps), React.createElement("div", {
        className: accordionTitleClass
      }, _this.renderHeader(isOpen, context.size))), React.createElement(Collapse, _extends({
        id: contentId,
        isOpen: isOpen,
        className: collapseClassName
      }, _this.getCardContentAccessibilityProperties()), React.createElement("div", {
        className: cardBodyClassNames
      }, _this.renderBody())));
    });
    return _this;
  }
  _createClass(AccordionCard, [{
    key: "renderBody",
    value: function renderBody() {
      var children = this.props.children;
      return children;
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;
      return React.createElement(ContextConsumer, {
        contexts: [AccordionContext]
      }, function (accordion) {
        return _this2.renderAccordionCard(accordion);
      });
    }
  }]);
  return AccordionCard;
}(PureComponent);
_defineProperty(AccordionCard, "propTypes", {
  id: PropTypes.string.isRequired,
  chevron: PropTypes.bool,
  chevronAlignment: chevronAlignProptypes,
  className: PropTypes.string,
  collapseClassName: PropTypes.string,
  cardBodyClassName: PropTypes.string,
  cardHeadingClassName: PropTypes.string,
  children: PropTypes.node,
  errorState: PropTypes.bool,
  title: intlMessageShape,
  renderHeader: PropTypes.func,
  isCollapsible: PropTypes.bool
});
_defineProperty(AccordionCard, "defaultProps", {
  chevron: false,
  chevronAlignment: 'left',
  errorState: false,
  isCollapsible: true
});
_defineProperty(AccordionCard, "contextType", TranslatorContext);
AccordionCard.__docgenInfo = {
  componentName: "AccordionCard",
  packageName: "@jutro/components",
  description: "The `AccordionCard` component offers Card-like behavior inside an `Accordion`. It accepts\nmost of the same properties as Card (except 'isOpen' and 'onToggleOpen' which is managed\nby the containing Accordion).",
  displayName: "AccordionCard",
  methods: [{
    name: "toggleFunc",
    docblock: null,
    modifiers: [],
    params: [{
      name: "onToggleOpen",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "getHeadingAccessibilityProperties",
    docblock: null,
    modifiers: [],
    params: [{
      name: "isOpen",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "getCardContentAccessibilityProperties",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "renderHeader",
    docblock: null,
    modifiers: [],
    params: [{
      name: "isOpen",
      optional: undefined,
      type: null
    }, {
      name: "size",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "handleClick",
    docblock: null,
    modifiers: [],
    params: [{
      name: "evt",
      optional: undefined,
      type: null
    }, {
      name: "toggleAccordionOpen",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "handleFocus",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "handleBlur",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "renderBody",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "renderAccordionCard",
    docblock: null,
    modifiers: [],
    params: [{
      name: "context",
      optional: undefined,
      type: null
    }],
    returns: null
  }],
  actualName: "AccordionCard",
  metadataType: "container",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "Used to identify header."
    },
    chevron: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Show chevron in header",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    chevronAlignment: {
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: false
        }, {
          value: "'right'",
          computed: false
        }, {
          value: "'none'",
          computed: false
        }]
      },
      required: false,
      description: "Position of chevron",
      defaultValue: {
        value: "'left'",
        computed: false
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional styles for whole card"
    },
    collapseClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional styles for Collapse"
    },
    cardBodyClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional styles for card body inside Collapse"
    },
    cardHeadingClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional styles for card header"
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: ""
    },
    errorState: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Indicates if there is an error within the card contents",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "property to render title for header"
    },
    renderHeader: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback to render header to be displayed in the Accordion"
    },
    isCollapsible: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Indicates if the card should be collapsible",
      defaultValue: {
        value: "true",
        computed: false
      }
    }
  }
};