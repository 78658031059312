import _defineProperty from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { IntlContext } from '@jutro/locale';
import get from "lodash/get";
import { CurrencyValue, CurrencyField } from '@jutro/components';
import { TableColumn } from '../table/TableColumn';
import { createTextFilter } from '../helper';
export var MoneyColumn = function MoneyColumn() {
  if (false) {
    throw new Error('Component <MoneyColumn /> should never render');
  }
  return React.createElement(React.Fragment, null);
};
MoneyColumn.propTypes = _objectSpread({}, TableColumn.propTypes, {
  onAccessor: PropTypes.func
});
MoneyColumn.defaultCell = function (row, rowId, _ref) {
  var path = _ref.path,
    visible = _ref.visible;
  if (visible === false) {
    return null;
  }
  var value = get(row, path);
  return React.createElement(CurrencyValue, {
    amount: value
  });
};
MoneyColumn.editCell = function (_ref2) {
  var props = _ref2.props,
    editProps = _ref2.editProps,
    editActions = _ref2.editActions;
  var path = props.path,
    id = props.id;
  var row = editProps.row,
    rowId = editProps.rowId;
  var update = editActions.update;
  var editedValue = row[path];
  return React.createElement(CurrencyField, {
    id: "".concat(id, "_").concat(rowId),
    value: {
      amount: editedValue
    },
    onValueChange: function onValueChange(newValue) {
      update(row, path, newValue.amount);
    }
  });
};
MoneyColumn.displayName = 'MoneyColumn';
MoneyColumn.defaultProps = {
  renderCell: MoneyColumn.defaultCell,
  renderEditCell: MoneyColumn.editCell,
  onFilter: function onFilter(filterValue, _ref3) {
    var path = _ref3.path;
    var intl = useContext(IntlContext);
    var textFilter = createTextFilter(filterValue);
    return function (item) {
      var value = get(item, path, '');
      if (textFilter(value)) {
        return true;
      }
      var formattedValue = intl.formatNumber(value);
      return textFilter(formattedValue);
    };
  },
  columnProportion: 1
};
MoneyColumn.__docgenInfo = {
  componentName: "MoneyColumn",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "MoneyColumn",
  methods: [],
  actualName: "MoneyColumn",
  metadataType: "container",
  props: {
    onAccessor: {
      type: {
        name: "func"
      },
      required: false,
      description: "Returns a value that will be used by the column to access the correct data of each row"
    },
    renderCell: {
      defaultValue: {
        value: "MoneyColumn.defaultCell",
        computed: true
      },
      required: false
    },
    renderEditCell: {
      defaultValue: {
        value: "MoneyColumn.editCell",
        computed: true
      },
      required: false
    },
    onFilter: {
      defaultValue: {
        value: "(filterValue, { path }) => {\n    // Fix: find another way to access locale here\n    // currently we assume this method is only called from component render function\n    const intl = useContext(IntlContext); // eslint-disable-line react-hooks/rules-of-hooks\n    const textFilter = createTextFilter(filterValue);\n\n    return item => {\n        const value = get(item, path, '');\n\n        if (textFilter(value)) {\n            return true;\n        }\n\n        const formattedValue = intl.formatNumber(value);\n\n        return textFilter(formattedValue);\n    };\n}",
        computed: false
      },
      required: false
    },
    columnProportion: {
      defaultValue: {
        value: "1",
        computed: false
      },
      required: false
    }
  },
  composes: ["../table/TableColumn"]
};