import _defineProperty from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import { onKeyDownHandler } from './onKeyDownHandler';
import { onFocusHandler, isFocused } from './onFocusHandler';
var getAriaSort = function getAriaSort(columnId, sorted) {
  var sortedHeader = sorted === null || sorted === void 0 ? void 0 : _findInstanceProperty(sorted).call(sorted, function (_ref) {
    var id = _ref.id;
    return id === columnId;
  });
  if (sortedHeader) {
    return sortedHeader.desc ? 'descending' : 'ascending';
  }
  return 'none';
};
export var getA11yTrGroupProps = function getA11yTrGroupProps() {
  return {
    role: 'rowgroup'
  };
};
export var getA11yTrProps = function getA11yTrProps() {
  return {
    role: 'row'
  };
};
export var getA11yTableProps = function getA11yTableProps(tableLabel) {
  return {
    role: 'table',
    'aria-label': tableLabel
  };
};
var isTabbableHeader = function isTabbableHeader(type) {
  return type === 'display';
};
var getA11yTheadThProps = function getA11yTheadThProps(tableId, focused, setFocused, state, columnId, type) {
  return {
    tabIndex: isTabbableHeader(type) ? 0 : -1,
    onFocus: onFocusHandler(focused, setFocused, state, 0, columnId),
    onKeyDown: onKeyDownHandler(focused, tableId, state),
    role: 'columnheader',
    'data-row': 0,
    'data-col': columnId,
    'data-parent': tableId
  };
};
export var getA11yTheadThPropsSortableHeader = function getA11yTheadThPropsSortableHeader(tableId, sorted, focused, setFocused) {
  return function (state, columnId, type) {
    return _objectSpread({
      'aria-sort': getAriaSort(columnId, sorted)
    }, getA11yTheadThProps(tableId, focused, setFocused, state, columnId, type));
  };
};
var isHiddenHeader = function isHiddenHeader(type) {
  return type === 'expander' || type === 'actions' || type === 'single_selection';
};
export var getA11yTheadThPropsHeader = function getA11yTheadThPropsHeader(tableId, focused, setFocused) {
  return function (state, columnId, type) {
    return isHiddenHeader(type) ? _objectSpread({
      'aria-hidden': true
    }, getA11yTheadThProps(tableId, focused, setFocused, state, columnId, type)) : getA11yTheadThProps(tableId, focused, setFocused, state, columnId, type);
  };
};
export var getA11yTdProps = function getA11yTdProps(tableId, focused, setFocused) {
  return function (state, rowInfo, columnId, shouldBeFocusable) {
    return {
      role: 'cell',
      tabIndex: !shouldBeFocusable && isFocused(focused, state, rowInfo.viewIndex + 1, columnId) ? 0 : -1,
      'data-row': rowInfo.viewIndex + 1,
      'data-col': columnId,
      'data-parent': tableId,
      onFocus: onFocusHandler(focused, setFocused, state, rowInfo.viewIndex + 1, columnId),
      onKeyDown: onKeyDownHandler(focused, tableId, state)
    };
  };
};