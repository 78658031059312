import _defineProperty2 from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty2(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _someInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/some";
import _trimInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/trim";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _get from "@babel/runtime-corejs3/helpers/get";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { getMessageProp } from '@jutro/platform';
import { intlMessageShape } from '@jutro/prop-types';
import { renderWithBreakpoint } from '@jutro/layout';
import styles from "./FileUploadField.module.css";
import { FieldComponent } from '../FieldComponent/FieldComponent';
import { Button, buttonTypes, buttonSizes } from '../../button/Button';
import { Icon } from '../../Icon/Icon';
import { IconButton } from '../../IconButton/IconButton';
import { messages } from './FileUploadField.messages';
import * as mimeTypes from './mimeTypes';
var FLAVOR_LARGE = 'large';
var FLAVOR_THIN = 'thin';
var isThinType = function isThinType(type) {
  return type === FLAVOR_THIN;
};
var isLargeType = function isLargeType(type) {
  return type === FLAVOR_LARGE;
};
function getFileName(value) {
  var _value$split;
  return value === null || value === void 0 ? void 0 : (_value$split = value.split) === null || _value$split === void 0 ? void 0 : _value$split.call(value, /[/\\]/).pop();
}
export var FileUploadField = function (_FieldComponent) {
  _inherits(FileUploadField, _FieldComponent);
  var _super = _createSuper(FileUploadField);
  function FileUploadField(props, context) {
    var _thisSuper, _this;
    _classCallCheck(this, FileUploadField);
    _this = _super.call(this, props, context);
    _defineProperty(_assertThisInitialized(_this), "handleErrorStateChange", function (prevErrorState, errorState) {
      var _this$props = _this.props,
        onValidationChange = _this$props.onValidationChange,
        model = _this$props.model,
        path = _this$props.path,
        value = _this$props.value;
      if (prevErrorState !== errorState) {
        var isValid = !errorState && _get((_thisSuper = _assertThisInitialized(_this), _getPrototypeOf(FileUploadField.prototype)), "validate", _thisSuper).call(_thisSuper, value, true);
        var currentValidationMessages = _this.getValidationMessages() || [];
        if (onValidationChange) {
          onValidationChange(isValid, model || path, currentValidationMessages);
        }
        _this.valid = isValid;
      }
    });
    _defineProperty(_assertThisInitialized(_this), "handleChange", function (evt) {
      var _this$props2 = _this.props,
        maxLength = _this$props2.maxLength,
        onUpload = _this$props2.onUpload,
        accept = _this$props2.accept,
        messageProps = _this$props2.messageProps,
        maxFileSizeKB = _this$props2.maxFileSizeKB;
      _this.pristine = false;
      _this.focusPristine = false;
      var path = evt.target.value || evt.dataTransfer.files[0].name;
      var file = evt.dataTransfer ? evt.dataTransfer.files[0] : evt.target.files[0];
      evt.target.value = null;
      var incorrectFileTypeMessage = getMessageProp('incorrectFileTypeMessage', messageProps, messages);
      var maxFileSizeKBMessage = getMessageProp('maxFileSizeKBMessage', messageProps, messages);
      var maxLengthMessage = getMessageProp('maxLengthMessage', messageProps, messages);
      var errorMessage;
      if (maxLength && file && file.name.length > maxLength) {
        errorMessage = _this.translator(maxLengthMessage, {
          nameLength: maxLength
        });
      }
      if (accept && file) {
        var _context;
        var types = _mapInstanceProperty(_context = accept.toLowerCase().split(',')).call(_context, function (item) {
          return _trimInstanceProperty(item).call(item);
        });
        var match = _someInstanceProperty(types).call(types, mimeTypes.isMatches(file));
        if (!match) {
          errorMessage = _this.translator(incorrectFileTypeMessage);
        }
      }
      if (maxFileSizeKB && file && file.size > maxFileSizeKB * 1000) {
        errorMessage = _this.translator(maxFileSizeKBMessage, {
          fileSizeKB: maxFileSizeKB,
          fileSize: maxFileSizeKB * 1000
        });
      }
      _this.setState({
        errorMessage: errorMessage
      });
      _this.notifyChange(path);
      if (onUpload && file && !errorMessage) {
        onUpload(file);
      }
    });
    _defineProperty(_assertThisInitialized(_this), "handleAddFile", function () {
      _this.myRef.current.click();
    });
    _defineProperty(_assertThisInitialized(_this), "handleClearFile", function () {
      if (_this.state.errorMessage) {
        _this.setState({
          errorMessage: undefined
        });
      }
      _this.notifyChange(undefined);
    });
    _defineProperty(_assertThisInitialized(_this), "handleOnDragOver", function (event) {
      event.preventDefault();
      event.stopPropagation();
    });
    _defineProperty(_assertThisInitialized(_this), "handleOnFileDrop", function (event) {
      event.preventDefault();
      _this.setState({
        dragCounter: 0
      });
      _this.handleChange(event);
    });
    _defineProperty(_assertThisInitialized(_this), "updateDragCounter", function (updatedCounter) {
      return function () {
        var disabled = _this.props.disabled;
        if (!disabled) {
          _this.setState({
            dragCounter: updatedCounter
          });
        }
      };
    });
    _defineProperty(_assertThisInitialized(_this), "renderEmptyUploadAreaMessage", function (type, messageProps) {
      var textClasses = cx(styles.valueText, _defineProperty2({}, styles.paddedLeft, isThinType(type)));
      var emptyUploadAreaMessage = getMessageProp('emptyUploadAreaMessage', messageProps, messages);
      return React.createElement("div", {
        className: textClasses
      }, _this.translator(emptyUploadAreaMessage));
    });
    _defineProperty(_assertThisInitialized(_this), "renderUploadedFileMessage", function (type, messageProps, value) {
      var textClasses = cx(styles.valueText, _defineProperty2({}, styles.paddedLeft, isThinType(type)));
      var uploadedFilesMessage = getMessageProp('uploadedFilesMessage', messageProps, messages);
      return React.createElement(React.Fragment, null, isThinType(type) ? React.createElement(Icon, {
        icon: "mi-photo",
        className: styles.uploadedFileIcon,
        "aria-hidden": "true"
      }) : null, React.createElement("div", {
        className: textClasses
      }, _this.translator(uploadedFilesMessage), " ", getFileName(value)));
    });
    _defineProperty(_assertThisInitialized(_this), "renderEmptyUploadArea", function (type, imageSource, disabled, messageProps, buttonType, buttonSize, buttonIcon, disableDragAndDrop) {
      if (isLargeType(type)) {
        return _this.renderLargeFlavorArea(type, imageSource, disabled, undefined, _this.renderEmptyUploadAreaMessage, messageProps, buttonType, buttonSize, buttonIcon, disableDragAndDrop);
      }
      if (isThinType(type)) {
        return React.createElement(React.Fragment, null, _this.renderActionButton(undefined, disabled, messageProps, buttonType, buttonSize, buttonIcon), !disableDragAndDrop && _this.renderEmptyUploadAreaMessage(type, messageProps));
      }
      return null;
    });
    _this.myRef = React.createRef();
    _this.state = {
      errorMessage: undefined,
      dragCounter: 0
    };
    return _this;
  }
  _createClass(FileUploadField, [{
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps, prevState) {
      var prevErrorMessageState = prevState.errorMessage;
      var errorMessageState = this.state.errorMessage;
      this.handleErrorStateChange(prevErrorMessageState, errorMessageState);
    }
  }, {
    key: "render",
    value: function render() {
      return _get(_getPrototypeOf(FileUploadField.prototype), "render", this).call(this);
    }
  }, {
    key: "getValidationMessages",
    value: function getValidationMessages() {
      var errorMessage = this.state.errorMessage;
      if (errorMessage) {
        return [errorMessage];
      }
      return _get(_getPrototypeOf(FileUploadField.prototype), "getValidationMessages", this).call(this);
    }
  }, {
    key: "renderControlReadOnly",
    value: function renderControlReadOnly(breakpointProps) {
      var id = breakpointProps.id,
        value = breakpointProps.value;
      var displayValue = getFileName(value);
      if (!displayValue) {
        displayValue = FieldComponent.defaultROEmptyValue;
      }
      return React.createElement("span", {
        id: id
      }, displayValue);
    }
  }, {
    key: "disabledOrReadOnly",
    value: function disabledOrReadOnly() {
      return this.props.disabled || this.props.readOnly;
    }
  }, {
    key: "renderActionButton",
    value: function renderActionButton(value, disabled, messageProps, buttonType, buttonSize, buttonIcon) {
      var uploadFilesMessage = getMessageProp('uploadFilesMessage', messageProps, messages);
      var removeFileMessage = getMessageProp('removeFileMessage', messageProps, messages);
      var buttonClick = value ? this.handleClearFile : this.handleAddFile;
      var dragCounter = this.state.dragCounter;
      var icon;
      if (buttonIcon) {
        icon = buttonIcon;
      } else if (value) {
        icon = 'mi-close';
      } else {
        icon = 'mi-cloud-upload';
      }
      return React.createElement(Button, {
        icon: icon,
        type: buttonType,
        size: buttonSize,
        onClick: buttonClick,
        disabled: disabled,
        onDragEnter: this.updateDragCounter(dragCounter + 1),
        onDragLeave: this.updateDragCounter(dragCounter - 1)
      }, value ? removeFileMessage : uploadFilesMessage);
    }
  }, {
    key: "renderUploadAreaImage",
    value: function renderUploadAreaImage(imageSource, messageProps) {
      if (!imageSource) {
        return undefined;
      }
      var emptyUploadAreaMessage = getMessageProp('emptyUploadAreaMessage', messageProps, messages);
      return React.createElement("img", {
        src: imageSource,
        alt: this.translator(emptyUploadAreaMessage),
        className: styles.areaImage,
        "aria-hidden": true
      });
    }
  }, {
    key: "renderLargeFlavorArea",
    value: function renderLargeFlavorArea(type, imageSource, disabled, value, renderMessage, messageProps, buttonType, buttonSize, buttonIcon, disableDragAndDrop) {
      return React.createElement(React.Fragment, null, this.renderUploadAreaImage(imageSource, messageProps), !disableDragAndDrop && renderMessage(type, messageProps, value), this.renderActionButton(value, disabled, messageProps, buttonType, buttonSize, buttonIcon));
    }
  }, {
    key: "renderUploadedFileArea",
    value: function renderUploadedFileArea(type, imageSource, disabled, value, messageProps, buttonType, buttonSize, buttonIcon) {
      var _this2 = this;
      if (isLargeType(type)) {
        return this.renderLargeFlavorArea(type, imageSource, disabled, value, this.renderUploadedFileMessage, messageProps, buttonType, buttonSize, buttonIcon, false);
      }
      if (isThinType(type)) {
        var deleteAction = function deleteAction() {
          var removeFileMessage = getMessageProp('removeFileMessage', messageProps, messages);
          var handleKeyDown = function handleKeyDown(event) {
            event.preventDefault();
            if (event.key === ' ' || event.key === 'Spacebar' || event.key === 'Enter') {
              _this2.handleClearFile();
            }
          };
          return React.createElement(IconButton, {
            icon: "mi-close",
            iconColor: "neutral",
            size: "small",
            className: styles.deleteActionLink,
            onClick: _this2.handleClearFile,
            onKeyDown: handleKeyDown,
            ariaLabel: removeFileMessage
          });
        };
        return React.createElement(React.Fragment, null, this.renderUploadedFileMessage(type, messageProps, value), deleteAction());
      }
      return null;
    }
  }, {
    key: "isDraggedOverAndEnabled",
    value: function isDraggedOverAndEnabled() {
      var dragCounter = this.state.dragCounter;
      var disabled = this.props.disabled;
      return dragCounter !== 0 && !disabled;
    }
  }, {
    key: "renderControl",
    value: function renderControl(breakpointProps) {
      var _this3 = this;
      return renderWithBreakpoint(function (breakpoint) {
        var _breakpointProps$disa, _this$myRef, _this$myRef$current;
        var id = breakpointProps.id,
          value = breakpointProps.value,
          maxLength = breakpointProps.maxLength,
          disabled = breakpointProps.disabled,
          controlClassName = breakpointProps.controlClassName,
          required = breakpointProps.required,
          accept = breakpointProps.accept,
          messageProps = breakpointProps.messageProps,
          type = breakpointProps.type,
          imageSource = breakpointProps.imageSource,
          buttonType = breakpointProps.buttonType,
          buttonSize = breakpointProps.buttonSize,
          buttonIcon = breakpointProps.buttonIcon;
        var isDesktop = breakpoint === 'desktop';
        var disableDragAndDrop = (_breakpointProps$disa = breakpointProps === null || breakpointProps === void 0 ? void 0 : breakpointProps.disableDragAndDrop) !== null && _breakpointProps$disa !== void 0 ? _breakpointProps$disa : !isDesktop;
        var focusHandlers = _this3.getInputFocusHandlers();
        var filePath = value === ((_this$myRef = _this3.myRef) === null || _this$myRef === void 0 ? void 0 : (_this$myRef$current = _this$myRef.current) === null || _this$myRef$current === void 0 ? void 0 : _this$myRef$current.value) ? value : '';
        var classes = cx(styles.fileUploadField, controlClassName);
        var wrapperClasses = cx(styles.wrapper, _defineProperty2(_defineProperty2(_defineProperty2(_defineProperty2(_defineProperty2({}, styles.wrapperLarge, type === FLAVOR_LARGE), styles.wrapperThin, type === FLAVOR_THIN), styles.wrapperThinWithUpload, type === FLAVOR_THIN && value), styles.wrapperWithDragAndDropDisabled, disableDragAndDrop && (type === FLAVOR_THIN && !value || type === FLAVOR_LARGE)), styles.dragOver, !disableDragAndDrop && _this3.isDraggedOverAndEnabled()));
        var dragCounter = _this3.state.dragCounter;
        var dragAndDropProps = {};
        if (!disableDragAndDrop) {
          dragAndDropProps = {
            onDragOver: !_this3.disabledOrReadOnly() ? _this3.handleOnDragOver : undefined,
            onDrop: !_this3.disabledOrReadOnly() ? _this3.handleOnFileDrop : undefined,
            onDragEnter: _this3.updateDragCounter(dragCounter + 1),
            onDragLeave: _this3.updateDragCounter(dragCounter - 1)
          };
        }
        return React.createElement("div", _extends({
          className: classes
        }, dragAndDropProps), React.createElement("div", {
          className: wrapperClasses
        }, value ? _this3.renderUploadedFileArea(type, imageSource, disabled, value, messageProps, buttonType, buttonSize, buttonIcon) : _this3.renderEmptyUploadArea(type, imageSource, disabled, messageProps, buttonType, buttonSize, buttonIcon, disableDragAndDrop), React.createElement("input", _extends({
          id: id,
          type: "file",
          ref: _this3.myRef,
          accept: accept,
          className: controlClassName,
          style: {
            display: 'none'
          },
          maxLength: maxLength,
          value: filePath || undefined,
          onChange: _this3.handleChange
        }, focusHandlers, {
          disabled: disabled,
          required: required
        }, _this3.generateDataPathProperty(), _this3.generateAccessibilityProperties()))));
      });
    }
  }]);
  return FileUploadField;
}(FieldComponent);
_defineProperty(FileUploadField, "propTypes", _objectSpread({}, FieldComponent.propTypes, {
  accept: PropTypes.string,
  type: PropTypes.oneOf([FLAVOR_THIN, FLAVOR_LARGE]).isRequired,
  imageSource: PropTypes.string,
  maxLength: PropTypes.number,
  maxFileSizeKB: PropTypes.number,
  onUpload: PropTypes.func,
  messageProps: PropTypes.shape({
    emptyUploadAreaMessage: intlMessageShape,
    uploadFilesMessage: intlMessageShape,
    uploadedFilesMessage: intlMessageShape,
    removeFileMessage: intlMessageShape,
    maxLengthMessage: intlMessageShape,
    maxFileSizeKBMessage: intlMessageShape,
    incorrectFileTypeMessage: intlMessageShape
  }),
  buttonType: PropTypes.oneOf(buttonTypes),
  buttonSize: PropTypes.oneOf(buttonSizes),
  buttonIcon: PropTypes.string,
  disableDragAndDrop: PropTypes.bool
}));
_defineProperty(FileUploadField, "defaultProps", _objectSpread({}, FieldComponent.defaultProps, {
  controlClassName: styles.fileUploadField,
  type: FLAVOR_THIN,
  placeholder: messages.placeholder,
  buttonType: 'outlined',
  buttonSize: 'small'
}));
_defineProperty(FileUploadField, "contextType", FieldComponent.contextType);
FileUploadField.displayName = 'FileUploadField';
FileUploadField.__docgenInfo = {
  componentName: "FileUploadField",
  packageName: "@jutro/components",
  description: "Renders a control which allows the user to upload a file. Allows you to specify validation and actions for specific events.",
  displayName: "FileUploadField",
  methods: [{
    name: "handleErrorStateChange",
    docblock: null,
    modifiers: [],
    params: [{
      name: "prevErrorState",
      optional: undefined,
      type: null
    }, {
      name: "errorState",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "handleChange",
    docblock: "Default change handler for `input` element. It will be used unless this method or `renderControl()`\nis overridden.\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: null,
    description: "Default change handler for `input` element. It will be used unless this method or `renderControl()`\nis overridden."
  }, {
    name: "getValidationMessages",
    docblock: "Get the validation messages to display.\nIf field is 'required' and no validation message is provided, it is added\n\n@returns {Array<any>} validation messages",
    modifiers: [],
    params: [],
    returns: {
      description: "validation messages",
      type: {
        name: "Array",
        elements: [{
          name: "any"
        }]
      }
    },
    description: "Get the validation messages to display.\nIf field is 'required' and no validation message is provided, it is added"
  }, {
    name: "renderControlReadOnly",
    docblock: "Render readonly control for this component.\n\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps"
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render readonly control for this component."
  }, {
    name: "disabledOrReadOnly",
    docblock: "Return true flag if component is disabled or readOnly\n\n@returns {boolean} disabled or readonly flag",
    modifiers: [],
    params: [],
    returns: {
      description: "disabled or readonly flag",
      type: {
        name: "boolean"
      }
    },
    description: "Return true flag if component is disabled or readOnly"
  }, {
    name: "handleAddFile",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "handleClearFile",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "handleOnDragOver",
    docblock: "Handle onDragOver event.\n\n@param {event} event onDragOver event",
    modifiers: [],
    params: [{
      name: "event",
      description: "onDragOver event",
      type: {
        name: "event"
      },
      optional: false
    }],
    returns: null,
    description: "Handle onDragOver event."
  }, {
    name: "handleOnFileDrop",
    docblock: "Handle onDrop event.\n\n@param {event} event onDrop event",
    modifiers: [],
    params: [{
      name: "event",
      description: "onDrop event",
      type: {
        name: "event"
      },
      optional: false
    }],
    returns: null,
    description: "Handle onDrop event."
  }, {
    name: "updateDragCounter",
    docblock: "Updates the drag counter only if component is not disabled\n\n@param {number} updatedCounter new counter value\n@returns {Function} function that updates the counter with the given value",
    modifiers: [],
    params: [{
      name: "updatedCounter",
      description: "new counter value",
      type: {
        name: "number"
      },
      optional: false
    }],
    returns: {
      description: "function that updates the counter with the given value",
      type: {
        name: "Function"
      }
    },
    description: "Updates the drag counter only if component is not disabled"
  }, {
    name: "renderActionButton",
    docblock: "Renders control's action button\n@param {string} value control value, file path\n@param {boolean} disabled indicates if control is disabled\n@param {object} messageProps i18n messages\n@param {string} buttonType type of button to render\n@param {string} buttonSize size of button to render\n@param {string} buttonIcon icon to render within the button\n@returns {Function} render function for the action button",
    modifiers: [],
    params: [{
      name: "value",
      description: "control value, file path",
      type: {
        name: "string"
      },
      optional: false
    }, {
      name: "disabled",
      description: "indicates if control is disabled",
      type: {
        name: "boolean"
      },
      optional: false
    }, {
      name: "messageProps",
      description: "i18n messages",
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "buttonType",
      description: "type of button to render",
      type: {
        name: "string"
      },
      optional: false
    }, {
      name: "buttonSize",
      description: "size of button to render",
      type: {
        name: "string"
      },
      optional: false
    }, {
      name: "buttonIcon",
      description: "icon to render within the button",
      type: {
        name: "string"
      },
      optional: false
    }],
    returns: {
      description: "render function for the action button",
      type: {
        name: "Function"
      }
    },
    description: "Renders control's action button"
  }, {
    name: "renderEmptyUploadAreaMessage",
    docblock: "Renders message to be displayed when no file is uploaded\n@param {string} type upload field type to render\n@param {object} messageProps i18n messages\n@returns {Function} render function for uploaded file area",
    modifiers: [],
    params: [{
      name: "type",
      description: "upload field type to render",
      type: {
        name: "string"
      },
      optional: false
    }, {
      name: "messageProps",
      description: "i18n messages",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "render function for uploaded file area",
      type: {
        name: "Function"
      }
    },
    description: "Renders message to be displayed when no file is uploaded"
  }, {
    name: "renderUploadAreaImage",
    docblock: "Renders image in empty upload area\n@param {string} imageSource source path for the image to display in upload area\n@param {object} messageProps i18n messages\n@returns {Function} render function for uploaded file area",
    modifiers: [],
    params: [{
      name: "imageSource",
      description: "source path for the image to display in upload area",
      type: {
        name: "string"
      },
      optional: false
    }, {
      name: "messageProps",
      description: "i18n messages",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "render function for uploaded file area",
      type: {
        name: "Function"
      }
    },
    description: "Renders image in empty upload area"
  }, {
    name: "renderUploadedFileMessage",
    docblock: "Renders message to be displayed when file is uploaded\n@param {string} type upload field type to render\n@param {object} messageProps i18n messages\n@param {string} value uploaded file path\n@returns {Function} render function for uploaded file message",
    modifiers: [],
    params: [{
      name: "type",
      description: "upload field type to render",
      type: {
        name: "string"
      },
      optional: false
    }, {
      name: "messageProps",
      description: "i18n messages",
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "value",
      description: "uploaded file path",
      type: {
        name: "string"
      },
      optional: false
    }],
    returns: {
      description: "render function for uploaded file message",
      type: {
        name: "Function"
      }
    },
    description: "Renders message to be displayed when file is uploaded"
  }, {
    name: "renderLargeFlavorArea",
    docblock: "Renders Large type upload area\n@param {string} type upload field type to render\n@param {string} imageSource source path for the image to display in upload area\n@param {boolean} disabled indicates if control is disabled\n@param {string} value uploaded file path or `undefined` if no file is uploaded\n@param {Function} renderMessage function to render upload area message\n@param {object} messageProps i18n messages\n@param {string} buttonType type of button to render\n@param {string} buttonSize size of button to render\n@param {string} buttonIcon icon to render within the button\n@param {boolean} disableDragAndDrop determine whether the empty upload message should be displayed\n@returns {Function} render function for empty upload area",
    modifiers: [],
    params: [{
      name: "type",
      description: "upload field type to render",
      type: {
        name: "string"
      },
      optional: false
    }, {
      name: "imageSource",
      description: "source path for the image to display in upload area",
      type: {
        name: "string"
      },
      optional: false
    }, {
      name: "disabled",
      description: "indicates if control is disabled",
      type: {
        name: "boolean"
      },
      optional: false
    }, {
      name: "value",
      description: "uploaded file path or `undefined` if no file is uploaded",
      type: {
        name: "string"
      },
      optional: false
    }, {
      name: "renderMessage",
      description: "function to render upload area message",
      type: {
        name: "Function"
      },
      optional: false
    }, {
      name: "messageProps",
      description: "i18n messages",
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "buttonType",
      description: "type of button to render",
      type: {
        name: "string"
      },
      optional: false
    }, {
      name: "buttonSize",
      description: "size of button to render",
      type: {
        name: "string"
      },
      optional: false
    }, {
      name: "buttonIcon",
      description: "icon to render within the button",
      type: {
        name: "string"
      },
      optional: false
    }, {
      name: "disableDragAndDrop",
      description: "determine whether the empty upload message should be displayed",
      type: {
        name: "boolean"
      },
      optional: false
    }],
    returns: {
      description: "render function for empty upload area",
      type: {
        name: "Function"
      }
    },
    description: "Renders Large type upload area"
  }, {
    name: "renderEmptyUploadArea",
    docblock: "Renders empty upload area\n@param {string} type upload field type to render\n@param {string} imageSource source path for the image to display in upload area\n@param {boolean} disabled indicates if control is disabled\n@param {object} messageProps i18n messages\n@param {string} buttonType type of button to render\n@param {string} buttonSize size of button to render\n@param {string} buttonIcon icon to render within the button\n@param {boolean} disableDragAndDrop determine whether the empty upload message should be displayed\n@returns {Function} render function for empty upload area",
    modifiers: [],
    params: [{
      name: "type",
      description: "upload field type to render",
      type: {
        name: "string"
      },
      optional: false
    }, {
      name: "imageSource",
      description: "source path for the image to display in upload area",
      type: {
        name: "string"
      },
      optional: false
    }, {
      name: "disabled",
      description: "indicates if control is disabled",
      type: {
        name: "boolean"
      },
      optional: false
    }, {
      name: "messageProps",
      description: "i18n messages",
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "buttonType",
      description: "type of button to render",
      type: {
        name: "string"
      },
      optional: false
    }, {
      name: "buttonSize",
      description: "size of button to render",
      type: {
        name: "string"
      },
      optional: false
    }, {
      name: "buttonIcon",
      description: "icon to render within the button",
      type: {
        name: "string"
      },
      optional: false
    }, {
      name: "disableDragAndDrop",
      description: "determine whether the empty upload message should be displayed",
      type: {
        name: "boolean"
      },
      optional: false
    }],
    returns: {
      description: "render function for empty upload area",
      type: {
        name: "Function"
      }
    },
    description: "Renders empty upload area"
  }, {
    name: "renderUploadedFileArea",
    docblock: "Renders uploaded file area\n@param {string} type upload field type to render\n@param {string} imageSource source path for the image to display in upload area\n@param {boolean} disabled indicates if control is disabled\n@param {string} value control value - uploaded file path\n@param {object} messageProps i18n messages\n@param {string} buttonType type of button to render\n@param {string} buttonSize size of button to render\n@param {string} buttonIcon icon to render within the button\n@returns {Function} render function for uploaded file area",
    modifiers: [],
    params: [{
      name: "type",
      description: "upload field type to render",
      type: {
        name: "string"
      },
      optional: false
    }, {
      name: "imageSource",
      description: "source path for the image to display in upload area",
      type: {
        name: "string"
      },
      optional: false
    }, {
      name: "disabled",
      description: "indicates if control is disabled",
      type: {
        name: "boolean"
      },
      optional: false
    }, {
      name: "value",
      description: "control value - uploaded file path",
      type: {
        name: "string"
      },
      optional: false
    }, {
      name: "messageProps",
      description: "i18n messages",
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "buttonType",
      description: "type of button to render",
      type: {
        name: "string"
      },
      optional: false
    }, {
      name: "buttonSize",
      description: "size of button to render",
      type: {
        name: "string"
      },
      optional: false
    }, {
      name: "buttonIcon",
      description: "icon to render within the button",
      type: {
        name: "string"
      },
      optional: false
    }],
    returns: {
      description: "render function for uploaded file area",
      type: {
        name: "Function"
      }
    },
    description: "Renders uploaded file area"
  }, {
    name: "isDraggedOverAndEnabled",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  actualName: "FileUploadField",
  metadataType: "field",
  props: {
    accept: {
      type: {
        name: "string"
      },
      required: false,
      description: "File type filter. (eg. '.md,.pdf' or 'audio/*')"
    },
    type: {
      type: {
        name: "enum",
        value: [{
          value: "'thin'",
          computed: false
        }, {
          value: "'large'",
          computed: false
        }]
      },
      required: false,
      description: "File upload field type (eg. 'thin', etc.)",
      defaultValue: {
        value: "'thin'",
        computed: false
      }
    },
    imageSource: {
      type: {
        name: "string"
      },
      required: false,
      description: "Path to empty dropdown area image"
    },
    maxLength: {
      type: {
        name: "number"
      },
      required: false,
      description: "Max length for the input field"
    },
    maxFileSizeKB: {
      type: {
        name: "number"
      },
      required: false,
      description: "Max file size in KB for the input field"
    },
    onUpload: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback when valid file is selected"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          emptyUploadAreaMessage: {
            name: "union",
            description: "Message for drag & drop",
            required: false,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          },
          uploadFilesMessage: {
            name: "union",
            description: "Message for upload file",
            required: false,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          },
          uploadedFilesMessage: {
            name: "union",
            description: "Message for uploaded file",
            required: false,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          },
          removeFileMessage: {
            name: "union",
            description: "Message for removing file",
            required: false,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          },
          maxLengthMessage: {
            name: "union",
            description: "Message for file name max length",
            required: false,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          },
          maxFileSizeKBMessage: {
            name: "union",
            description: "Message for max file size",
            required: false,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          },
          incorrectFileTypeMessage: {
            name: "union",
            description: "Error message to present if the file type is incorrect",
            required: false,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          }
        }
      },
      required: false,
      description: "Message props(error message/aria-label)"
    },
    buttonType: {
      type: {
        name: "enum",
        computed: false,
        value: [{
          value: "'filled'",
          computed: false
        }, {
          value: "'outlined'",
          computed: false
        }, {
          value: "'text'",
          computed: false
        }]
      },
      required: false,
      description: "'type' prop for the rendered button",
      defaultValue: {
        value: "'outlined'",
        computed: false
      }
    },
    buttonSize: {
      type: {
        name: "enum",
        computed: false,
        value: [{
          value: "'small'",
          computed: false
        }, {
          value: "'medium'",
          computed: false
        }]
      },
      required: false,
      description: "'size' prop for the rendered button",
      defaultValue: {
        value: "'small'",
        computed: false
      }
    },
    buttonIcon: {
      type: {
        name: "string"
      },
      required: false,
      description: "'icon' prop for the rendered button"
    },
    disableDragAndDrop: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Determines if the drag and drop functionality of the component should be disabled"
    },
    controlClassName: {
      defaultValue: {
        value: "styles.fileUploadField",
        computed: true
      },
      required: false
    },
    placeholder: {
      defaultValue: {
        value: "messages.placeholder",
        computed: true
      },
      required: false
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};