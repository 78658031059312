import _defineProperty from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { isValidElementType } from 'react-is';
import PropTypes from 'prop-types';
import omit from "lodash/omit";
import { themeConfigShape, deprecated } from '@jutro/prop-types';
import { DropdownSelectField } from '../../inputs/DropdownSelectField/DropdownSelectField';
export var omittedDropdownProps = ['value', 'defaultValue', 'availableValues', 'dataType', 'autoTrim', 'onValueChange', 'model', 'path', 'validationMessages', 'dataPath', 'validator', 'inputType'];
export var themeChooserPropTypes = _objectSpread({}, omit((DropdownSelectField === null || DropdownSelectField === void 0 ? void 0 : DropdownSelectField.propTypes) || {}, omittedDropdownProps), {
  availableThemes: PropTypes.arrayOf(themeConfigShape.isRequired).isRequired,
  onThemeChange: PropTypes.func,
  theme: themeConfigShape,
  defaultTheme: themeConfigShape,
  storybookComponent: deprecated(function (props, propName) {
    if (props[propName] && !isValidElementType(props[propName])) {
      return new Error("Invalid prop 'storybookComponent' supplied to 'ThemeChooser': the prop is not a valid React component");
    }
    return null;
  }, '6.0.0'),
  skipPropagation: PropTypes.bool
});
export var themeChooserDefaultProps = _objectSpread({}, omit((DropdownSelectField === null || DropdownSelectField === void 0 ? void 0 : DropdownSelectField.defaultProps) || {}, omittedDropdownProps), {
  availableThemes: [],
  storybookMode: false,
  skipPropagation: false,
  label: '',
  id: 'ThemeChooser'
});
export var themeChooserStorybookPropNames = ['id', 'availableThemes', 'onThemeChange', 'className', 'label', 'theme', 'skipPropagation'];