import _defineProperty2 from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty2(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getSamplePhoneNumber, isValidatePhoneNumber, getPrefixForCountry } from '@jutro/locale';
import { intlMessageShape } from '@jutro/prop-types';
import { mappingDelta, getMessageProp } from '@jutro/platform';
import { FieldComponent } from '../FieldComponent/FieldComponent';
import { InputMaskField } from '../InputMaskField/InputMaskField';
import { FieldIcon } from '../FieldComponent/FieldIcon';
import styles from "./PhoneNumberField.module.css";
import { messages } from './PhoneNumberField.messages';
var phoneMaskFormatChars = {
  9: '[0-9]'
};
export var PhoneNumberField = function (_PureComponent) {
  _inherits(PhoneNumberField, _PureComponent);
  var _super = _createSuper(PhoneNumberField);
  function PhoneNumberField() {
    var _this;
    _classCallCheck(this, PhoneNumberField);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    _this = _super.call.apply(_super, [this].concat(args));
    _defineProperty(_assertThisInitialized(_this), "state", {
      phoneMask: ''
    });
    _defineProperty(_assertThisInitialized(_this), "getValidationMessages", function () {
      var invalidPhone = getMessageProp('invalidPhone', _this.props.messageProps, messages);
      if (!isValidatePhoneNumber(_this.props.value, _this.props.countryCode)) {
        return invalidPhone;
      }
      return undefined;
    });
    return _this;
  }
  _createClass(PhoneNumberField, [{
    key: "renderInput",
    value: function renderInput() {
      return React.createElement(InputMaskField, _extends({}, this.props, {
        mask: this.state.phoneMask,
        formatChars: phoneMaskFormatChars,
        inputType: "tel",
        className: styles.phoneNumber,
        onGetValidationMessages: this.getValidationMessages,
        insertMode: true
      }));
    }
  }, {
    key: "render",
    value: function render() {
      return this.renderInput();
    }
  }], [{
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, prevState) {
      return mappingDelta({
        phoneMask: PhoneNumberField.getMask(nextProps)
      }, prevState);
    }
  }, {
    key: "getMask",
    value: function getMask(_ref) {
      var countryCode = _ref.countryCode,
        phoneNumberType = _ref.phoneNumberType,
        withCountryPrefix = _ref.withCountryPrefix;
      var phoneMask = '';
      var samplePhoneNumber = getSamplePhoneNumber(countryCode, phoneNumberType);
      if (samplePhoneNumber) {
        phoneMask = samplePhoneNumber.replace(/\d/g, '9');
        if (withCountryPrefix) {
          var prefix = getPrefixForCountry(countryCode);
          if (prefix !== 0) {
            var escapedPrefix = prefix.toString().replace(/9/g, '\\9');
            phoneMask = "+".concat(escapedPrefix, " ").concat(phoneMask);
          }
        }
      }
      return phoneMask;
    }
  }]);
  return PhoneNumberField;
}(PureComponent);
_defineProperty(PhoneNumberField, "propTypes", _objectSpread({}, FieldComponent.propTypes, {
  maskChar: PropTypes.string,
  alwaysShowMask: PropTypes.bool,
  countryCode: PropTypes.string,
  phoneNumberType: PropTypes.number,
  withCountryPrefix: PropTypes.bool,
  messageProps: PropTypes.shape({
    invalidPhone: intlMessageShape
  }),
  value: PropTypes.string
}, FieldIcon.propTypes));
_defineProperty(PhoneNumberField, "defaultProps", {
  countryCode: 'US',
  maskChar: '_',
  phoneNumberType: 0
});
PhoneNumberField.__docgenInfo = {
  componentName: "PhoneNumberField",
  packageName: "@jutro/components",
  description: "PhoneNumberField is a 'field' component that displays a label, control and message. It can be used to render\na phone number mask on a HTML5 <input> element with type 'tel'.",
  displayName: "PhoneNumberField",
  methods: [{
    name: "getMask",
    docblock: null,
    modifiers: ["static"],
    params: [{
      name: "{ countryCode, phoneNumberType, withCountryPrefix }",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "getValidationMessages",
    docblock: "Get the validation messages to display if the phone number is not valid\n\n@returns {Array<any>} validation messages",
    modifiers: [],
    params: [],
    returns: {
      description: "validation messages",
      type: {
        name: "Array",
        elements: [{
          name: "any"
        }]
      }
    },
    description: "Get the validation messages to display if the phone number is not valid"
  }, {
    name: "renderInput",
    docblock: "Render control for this component.\n\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  actualName: "PhoneNumberField",
  metadataType: "field",
  props: {
    maskChar: {
      type: {
        name: "string"
      },
      required: false,
      description: "The character used in the mask, '_' is used by default.\nNOTE: Mask char should not be the same as one of possible input characters because this can generate unintended incorrect values.",
      defaultValue: {
        value: "'_'",
        computed: false
      }
    },
    alwaysShowMask: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, the mask is always visible"
    },
    countryCode: {
      type: {
        name: "string"
      },
      required: false,
      description: "A country code to automatically set the input mask. The codes are based on the two letter ISO 3166-1 codes.\nExample codes: US, PL, GB, FR, IE",
      defaultValue: {
        value: "'US'",
        computed: false
      }
    },
    phoneNumberType: {
      type: {
        name: "number"
      },
      required: false,
      description: "A phone number type used when generating the countryCode based mask. Following are possible values:\n        FIXED_LINE = 0,\n        MOBILE = 1,\n        FIXED_LINE_OR_MOBILE = 2,\n        TOLL_FREE = 3,\n        PREMIUM_RATE = 4,\n        SHARED_COST = 5,\n        VOIP = 6,\n        PERSONAL_NUMBER = 7,\n        PAGER = 8,\n        UAN = 9,\n        VOICEMAIL = 10,",
      defaultValue: {
        value: "0",
        computed: false
      }
    },
    withCountryPrefix: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If set to true, the mask will be prefixed with the country code. Works only\nin combination with coutryCode"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          invalidPhone: {
            name: "union",
            description: "Validation message for invalid phone number",
            required: false,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          }
        }
      },
      required: false,
      description: "Message props(error message/aria-label)"
    },
    value: {
      type: {
        name: "string"
      },
      required: false,
      description: "Value to display in control"
    }
  },
  composes: ["../FieldComponent/FieldComponent", "../FieldComponent/FieldIcon"]
};