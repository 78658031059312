import _defineProperty from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _Array$isArray from "@babel/runtime-corejs3/core-js-stable/array/is-array";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { uniqueInnerId } from '@jutro/platform';
import styles from "./DropdownMenu.module.css";
import { DropdownMenuHeader } from './DropdownMenuHeader';
export var DropdownMenu = function DropdownMenu(_ref) {
  var id = _ref.id,
    renderTrigger = _ref.renderTrigger,
    _ref$onRenderTrigger = _ref.onRenderTrigger,
    onRenderTrigger = _ref$onRenderTrigger === void 0 ? renderTrigger : _ref$onRenderTrigger,
    isOpen = _ref.isOpen,
    dropUp = _ref.dropUp,
    alignRight = _ref.alignRight,
    className = _ref.className,
    menuClassName = _ref.menuClassName,
    children = _ref.children,
    focused = _ref.focused,
    openMenuId = _ref.openMenuId,
    onMenuOpen = _ref.onMenuOpen,
    onMenuClose = _ref.onMenuClose;
  var _context;
  var dropdownMenuRef = useRef();
  var triggerRef = useRef();
  var _useState = useState(isOpen !== null && isOpen !== void 0 ? isOpen : false),
    _useState2 = _slicedToArray(_useState, 2),
    isMenuOpen = _useState2[0],
    setIsMenuOpen = _useState2[1];
  var componentClasses = cx(styles.dropdownMenuPosition, className, {
    opened: isMenuOpen
  });
  var menuClasses = cx(styles.menu, _defineProperty({}, styles['menu-top'], dropUp), _defineProperty({}, styles['menu-left'], alignRight), _defineProperty({}, styles['menu-active'], isMenuOpen), menuClassName);
  var _uniqueInnerId = uniqueInnerId(id, 'triggerId', 'menuId'),
    triggerId = _uniqueInnerId.triggerId,
    menuId = _uniqueInnerId.menuId;
  var hideMenuOnClickOut = useCallback(function () {
    if (isMenuOpen) {
      toggleMenu({
        open: false,
        moveFocusToTrigger: true,
        callbackOnClose: true
      });
    }
  });
  var keyPressHandler = useCallback(function (event) {
    if (event.key === 'Escape') {
      toggleMenu({
        open: false,
        moveFocusToTrigger: true,
        callbackOnClose: true
      });
    }
  });
  var toggleMenu = useCallback(function (_ref2) {
    var open = _ref2.open,
      moveFocusToTrigger = _ref2.moveFocusToTrigger,
      callbackOnClose = _ref2.callbackOnClose;
    var newIsOpen = open === undefined ? !isMenuOpen : !!open;
    if (isMenuOpen === newIsOpen) {
      return;
    }
    if (newIsOpen) {
      onMenuOpen === null || onMenuOpen === void 0 ? void 0 : onMenuOpen(id);
    }
    if (!newIsOpen && moveFocusToTrigger) {
      var _triggerRef$current, _triggerRef$current$f;
      (_triggerRef$current = triggerRef.current) === null || _triggerRef$current === void 0 ? void 0 : (_triggerRef$current$f = _triggerRef$current.focus) === null || _triggerRef$current$f === void 0 ? void 0 : _triggerRef$current$f.call(_triggerRef$current);
    }
    if (!newIsOpen && callbackOnClose) {
      onMenuClose === null || onMenuClose === void 0 ? void 0 : onMenuClose();
    }
    setIsMenuOpen(newIsOpen);
  }, [isMenuOpen, onMenuOpen, onMenuClose]);
  var blurHandler = useCallback(function (event) {
    if (dropdownMenuRef.current.contains(event.relatedTarget)) {
      return;
    }
    event.preventDefault();
    toggleMenu({
      open: false,
      callbackOnClose: true,
      moveFocusToTrigger: false
    });
  }, [toggleMenu]);
  useEffect(function () {
    if (isOpen !== undefined) {
      toggleMenu({
        open: isOpen
      });
    }
  }, [isOpen]);
  useEffect(function () {
    if (focused) {
      var _triggerRef$current2, _triggerRef$current2$;
      (_triggerRef$current2 = triggerRef.current) === null || _triggerRef$current2 === void 0 ? void 0 : (_triggerRef$current2$ = _triggerRef$current2.focus) === null || _triggerRef$current2$ === void 0 ? void 0 : _triggerRef$current2$.call(_triggerRef$current2);
    }
  }, [focused]);
  useEffect(function () {
    window.addEventListener('click', hideMenuOnClickOut);
    window.addEventListener('keydown', keyPressHandler);
    window.addEventListener('blur', blurHandler);
    return function () {
      window.removeEventListener('click', hideMenuOnClickOut);
      window.removeEventListener('keydown', keyPressHandler);
      window.removeEventListener('blur', blurHandler);
    };
  }, [hideMenuOnClickOut, keyPressHandler, blurHandler]);
  var dropdownTrigger = useMemo(function () {
    return onRenderTrigger && onRenderTrigger({
      id: triggerId,
      menuId: menuId,
      isOpen: isMenuOpen,
      ref: triggerRef,
      'aria-haspopup': true,
      'aria-expanded': isMenuOpen
    }, function (open) {
      return toggleMenu({
        open: open,
        moveFocusToTrigger: true,
        callbackOnClose: true
      });
    });
  }, [onRenderTrigger, triggerId, menuId, isMenuOpen, triggerRef, toggleMenu]);
  var renderItem = function renderItem(child) {
    return React.cloneElement(child, {
      onMenuItemClick: function onMenuItemClick() {
        var _triggerRef$current3, _triggerRef$current3$;
        onMenuClose === null || onMenuClose === void 0 ? void 0 : onMenuClose();
        (_triggerRef$current3 = triggerRef.current) === null || _triggerRef$current3 === void 0 ? void 0 : (_triggerRef$current3$ = _triggerRef$current3.focus) === null || _triggerRef$current3$ === void 0 ? void 0 : _triggerRef$current3$.call(_triggerRef$current3);
      }
    });
  };
  var mappedChildren = _mapInstanceProperty(_context = React.Children.toArray(children)).call(_context, function (child) {
    var item = renderItem(child);
    if (child.type === DropdownMenuHeader && child.props.children && _Array$isArray(child.props.children) && children.length) {
      var _context2;
      var itemChildren = _mapInstanceProperty(_context2 = React.Children.toArray(item.props.children)).call(_context2, function (subChild) {
        if (subChild.props.disabled) {
          return subChild;
        }
        var subItem = renderItem(subChild);
        return subItem;
      });
      item = React.cloneElement(child, {
        children: itemChildren
      });
    }
    return item;
  });
  return React.createElement("div", {
    className: componentClasses,
    id: id,
    ref: dropdownMenuRef,
    onBlur: blurHandler
  }, dropdownTrigger, React.createElement("div", {
    "data-testid": "menu-panel",
    id: menuId,
    className: menuClasses,
    "aria-hidden": !isMenuOpen,
    "aria-labelledby": triggerId
  }, mappedChildren));
};
DropdownMenu.propTypes = {
  id: PropTypes.string.isRequired,
  renderTrigger: PropTypes.func,
  onRenderTrigger: PropTypes.func,
  isOpen: PropTypes.bool,
  dropUp: PropTypes.bool,
  alignRight: PropTypes.bool,
  className: PropTypes.string,
  menuClassName: PropTypes.string,
  children: PropTypes.node,
  focused: PropTypes.bool,
  openMenuId: PropTypes.string,
  onMenuOpen: PropTypes.func,
  onMenuClose: PropTypes.func
};
DropdownMenu.__docgenInfo = {
  componentName: "DropdownMenu",
  packageName: "@jutro/router",
  description: "A dropdown menu where you can add your actions to each item.",
  displayName: "DropdownMenu",
  methods: [],
  actualName: "DropdownMenu",
  metadataType: "container",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "Used to identify menu component."
    },
    renderTrigger: {
      type: {
        name: "func"
      },
      required: false,
      description: "*** deprecated *** The function that generates a component that the user clicks on to show the menu."
    },
    onRenderTrigger: {
      type: {
        name: "func"
      },
      required: false,
      description: "The function that generates a component that the user clicks on to show the menu.",
      defaultValue: {
        value: "renderTrigger",
        computed: true
      }
    },
    isOpen: {
      type: {
        name: "bool"
      },
      required: false,
      description: "The prop that indicates if the menu is currently visible."
    },
    dropUp: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If `true`, the menu appears above the trigger component."
    },
    alignRight: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If `true`, items are aligned to the right edge of the menu."
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Override class for dropdown menu"
    },
    menuClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Override class for the inner menu of the dropdown menu"
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "The component children wrapped by the dropdown menu component."
    },
    focused: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If `true`, root element is considered as a currently active one"
    },
    openMenuId: {
      type: {
        name: "string"
      },
      required: false,
      description: "Optional id of the component instance that's supposed be to currently opened.\nMostly useful when rendering multiple instances next to each other (e.g. within Header)"
    },
    onMenuOpen: {
      type: {
        name: "func"
      },
      required: false,
      description: "Optional callback that's supposed to be triggered when menu gets opened"
    },
    onMenuClose: {
      type: {
        name: "func"
      },
      required: false,
      description: "Optional callback that's supposed to be triggered when menu gets closed"
    }
  }
};