import _defineProperty from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from "./BurgerMenu.module.css";
import { DropdownMenuSeparator } from '../DropdownMenu/DropdownMenuSeparator';
import { DropdownMenuLink } from '../DropdownMenu/DropdownMenuLink';
export var BurgerMenuLink = function BurgerMenuLink(_ref) {
  var id = _ref.id,
    hideSeparator = _ref.hideSeparator,
    separatorClassName = _ref.separatorClassName,
    className = _ref.className,
    props = _objectWithoutProperties(_ref, ["id", "hideSeparator", "separatorClassName", "className"]);
  return React.createElement(React.Fragment, null, !hideSeparator && React.createElement(DropdownMenuSeparator, {
    className: cx(styles.sidebarSeparator, separatorClassName)
  }), React.createElement(DropdownMenuLink, _extends({
    id: id,
    className: cx(styles.sidebarItem, styles.sidebarItemInteractive, className)
  }, props)));
};
BurgerMenuLink.propTypes = _objectSpread({}, DropdownMenuLink.propTypes, {
  id: PropTypes.string.isRequired,
  hideSeparator: PropTypes.bool,
  separatorClassName: PropTypes.string,
  children: PropTypes.node
});
BurgerMenuLink.defaultProps = {
  tag: 'div'
};
BurgerMenuLink.__docgenInfo = {
  componentName: "BurgerMenuLink",
  packageName: "@jutro/router",
  description: "BurgerMenuLink renders single burger menu content element",
  displayName: "BurgerMenuLink",
  methods: [],
  actualName: "BurgerMenuLink",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "Component unique identifier"
    },
    hideSeparator: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Do not render separator if true"
    },
    separatorClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Optional css class to the burger sidebar item separator"
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "The item's content."
    },
    tag: {
      defaultValue: {
        value: "'div'",
        computed: false
      },
      required: false
    }
  },
  composes: ["../DropdownMenu/DropdownMenuLink"]
};