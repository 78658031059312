import _readOnlyError from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/readOnlyError";
import _defineProperty2 from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty2(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _setTimeout from "@babel/runtime-corejs3/core-js-stable/set-timeout";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _bindInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/bind";
import _trimInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/trim";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import cloneDeep from "lodash/cloneDeep";
import omitBy from "lodash/omitBy";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isObject from "lodash/isObject";
import isFunction from "lodash/isFunction";
import isUndefined from "lodash/isUndefined";
import { log } from '@jutro/logger';
import { TranslatorContext } from '@jutro/locale';
import { intlMessageShape, DATA_TYPE_OBJECT, getDisplayName, DATA_TYPE_STRING, nestedTooltipShape } from '@jutro/prop-types';
import { ContextConsumer, uniqueInnerId, getMessageProp } from '@jutro/platform';
import { publish, JUTRO_TOPICS } from '@jutro/events';
import { metadataTypes } from '@jutro/uimetadata';
import { BreakpointTracker, BreakpointTrackerContext } from '@jutro/layout';
import { isEmptyValue, isNilValue, VISIBLE_IF_REQUIRED } from '@jutro/data';
import styles from "./FieldComponent.module.css";
import { TooltipIcon } from '../../Tooltip/TooltipIcon';
import { InlineFieldLayout } from './InlineFieldLayout';
import { FieldLabel } from './FieldLabel';
import { FieldMessage } from './FieldMessage';
import { Icon } from '../../Icon/Icon';
import { messages } from './FieldComponent.messages';
export var FieldComponent = function (_PureComponent) {
  _inherits(FieldComponent, _PureComponent);
  var _super = _createSuper(FieldComponent);
  function FieldComponent(_props) {
    var _context, _context2;
    var _this;
    _classCallCheck(this, FieldComponent);
    _this = _super.call(this, _props);
    _defineProperty(_assertThisInitialized(_this), "getDataTypeAwareSelectedValue", function () {
      var _this$props = _this.props,
        dataType = _this$props.dataType,
        value = _this$props.value;
      if (dataType === DATA_TYPE_OBJECT && !isEmptyValue(value)) {
        var selectedValue = isNilValue(value.code) ? value.id : value.code;
        return !isNilValue(selectedValue) && selectedValue.toString() || undefined;
      }
      return value;
    });
    _defineProperty(_assertThisInitialized(_this), "handleAvailableValuesValueChange", function (targetValue) {
      var _this$props2 = _this.props,
        dataType = _this$props2.dataType,
        availableValues = _this$props2.availableValues;
      var value;
      if (dataType === DATA_TYPE_OBJECT) {
        value = _findInstanceProperty(availableValues).call(availableValues, findAvailableValue(targetValue));
      } else {
        value = tryToCastToBoolean(targetValue);
      }
      _this.notifyChange(value);
    });
    _defineProperty(_assertThisInitialized(_this), "handleChange", function (evt) {
      var value = evt.target.value;
      if (typeof value === 'string' && _this.props.autoTrim) {
        value = _trimInstanceProperty(value).call(value);
      }
      _this.notifyChange(value);
    });
    _defineProperty(_assertThisInitialized(_this), "handleFocus", _bindInstanceProperty(_context = _this.handleFocus).call(_context, _assertThisInitialized(_this)));
    _defineProperty(_assertThisInitialized(_this), "handleBlur", _bindInstanceProperty(_context2 = _this.handleBlur).call(_context2, _assertThisInitialized(_this)));
    _defineProperty(_assertThisInitialized(_this), "renderFieldComponent", function (breakpoint) {
      var _props$inputType;
      var props = BreakpointTracker.applyBreakpointOverrides(_objectSpread({}, _this.props, {
        phone: _objectSpread({
          labelPosition: 'top'
        }, _this.props.phone)
      }), breakpoint);
      var breakpointProps = _objectSpread({}, props, {
        inputType: (_props$inputType = props.inputType) !== null && _props$inputType !== void 0 ? _props$inputType : 'text'
      });
      var id = breakpointProps.id,
        layout = breakpointProps.layout,
        inputType = breakpointProps.inputType,
        className = breakpointProps.className,
        readOnly = breakpointProps.readOnly,
        hideLabel = breakpointProps.hideLabel,
        label = breakpointProps.label,
        visible = breakpointProps.visible,
        isDesignMode = breakpointProps.isDesignMode,
        required = breakpointProps.required,
        schemaRequired = breakpointProps.schemaRequired,
        showValidationIcon = breakpointProps.showValidationIcon,
        successMessage = breakpointProps.successMessage,
        labelPosition = breakpointProps.labelPosition,
        showInlineLabel = breakpointProps.showInlineLabel;
      var _uniqueInnerId = uniqueInnerId(id, 'labelId'),
        labelId = _uniqueInnerId.labelId;
      var layoutComponent = _this.getLayoutComponent();
      var isHidden = visible === false;
      if (visible === VISIBLE_IF_REQUIRED) {
        isHidden = required !== true && schemaRequired !== true;
      }
      var validationMessages = _this.getValidationMessages();
      if (validationMessages && !isEmpty(validationMessages) || !_this.pristine) {
        isHidden = false;
      }
      var isValid = _this.isValid(validationMessages);
      if (isHidden === true && !isDesignMode) {
        return null;
      }
      var automationProperty = _this.generateAutomationProperty();
      var labelContent = _this.renderLabel(breakpointProps);
      var controlContent = readOnly ? _this.renderControlReadOnly(breakpointProps, labelContent ? {
        labelId: labelId
      } : undefined) : _this.renderControl(breakpointProps, labelContent ? {
        isValid: isValid,
        labelId: labelId
      } : {
        isValid: isValid
      });
      var messageContent = _this.renderMessages(validationMessages, successMessage, isValid);
      var relativeClass = _this.shouldRenderTooltipIcon() && hideLabel !== true && label !== '' ? styles.relative : null;
      if (layoutComponent) {
        return layoutComponent({
          labelContent: labelContent,
          controlContent: controlContent,
          messageContent: messageContent,
          className: cx(relativeClass, className)
        });
      }
      var outerStyle = cx(_defineProperty2(_defineProperty2(_defineProperty2(_defineProperty2(_defineProperty2(_defineProperty2({}, styles.fieldComponent, layout !== 'plain'), styles.left, labelPosition === 'left'), styles.reversed, layout === 'reversed'), styles.fullWidth, layout === 'full-width'), styles.fullWidthSimple, layout === 'full-width-simple'), styles.hidden, inputType === 'hidden'), relativeClass, className);
      var innerStyle = cx(styles.contentContainer, _defineProperty2(_defineProperty2({}, styles.controls, layout !== 'plain'), styles.controlReadOnly, readOnly));
      var validationIcon = _this.renderValidationIcon(showValidationIcon, readOnly, isValid, styles);
      return React.createElement("div", _extends({
        className: outerStyle
      }, automationProperty), labelContent, React.createElement("div", {
        className: innerStyle
      }, controlContent, validationIcon, messageContent), labelPosition === 'left' && !showInlineLabel && _this.renderTooltipIcon(breakpointProps));
    });
    _defineProperty(_assertThisInitialized(_this), "getLayoutComponent", function () {
      return _this.props.layout === 'inline' ? InlineFieldLayout : undefined;
    });
    _this.pristine = true;
    _this.focused = false;
    _this.focusPristine = true;
    _this.beforeValue = null;
    _this.valid = true;
    return _this;
  }
  _createClass(FieldComponent, [{
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps) {
      var prevMessages = prevProps.validationMessages;
      var _this$props3 = this.props,
        validationMessages = _this$props3.validationMessages,
        value = _this$props3.value;
      if (!isEqual(validationMessages, prevMessages)) {
        this.validate(value);
      }
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this$props4 = this.props,
        defaultValue = _this$props4.defaultValue,
        value = _this$props4.value,
        availableValues = _this$props4.availableValues,
        dataType = _this$props4.dataType,
        isDesignMode = _this$props4.isDesignMode;
      var newValue = null;
      if (isDesignMode) {
        newValue = defaultValue;
      } else if (defaultValue !== undefined && (value === undefined || value === null)) {
        if (defaultValue === FieldComponent.firstAvailable) {
          if (availableValues) {
            newValue = getConvertedAvailableValue(availableValues[0], dataType);
          }
        } else if (defaultValue === FieldComponent.lastAvailable) {
          if (availableValues) {
            newValue = getConvertedAvailableValue(availableValues[availableValues.length - 1], dataType);
          }
        } else if (defaultValue === FieldComponent.onlyAvailable) {
          if (availableValues && availableValues.length === 1) {
            newValue = getConvertedAvailableValue(availableValues[0], dataType);
          }
        } else {
          newValue = defaultValue;
        }
      } else {
        this.validate(value);
      }
      if (isDesignMode && value || newValue !== undefined && newValue !== null) {
        this.notifyChange(newValue);
      }
      publish(JUTRO_TOPICS.COMPONENT_LOADED, FieldComponent.fieldEventFormatter(_objectSpread({
        componentName: this.constructor.name
      }, this.props)));
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.updateTimeout) {
        clearTimeout(this.updateTimeout);
      }
    }
  }, {
    key: "isRequired",
    value: function isRequired() {
      var _this$props5 = this.props,
        required = _this$props5.required,
        schemaRequired = _this$props5.schemaRequired;
      return required || schemaRequired;
    }
  }, {
    key: "showErrors",
    value: function showErrors() {
      if (this.focused && !this.focusPristine) {
        return false;
      }
      var showErrors = this.props.showErrors;
      return showErrors === true || !this.pristine;
    }
  }, {
    key: "handleFocus",
    value: function handleFocus(evt) {
      var onFocus = this.props.onFocus;
      this.focused = true;
      this.focusPristine = true;
      if (onFocus) {
        onFocus(evt, this.beforeValue);
      }
      this.beforeValue = cloneDeep(this.props.value);
    }
  }, {
    key: "getInputFocusHandlers",
    value: function getInputFocusHandlers() {
      return {
        onFocus: this.handleFocus,
        onBlur: this.handleBlur
      };
    }
  }, {
    key: "getValidationMessages",
    value: function getValidationMessages() {
      var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.getValue();
      var _this$props6 = this.props,
        validationMessages = _this$props6.validationMessages,
        requiredFieldValidationMessage = _this$props6.requiredFieldValidationMessage,
        validator = _this$props6.validator,
        messageProps = _this$props6.messageProps,
        dataType = _this$props6.dataType,
        readOnly = _this$props6.readOnly;
      var requiredField = getMessageProp('requiredField', messageProps, messages);
      if (this.isRequired() && this.isEmpty(value) && isEmpty(validationMessages) && !readOnly) {
        var translator = this.translator;
        return [requiredFieldValidationMessage || translator(requiredField)];
      }
      if (validator && !this.isEmpty() && dataType === DATA_TYPE_STRING) {
        var _value$match;
        var pattern = validator.pattern,
          message = validator.message;
        if (pattern && message && !((_value$match = value.match) !== null && _value$match !== void 0 && _value$match.call(value, pattern))) {
          var _translator = this.translator;
          return [_translator(message)];
        }
      }
      return validationMessages;
    }
  }, {
    key: "getValue",
    value: function getValue() {
      return this.props.value;
    }
  }, {
    key: "isEmpty",
    value: function isEmpty() {
      var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.props.value;
      return isEmptyValue(value);
    }
  }, {
    key: "handleBlur",
    value: function handleBlur(evt) {
      var _this2 = this;
      var onBlur = this.props.onBlur;
      var validationMessages = this.getValidationMessages();
      var translator = this.translator;
      this.focused = false;
      if (!this.focusPristine && (!isEmpty(validationMessages) || !this.pristine)) {
        this.updateTimeout = _setTimeout(function () {
          _this2.forceUpdate();
        }, 200);
      }
      if (onBlur) {
        var _this$props7 = this.props,
          id = _this$props7.id,
          label = _this$props7.label,
          value = _this$props7.value,
          model = _this$props7.model,
          path = _this$props7.path,
          dataPath = _this$props7.dataPath;
        onBlur(evt, {
          id: id,
          label: translator(label),
          value: value,
          beforeValue: this.beforeValue,
          model: model || path,
          dataPath: dataPath
        });
      }
      publish(JUTRO_TOPICS.FIELD_BLUR, FieldComponent.fieldEventFormatter(_objectSpread({}, this.props, {
        beforeValue: this.beforeValue
      })));
    }
  }, {
    key: "validate",
    value: function validate(value) {
      var noNotification = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var _this$props8 = this.props,
        onValidationChange = _this$props8.onValidationChange,
        model = _this$props8.model,
        path = _this$props8.path,
        readOnly = _this$props8.readOnly;
      if (!onValidationChange || readOnly) {
        return undefined;
      }
      var currentValidationMessages = this.getValidationMessages(value) || [];
      var wasValid = this.valid;
      var isValid;
      if (this.isRequired()) {
        isValid = !isEmptyValue(value) && isEmpty(currentValidationMessages);
      } else {
        isValid = isEmptyValue(value) || isEmpty(currentValidationMessages);
      }
      if (wasValid !== isValid && !noNotification) {
        onValidationChange(isValid, model || path, currentValidationMessages);
      }
      this.valid = isValid;
      return isValid;
    }
  }, {
    key: "notifyChange",
    value: function notifyChange(value) {
      var translator = this.translator;
      this.pristine = false;
      this.focusPristine = false;
      var _this$props9 = this.props,
        onValueChange = _this$props9.onValueChange,
        model = _this$props9.model,
        path = _this$props9.path,
        id = _this$props9.id,
        label = _this$props9.label,
        dataPath = _this$props9.dataPath,
        nullable = _this$props9.nullable;
      if (nullable && value === '') value = undefined;
      if (onValueChange) {
        onValueChange(value, model || path, {
          id: id,
          label: translator(label),
          beforeValue: this.beforeValue,
          dataPath: dataPath
        });
      }
      publish(JUTRO_TOPICS.VALUE_CHANGED, FieldComponent.fieldEventFormatter(_objectSpread({}, this.props, {
        value: value,
        beforeValue: this.beforeValue
      })));
      this.validate(value);
    }
  }, {
    key: "renderTooltipIcon",
    value: function renderTooltipIcon(breakpointProps) {
      if (!this.shouldRenderTooltipIcon()) {
        return null;
      }
      var idProp = breakpointProps.id,
        labelPosition = breakpointProps.labelPosition,
        showInlineLabel = breakpointProps.showInlineLabel;
      var _uniqueInnerId2 = uniqueInnerId(idProp, 'tooltipId'),
        tooltipId = _uniqueInnerId2.tooltipId;
      var _this$getTooltipIconP = this.getTooltipIconProps(),
        _this$getTooltipIconP2 = _this$getTooltipIconP.id,
        id = _this$getTooltipIconP2 === void 0 ? tooltipId : _this$getTooltipIconP2,
        _this$getTooltipIconP3 = _this$getTooltipIconP.placement,
        placement = _this$getTooltipIconP3 === void 0 ? 'top' : _this$getTooltipIconP3,
        other = _objectWithoutProperties(_this$getTooltipIconP, ["id", "placement"]);
      var tooltipProps = _objectSpread({
        id: id,
        placement: placement
      }, other, {
        labelPosition: labelPosition,
        showInlineLabel: showInlineLabel
      });
      return React.createElement(TooltipIcon, tooltipProps);
    }
  }, {
    key: "getTooltipIconProps",
    value: function getTooltipIconProps() {
      var tooltip = this.props.tooltip;
      return isObject(tooltip) ? tooltip : {
        text: tooltip
      };
    }
  }, {
    key: "shouldRenderTooltipIcon",
    value: function shouldRenderTooltipIcon() {
      var layout = this.props.layout;
      var _this$getTooltipIconP4 = this.getTooltipIconProps(),
        text = _this$getTooltipIconP4.text,
        title = _this$getTooltipIconP4.title,
        renderContent = _this$getTooltipIconP4.renderContent;
      return !!(text || title || renderContent) && layout !== 'plain';
    }
  }, {
    key: "renderLabel",
    value: function renderLabel(breakpointProps, className, secondaryClassName, controlId) {
      var translator = this.translator;
      var id = breakpointProps.id,
        label = breakpointProps.label,
        readOnly = breakpointProps.readOnly,
        disabled = breakpointProps.disabled,
        hideLabel = breakpointProps.hideLabel,
        labelClassName = breakpointProps.labelClassName,
        showRequired = breakpointProps.showRequired,
        showOptional = breakpointProps.showOptional,
        secondaryLabel = breakpointProps.secondaryLabel,
        secondaryLabelClassName = breakpointProps.secondaryLabelClassName,
        labelPosition = breakpointProps.labelPosition,
        showInlineLabel = breakpointProps.showInlineLabel;
      var _uniqueInnerId3 = uniqueInnerId(id, 'labelId'),
        labelId = _uniqueInnerId3.labelId;
      var tooltipIcon = (labelPosition !== 'left' || showInlineLabel) && this.renderTooltipIcon(breakpointProps);
      var layoutComponent = this.getLayoutComponent();
      var layoutComponentClassName = layoutComponent === null || layoutComponent === void 0 ? void 0 : layoutComponent.labelClass;
      var combinedClassName = cx(layoutComponentClassName, labelClassName, className);
      var combinedSecondaryClassName = cx(secondaryLabelClassName, secondaryClassName);
      var labelProps = {
        id: labelId,
        htmlFor: controlId || id,
        label: label,
        required: this.isRequired(),
        readOnly: readOnly,
        disabled: disabled,
        hideLabel: hideLabel,
        showRequired: showRequired,
        showOptional: showOptional,
        translator: translator,
        className: combinedClassName,
        secondaryLabel: secondaryLabel,
        secondaryLabelClassName: combinedSecondaryClassName,
        labelPosition: labelPosition,
        showInlineLabel: showInlineLabel
      };
      return label && label !== '' ? React.createElement(FieldLabel, _extends({}, labelProps, {
        tooltipIcon: tooltipIcon
      })) : null;
    }
  }, {
    key: "renderControlReadOnly",
    value: function renderControlReadOnly(breakpointProps) {
      var id = breakpointProps.id,
        value = breakpointProps.value,
        availableValues = breakpointProps.availableValues,
        className = breakpointProps.className;
      var primaryValue = value;
      var secondaryValue = null;
      if (availableValues && value !== undefined && value !== null) {
        var translator = this.translator;
        var actualValue = isNilValue(value.code) ? value.id : value.code;
        var valueAsString = !isNilValue(actualValue) && actualValue.toString() || value.toString();
        var selectedValue = _findInstanceProperty(availableValues).call(availableValues, findAvailableValue(valueAsString));
        if (selectedValue) {
          var name = selectedValue.name || selectedValue.displayName;
          primaryValue = translator(name);
          secondaryValue = translator(selectedValue.secondaryLabel);
        }
      }
      if (this.shouldDisplayDefaultROValue(primaryValue)) {
        primaryValue = FieldComponent.defaultROEmptyValue;
        secondaryValue = null;
      }
      var classes = cx(styles.controlReadOnly, className);
      return React.createElement("div", {
        id: id,
        "data-read-only": true,
        className: classes
      }, primaryValue, secondaryValue ? React.createElement("span", {
        className: styles.controlReadOnlySecondary
      }, secondaryValue) : '');
    }
  }, {
    key: "shouldDisplayDefaultROValue",
    value: function shouldDisplayDefaultROValue(value) {
      return !value;
    }
  }, {
    key: "renderControl",
    value: function renderControl(breakpointProps, options) {
      var id = breakpointProps.id,
        inputType = breakpointProps.inputType;
      if (false) {
        throw new Error('"renderControl()" method is not overridden in component' + "'<".concat(getDisplayName(this), " id=\"").concat(id, "\" inputType=\"").concat(inputType, "\" />'"));
      }
      return this.renderControlReadOnly(breakpointProps);
    }
  }, {
    key: "generateAutomationProperty",
    value: function generateAutomationProperty() {
      if (false || false) {
        var gwTest = this.props.gwTest;
        if (gwTest) {
          return _defineProperty2({}, "data-gw-test-".concat(gwTest), '');
        }
      }
      return undefined;
    }
  }, {
    key: "generateDataPathProperty",
    value: function generateDataPathProperty() {
      var dataPath = this.props.dataPath;
      return {
        'data-path': dataPath
      };
    }
  }, {
    key: "generateAccessibilityProperties",
    value: function generateAccessibilityProperties() {
      var translator = this.translator;
      var _this$props10 = this.props,
        id = _this$props10.id,
        required = _this$props10.required,
        schemaRequired = _this$props10.schemaRequired,
        placeholder = _this$props10.placeholder,
        label = _this$props10.label;
      var _uniqueInnerId4 = uniqueInnerId(id, 'messageId'),
        messageId = _uniqueInnerId4.messageId;
      var formattedPlaceholder = translator(placeholder);
      var formattedLabel = translator(label);
      return _objectSpread({
        'aria-required': required || schemaRequired,
        'aria-describedby': messageId,
        'aria-label': formattedLabel !== null && formattedLabel !== void 0 ? formattedLabel : formattedPlaceholder
      }, !this.isValid(this.getValidationMessages()) && {
        'aria-invalid': true
      }, {
        placeholder: formattedPlaceholder
      });
    }
  }, {
    key: "getMessages",
    value: function getMessages(validationMessages, successMessage, isValid) {
      if (!isValid) {
        validationMessages = _filterInstanceProperty(validationMessages).call(validationMessages, function (msg) {
          if (msg) {
            return true;
          }
          log.error('This component is set to show errors, but the validation error message is undefined.');
          return false;
        });
        return {
          errorMessage: function errorMessage() {
            return _mapInstanceProperty(validationMessages).call(validationMessages, function (msg) {
              return React.createElement("div", {
                key: msg
              }, msg);
            });
          }
        };
      }
      if (successMessage) {
        return {
          successMessage: successMessage
        };
      }
      return {};
    }
  }, {
    key: "renderMessages",
    value: function renderMessages(validationMessages, successMessage, isValid) {
      var translator = this.translator;
      var readOnly = this.props.readOnly;
      var _uniqueInnerId5 = uniqueInnerId(this.props.id, 'messageId'),
        messageId = _uniqueInnerId5.messageId;
      var messagesList = this.getMessages(validationMessages, successMessage, isValid);
      var messageProps = {
        translator: translator
      };
      if (!readOnly) {
        return React.createElement(FieldMessage, _extends({
          id: messageId
        }, messagesList, messageProps));
      }
      return undefined;
    }
  }, {
    key: "renderValidationIcon",
    value: function renderValidationIcon(showValidationIcon, readOnly, isValid) {
      if (!showValidationIcon || readOnly) {
        return null;
      }
      var circleClasses = cx('icon-stack-2x', isValid ? styles.validStateIcon : styles.invalidStateIcon);
      var markIcon = isValid ? 'check_circle_outline' : 'report';
      var icon = React.createElement("div", {
        className: cx('icon-stack')
      }, React.createElement(Icon, {
        icon: markIcon,
        className: circleClasses
      }));
      var isDirty = !this.pristine && !this.focused;
      if (!isValid || isDirty) {
        return React.createElement("span", {
          className: styles.validationIcon
        }, icon);
      }
      return null;
    }
  }, {
    key: "isValid",
    value: function isValid(validationMessages) {
      return !this.showErrors() || isEmpty(validationMessages);
    }
  }, {
    key: "render",
    value: function render() {
      return React.createElement(ContextConsumer, {
        contexts: [BreakpointTrackerContext]
      }, this.renderFieldComponent);
    }
  }, {
    key: "translator",
    get: function get() {
      return this.context;
    }
  }]);
  return FieldComponent;
}(PureComponent);
_defineProperty(FieldComponent, "metadataType", metadataTypes.FIELD);
_defineProperty(FieldComponent, "propTypes", {
  id: PropTypes.string.isRequired,
  label: intlMessageShape,
  secondaryLabel: intlMessageShape,
  tooltip: PropTypes.oneOfType([PropTypes.string, nestedTooltipShape]),
  placeholder: intlMessageShape,
  required: PropTypes.bool,
  schemaRequired: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  nullable: PropTypes.bool,
  visible: PropTypes.bool,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  autoTrim: PropTypes.bool,
  onValueChange: PropTypes.func,
  onValidationChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  model: PropTypes.object,
  path: PropTypes.string,
  showErrors: PropTypes.bool,
  showRequired: PropTypes.bool,
  showOptional: PropTypes.bool,
  validationMessages: PropTypes.arrayOf(intlMessageShape),
  layout: PropTypes.string,
  hideLabel: PropTypes.bool,
  className: PropTypes.string,
  controlClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  secondaryLabelClassName: PropTypes.string,
  gwTest: PropTypes.string,
  showValidationIcon: PropTypes.bool,
  dataPath: PropTypes.string,
  validator: PropTypes.shape({
    pattern: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired
  }),
  requiredFieldValidationMessage: PropTypes.string,
  successMessage: PropTypes.string,
  messageProps: PropTypes.shape({
    requiredField: intlMessageShape
  }),
  labelPosition: PropTypes.oneOf(['top', 'left']),
  phone: PropTypes.object,
  phoneWide: PropTypes.object,
  tablet: PropTypes.object,
  inputType: PropTypes.string
});
_defineProperty(FieldComponent, "defaultProps", {
  autoTrim: false,
  required: false,
  schemaRequired: false,
  readOnly: false,
  disabled: false,
  showErrors: false,
  hideLabel: false,
  showValidationIcon: false,
  dataPath: '',
  labelPosition: 'top',
  dataType: DATA_TYPE_STRING
});
_defineProperty(FieldComponent, "contextType", TranslatorContext);
_defineProperty(FieldComponent, "defaultROEmptyValue", '-');
_defineProperty(FieldComponent, "firstAvailable", '__FIRST_AVAILABLE__');
_defineProperty(FieldComponent, "lastAvailable", '__LAST_AVAILABLE__');
_defineProperty(FieldComponent, "onlyAvailable", '__ONLY_AVAILABLE__');
_defineProperty(FieldComponent, "fieldEventFormatter", function (props) {
  return omitBy(props, function (val) {
    return isFunction(val) || isUndefined(val);
  });
});
var tryToCastToBoolean = function tryToCastToBoolean(value) {
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  return value;
};
var getConvertedAvailableValue = function getConvertedAvailableValue(availableValue, dataType) {
  if (dataType === DATA_TYPE_OBJECT) {
    return availableValue;
  }
  return availableValue.code;
};
var findAvailableValue = function findAvailableValue(targetValue) {
  return function (option) {
    return isNilValue(option.code) ? option.id.toString() === targetValue : option.code.toString() === targetValue;
  };
};
FieldComponent.__docgenInfo = {
  componentName: "FieldComponent",
  packageName: "@jutro/components",
  description: "Base class for field components. A field component renders label, control and messages. Other fields should extend\nthis class. Override any methods as needed: `renderLabel()`, `renderControl()`, `renderMessages()`. By default,\nthis class will render an <input> control.",
  displayName: "FieldComponent",
  methods: [{
    name: "fieldEventFormatter",
    docblock: "Removes props which are functions or undefined\n\n@param {object} props - component props\n@returns {object}",
    modifiers: ["static"],
    params: [{
      name: "props",
      description: "component props",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: null,
      type: {
        name: "object"
      }
    },
    description: "Removes props which are functions or undefined"
  }, {
    name: "isRequired",
    docblock: "Determines requiredness of the field.\n\n@returns {boolean} true if field is required",
    modifiers: [],
    params: [],
    returns: {
      description: "true if field is required",
      type: {
        name: "boolean"
      }
    },
    description: "Determines requiredness of the field."
  }, {
    name: "showErrors",
    docblock: "Determines whether errors should be displayed. Errors are displayed as highlight of control and as separate\nmessage block.\n\n@returns {boolean} true if errors should be shown; false if errors should be hidden",
    modifiers: [],
    params: [],
    returns: {
      description: "true if errors should be shown; false if errors should be hidden",
      type: {
        name: "boolean"
      }
    },
    description: "Determines whether errors should be displayed. Errors are displayed as highlight of control and as separate\nmessage block."
  }, {
    name: "getDataTypeAwareSelectedValue",
    docblock: "Get the code of the currently selected availableValue.\nIf using dataType=DATA_TYPE_OBJECT, return the `code` from the value.\nOtherwise return the value itself\n\n@returns {any} - The selected value",
    modifiers: [],
    params: [],
    returns: {
      description: "The selected value",
      type: {
        name: "any"
      }
    },
    description: "Get the code of the currently selected availableValue.\nIf using dataType=DATA_TYPE_OBJECT, return the `code` from the value.\nOtherwise return the value itself"
  }, {
    name: "handleAvailableValuesValueChange",
    docblock: "Change handler to be shared by components using availableValues\n\n@param {any} targetValue - The new value to set",
    modifiers: [],
    params: [{
      name: "targetValue",
      description: "The new value to set",
      type: {
        name: "any"
      },
      optional: false
    }],
    returns: null,
    description: "Change handler to be shared by components using availableValues"
  }, {
    name: "handleChange",
    docblock: "Default change handler for `input` element. It will be used unless this method or `renderControl()`\nis overridden.\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: null,
    description: "Default change handler for `input` element. It will be used unless this method or `renderControl()`\nis overridden."
  }, {
    name: "handleFocus",
    docblock: "Handle 'focus' events for 'input' elements with validation.\nThis method is not included by default. Derived class will need to include this in their `renderControl()` method\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: null,
    description: "Handle 'focus' events for 'input' elements with validation.\nThis method is not included by default. Derived class will need to include this in their `renderControl()` method"
  }, {
    name: "getInputFocusHandlers",
    docblock: "Get focus/blur handlers for use in derived input fields. This optimizes the validation behavior when the\ninput field is in focus.\n\n@returns {object} focus and blur handlers to be added to `<input>` fields",
    modifiers: [],
    params: [],
    returns: {
      description: "focus and blur handlers to be added to `<input>` fields",
      type: {
        name: "object"
      }
    },
    description: "Get focus/blur handlers for use in derived input fields. This optimizes the validation behavior when the\ninput field is in focus."
  }, {
    name: "getValidationMessages",
    docblock: "Get the validation messages to display.\nIf field is 'required' and no validation message is provided, it is added\n\n@param {*} value current value of input\n@returns {Array} validation messages",
    modifiers: [],
    params: [{
      name: "value",
      description: "current value of input",
      type: {
        name: "mixed"
      },
      optional: false
    }],
    returns: {
      description: "validation messages",
      type: {
        name: "Array"
      }
    },
    description: "Get the validation messages to display.\nIf field is 'required' and no validation message is provided, it is added"
  }, {
    name: "getValue",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "isEmpty",
    docblock: "Tests if component is empty\n\n@param {*} value value to check\n@returns {boolean}",
    modifiers: [],
    params: [{
      name: "value",
      description: "value to check",
      type: {
        name: "mixed"
      },
      optional: false
    }],
    returns: {
      description: null,
      type: {
        name: "boolean"
      }
    },
    description: "Tests if component is empty"
  }, {
    name: "handleBlur",
    docblock: "Handle 'focus' events for 'input' elements with validation.\nThis method is not included by default. Derived class will need to include this in their `renderControl()` method\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: null,
    description: "Handle 'focus' events for 'input' elements with validation.\nThis method is not included by default. Derived class will need to include this in their `renderControl()` method"
  }, {
    name: "validate",
    docblock: "validate the selected value for 'input' elements.\nThis method will triggered validation if value is changed for required field.\n\n@param {any} value  - selected value to validate\n@param {boolean} [noNotification=false]  - optional flag for skipping the validation change notification",
    modifiers: [],
    params: [{
      name: "value",
      description: "selected value to validate",
      type: {
        name: "any"
      },
      optional: false
    }, {
      name: "noNotification"
    }],
    returns: null,
    description: "validate the selected value for 'input' elements.\nThis method will triggered validation if value is changed for required field."
  }, {
    name: "notifyChange",
    docblock: "Helper method to invoke callback when value is changed; useful in derived classes\n\n@param {any} value - new value of control to send with notification",
    modifiers: [],
    params: [{
      name: "value",
      description: "new value of control to send with notification",
      type: {
        name: "any"
      },
      optional: false
    }],
    returns: null,
    description: "Helper method to invoke callback when value is changed; useful in derived classes"
  }, {
    name: "renderTooltipIcon",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpointProps",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "getTooltipIconProps",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "shouldRenderTooltipIcon",
    docblock: "Determines whether tooltip icon should be rendered\n\n@param {string|object} [tooltip] - tooltip icon component\n@param {string} [layout] - tooltip icon component\n@returns {boolean} true/false value indicating whether tooltip icon should be rendered",
    modifiers: [],
    params: [],
    returns: null,
    description: "Determines whether tooltip icon should be rendered"
  }, {
    name: "renderLabel",
    docblock: "Render label for this component. Apply 'required' styling as appropriate.\n\n@param {object} breakpointProps - object with props according to the breakpoint\n@param {string} [className] - custom class applied to the label\n@param {string} [secondaryClassName] - custom class applied to the secondary label\n@returns {React.ReactElement} JSX for the label",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "object with props according to the breakpoint",
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "className"
    }, {
      name: "secondaryClassName"
    }, {
      name: "controlId"
    }],
    returns: null,
    description: "Render label for this component. Apply 'required' styling as appropriate."
  }, {
    name: "renderControlReadOnly",
    docblock: "Render readonly control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render readonly control for this component."
  }, {
    name: "shouldDisplayDefaultROValue",
    docblock: "Should display default readOnly char?\n\n@param {string} value\n\n@returns {boolean} true if value is empty",
    modifiers: [],
    params: [{
      name: "value",
      description: null,
      type: {
        name: "string"
      },
      optional: false
    }],
    returns: {
      description: "true if value is empty",
      type: {
        name: "boolean"
      }
    },
    description: "Should display default readOnly char?"
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@param {object} options - additional options\n\n@returns {React.ReactNode} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "options",
      description: "additional options",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactNode"
      }
    },
    description: "Render control for this component."
  }, {
    name: "generateAutomationProperty",
    docblock: "Generates the automation property used for automation tests\nthe property should be visible only in dev or test environmnents\n\n@returns {object|undefined} The automation property attribute when gwTest is provided via props",
    modifiers: [],
    params: [],
    returns: {
      description: "The automation property attribute when gwTest is provided via props",
      type: {
        name: "union",
        elements: [{
          name: "object"
        }, null]
      }
    },
    description: "Generates the automation property used for automation tests\nthe property should be visible only in dev or test environmnents"
  }, {
    name: "generateDataPathProperty",
    docblock: "generates the data-path attribute\n\n@returns {object} the data-path attribute when dataPath is provided via props",
    modifiers: [],
    params: [],
    returns: {
      description: "the data-path attribute when dataPath is provided via props",
      type: {
        name: "object"
      }
    },
    description: "generates the data-path attribute"
  }, {
    name: "generateAccessibilityProperties",
    docblock: "generates accessibility properties for the field component\n\n@returns {object} set of applicable wai-aria tags",
    modifiers: [],
    params: [],
    returns: {
      description: "set of applicable wai-aria tags",
      type: {
        name: "object"
      }
    },
    description: "generates accessibility properties for the field component"
  }, {
    name: "getMessages",
    docblock: null,
    modifiers: [],
    params: [{
      name: "validationMessages",
      optional: undefined,
      type: null
    }, {
      name: "successMessage",
      optional: undefined,
      type: null
    }, {
      name: "isValid",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "renderMessages",
    docblock: "Render messages for this component. Messages are expected to be translated before passing in.\n\n@param {Array<any>} validationMessages - error messages to render\n@param {string} successMessage - info message to render\n@param {boolean} isValid - indicates whether the component is valid or not\n@returns {React.ReactElement}   JSX for the messages",
    modifiers: [],
    params: [{
      name: "validationMessages",
      description: "error messages to render",
      type: {
        name: "Array",
        elements: [{
          name: "any"
        }]
      },
      optional: false
    }, {
      name: "successMessage",
      description: "info message to render",
      type: {
        name: "string"
      },
      optional: false
    }, {
      name: "isValid",
      description: "indicates whether the component is valid or not",
      type: {
        name: "boolean"
      },
      optional: false
    }],
    returns: {
      description: "JSX for the messages",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render messages for this component. Messages are expected to be translated before passing in."
  }, {
    name: "renderValidationIcon",
    docblock: null,
    modifiers: [],
    params: [{
      name: "showValidationIcon",
      optional: undefined,
      type: null
    }, {
      name: "readOnly",
      optional: undefined,
      type: null
    }, {
      name: "isValid",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "isValid",
    docblock: null,
    modifiers: [],
    params: [{
      name: "validationMessages",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "translator",
    docblock: "Return translator from context or default translator if\nthe former is undefined\n\n@returns {Function}",
    modifiers: ["get"],
    params: [],
    returns: {
      description: null,
      type: {
        name: "Function"
      }
    },
    description: "Return translator from context or default translator if\nthe former is undefined"
  }, {
    name: "renderFieldComponent",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpoint",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "getLayoutComponent",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }],
  actualName: "FieldComponent",
  metadataType: "field",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "Used to identify the component. applied to control and referenced by label"
    },
    label: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Label text to display; if not provided, uses '[id]' for development"
    },
    secondaryLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Secondary label text to display; if not provided, uses '[id]' for development"
    },
    tooltip: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "custom",
          raw: "nestedTooltipShape"
        }]
      },
      required: false,
      description: "Tooltip text to display or tooltip object to pass to TooltipIcon"
    },
    placeholder: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Placeholder to display on empty component"
    },
    required: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, this field is required",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    schemaRequired: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, this field is required by schema",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    readOnly: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, this field is readonly",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, this field is disabled",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    nullable: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, this field returns undefined when the user deletes the data/selection on the input"
    },
    visible: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, this field is visible"
    },
    value: {
      type: {
        name: "any"
      },
      required: false,
      description: "Value to display in control"
    },
    defaultValue: {
      type: {
        name: "any"
      },
      required: false,
      description: "Set the default field value on render if there is a default value; needs onValueChange to work"
    },
    autoTrim: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, will automatically trim string values on change",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    onValueChange: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback when value is changed; receives new value and (model or path) for this component"
    },
    onValidationChange: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback when validation is changed; receives 'isValid', (model or path) and validation message for this component"
    },
    onBlur: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback when blur event is fired"
    },
    onFocus: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback when focus event is fired"
    },
    model: {
      type: {
        name: "object"
      },
      required: false,
      description: "Passed as second argument to onValueChange"
    },
    path: {
      type: {
        name: "string"
      },
      required: false,
      description: "Passed as second argument to onValueChange if model is not present"
    },
    showErrors: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Show errors for this field",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    showRequired: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Show required indicator"
    },
    showOptional: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Show optional indicator"
    },
    validationMessages: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: false
              },
              defaultMessage: {
                name: "string",
                required: false
              },
              args: {
                name: "shape",
                value: {},
                required: false
              }
            }
          }]
        }
      },
      required: false,
      description: "Validation messages to show for this field; only rendered if 'showErrors' is true"
    },
    layout: {
      type: {
        name: "string"
      },
      required: false,
      description: "Layout to use with this field; default is more control and less label; other option is 'reversed'"
    },
    hideLabel: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Hides the label on any layout",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional style to apply to the component"
    },
    controlClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional style to apply to the control of the component"
    },
    labelClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional style to apply to the label of the component"
    },
    secondaryLabelClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional style to apply to the secondary label of the component"
    },
    gwTest: {
      type: {
        name: "string"
      },
      required: false,
      description: "The automation property to be applied to the field"
    },
    showValidationIcon: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Used to display or not the validation icon",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    dataPath: {
      type: {
        name: "string"
      },
      required: false,
      description: "The full path of view model",
      defaultValue: {
        value: "''",
        computed: false
      }
    },
    validator: {
      type: {
        name: "shape",
        value: {
          pattern: {
            name: "string",
            required: true
          },
          message: {
            name: "string",
            required: true
          }
        }
      },
      required: false,
      description: "An object which should contain a regex pattern as string and a validation message as string"
    },
    requiredFieldValidationMessage: {
      type: {
        name: "string"
      },
      required: false,
      description: "Used to override the default required field message"
    },
    successMessage: {
      type: {
        name: "string"
      },
      required: false,
      description: "Success message to apply to component if it is valid"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          requiredField: {
            name: "union",
            description: "Required message",
            required: false,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          }
        }
      },
      required: false,
      description: "Message props(error message/aria-label)"
    },
    labelPosition: {
      type: {
        name: "enum",
        value: [{
          value: "'top'",
          computed: false
        }, {
          value: "'left'",
          computed: false
        }]
      },
      required: false,
      description: "Allows to select label position",
      defaultValue: {
        value: "'top'",
        computed: false
      }
    },
    phone: {
      type: {
        name: "object"
      },
      required: false,
      description: "Include any FieldComponent property to use at 'phone' breakpoint"
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: false,
      description: "Include any FieldComponent property to use 'phoneWide' and 'phone' breakpoint"
    },
    tablet: {
      type: {
        name: "object"
      },
      required: false,
      description: "Include any FieldComponent property to use at 'tablet', 'phoneWide' and 'phone' breakpoint"
    },
    inputType: {
      type: {
        name: "string"
      },
      required: false,
      description: "Type attribute specifies the type of <input> element to display."
    },
    dataType: {
      defaultValue: {
        value: "DATA_TYPE_STRING",
        computed: true
      },
      required: false
    }
  }
};