import _defineProperty from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import cx from 'classnames';
import { intlMessageShape } from '@jutro/prop-types';
import { Icon } from '../../Icon/Icon';
import styles from "./ProgressPhase.module.css";
var phaseProgressLabelPropTypes = {
  title: intlMessageShape,
  stepTitle: intlMessageShape,
  icon: PropTypes.string,
  complete: PropTypes.bool,
  active: PropTypes.bool,
  isError: PropTypes.bool
};
export var PhaseProgressLabel = function PhaseProgressLabel(_ref) {
  var title = _ref.title,
    stepTitle = _ref.stepTitle,
    icon = _ref.icon,
    complete = _ref.complete,
    active = _ref.active,
    isError = _ref.isError;
  var translator = useContext(TranslatorContext);
  var getIconName = function getIconName() {
    if (isError) return 'mi-error_outline';
    if (complete) return 'mi-done';
    return icon;
  };
  var iconComponent = icon && React.createElement(Icon, {
    icon: getIconName(),
    className: styles.icon
  });
  var labelStyles = cx(styles.label, _defineProperty({}, styles.error, isError));
  return React.createElement("div", {
    className: labelStyles
  }, iconComponent, React.createElement("div", {
    className: styles.labelText
  }, React.createElement("span", {
    className: styles.title
  }, translator(title)), active && React.createElement("div", {
    className: styles.activeStep
  }, translator(stepTitle))));
};
PhaseProgressLabel.propTypes = phaseProgressLabelPropTypes;
PhaseProgressLabel.__docgenInfo = {
  componentName: "PhaseProgressLabel",
  packageName: "@jutro/components",
  description: "PhaseProgressLabel",
  displayName: "PhaseProgressLabel",
  methods: [],
  actualName: "PhaseProgressLabel",
  props: {
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Phase title"
    },
    stepTitle: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Current step title"
    },
    icon: {
      type: {
        name: "string"
      },
      required: false,
      description: "Name of displayed icon"
    },
    complete: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Flag indicating if phase is complete"
    },
    active: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Flag indicating if phase is active"
    },
    isError: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Flag indicating if phase is in error state"
    }
  }
};