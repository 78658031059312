import _defineProperty from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from "./Container.module.css";
var containerPropTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  fluid: PropTypes.bool
};
export var Container = function Container(_ref) {
  var children = _ref.children,
    className = _ref.className,
    fluid = _ref.fluid;
  var containerClasses = cx(styles.container, className, _defineProperty({}, styles.fluid, fluid));
  return React.createElement("div", {
    className: containerClasses
  }, children);
};
Container.propTypes = containerPropTypes;
Container.__docgenInfo = {
  componentName: "Container",
  packageName: "@jutro/components",
  description: "Container element which wraps some content.",
  displayName: "Container",
  methods: [],
  actualName: "Container",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for component."
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "The container's content."
    },
    fluid: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Span the entire width."
    }
  }
};