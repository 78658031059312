import _defineProperty2 from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty2(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _get from "@babel/runtime-corejs3/helpers/get";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';
import { FieldComponent } from '../FieldComponent/FieldComponent';
import { Checkbox } from './Checkbox';
import styles from "./CheckboxField.module.css";
import { getComponentId } from '../uniqueIds';
function isChecked(value) {
  return !_.isNil(value) && value.toString() === 'true';
}
export var CheckboxField = function (_FieldComponent) {
  _inherits(CheckboxField, _FieldComponent);
  var _super = _createSuper(CheckboxField);
  _createClass(CheckboxField, null, [{
    key: "isContentVisible",
    value: function isContentVisible(value) {
      return isChecked(value);
    }
  }]);
  function CheckboxField(props, context) {
    var _thisSuper, _this;
    _classCallCheck(this, CheckboxField);
    _this = _super.call(this, props, context);
    _defineProperty(_assertThisInitialized(_this), "renderInlineLabel", function () {
      var classes = cx(styles.checkboxElementWrapper);
      return React.createElement("div", {
        className: classes
      }, _get((_thisSuper = _assertThisInitialized(_this), _getPrototypeOf(CheckboxField.prototype)), "renderLabel", _thisSuper).call(_thisSuper, _this.props, styles.inlineLabel, undefined, _this.controlUniqueId));
    });
    _defineProperty(_assertThisInitialized(_this), "isInlineLabelVisible", function () {
      var _this$props = _this.props,
        showInlineLabel = _this$props.showInlineLabel,
        hideLabel = _this$props.hideLabel;
      return showInlineLabel || hideLabel;
    });
    _defineProperty(_assertThisInitialized(_this), "renderControl", function (breakpointProps) {
      return React.createElement(Checkbox, _extends({}, breakpointProps, _this.baseProps));
    });
    _defineProperty(_assertThisInitialized(_this), "renderControlReadOnly", function (breakpointProps) {
      return React.createElement(Checkbox, _extends({}, breakpointProps, _this.baseProps, {
        disabled: true
      }));
    });
    _defineProperty(_assertThisInitialized(_this), "handleClick", function (evt) {
      evt === null || evt === void 0 ? void 0 : evt.stopPropagation();
      evt === null || evt === void 0 ? void 0 : evt.preventDefault();
      var _this$props2 = _this.props,
        value = _this$props2.value,
        disabled = _this$props2.disabled,
        readOnly = _this$props2.readOnly;
      if (!disabled && !readOnly) {
        _this.notifyChange(!isChecked(value));
      }
    });
    _defineProperty(_assertThisInitialized(_this), "handleCheckChange", function (evt) {
      var value = evt.target.checked;
      _this.notifyChange(value);
    });
    _defineProperty(_assertThisInitialized(_this), "handleKeyDown", function (evt) {
      if (evt.key === 'Enter' || evt.key === ' ') {
        var value = _this.props.value;
        var checked = isChecked(value);
        _this.notifyChange(!checked);
        evt.preventDefault();
      }
    });
    var id = props.id;
    _this.controlUniqueId = getComponentId(id);
    return _this;
  }
  _createClass(CheckboxField, [{
    key: "render",
    value: function render() {
      return _get(_getPrototypeOf(CheckboxField.prototype), "render", this).call(this);
    }
  }, {
    key: "renderLabel",
    value: function renderLabel() {
      if (this.isInlineLabelVisible()) {
        return null;
      }
      return _get(_getPrototypeOf(CheckboxField.prototype), "renderLabel", this).call(this, this.props, undefined, undefined, this.controlUniqueId);
    }
  }, {
    key: "baseProps",
    get: function get() {
      return {
        id: this.controlUniqueId,
        onClick: this.handleClick,
        checked: isChecked(this.props.value),
        onBlur: this.handleBlur,
        onFocus: this.handleFocus,
        onKeyDown: this.handleKeyDown,
        onChange: this.handleCheckChange,
        accessibilityProps: this.generateAccessibilityProperties(),
        renderInlineLabel: this.renderInlineLabel
      };
    }
  }]);
  return CheckboxField;
}(FieldComponent);
_defineProperty(CheckboxField, "propTypes", _objectSpread({}, FieldComponent.propTypes, {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  showInlineLabel: PropTypes.bool,
  detailElement: PropTypes.node,
  children: PropTypes.node
}));
CheckboxField.__docgenInfo = {
  componentName: "CheckboxField",
  packageName: "@jutro/components",
  description: "Displays a label, clickable box, and message.",
  displayName: "CheckboxField",
  methods: [{
    name: "isContentVisible",
    docblock: null,
    modifiers: ["static"],
    params: [{
      name: "value",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "renderInlineLabel",
    docblock: "Render label for this component. Override FieldComponent renderLabel\n\n@returns {React.ReactElement} JSX for the inline label",
    modifiers: [],
    params: [],
    returns: {
      description: "JSX for the inline label",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render label for this component. Override FieldComponent renderLabel"
  }, {
    name: "renderLabel",
    docblock: "Render label for this component. Override FieldComponent renderLabel\n\n@returns {React.ReactElement} JSX for the label",
    modifiers: [],
    params: [],
    returns: {
      description: "JSX for the label",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render label for this component. Override FieldComponent renderLabel"
  }, {
    name: "isInlineLabelVisible",
    docblock: "Check is inline label is visible\n@returns {boolean} whether the inline label is visible",
    modifiers: [],
    params: [],
    returns: {
      description: "whether the inline label is visible",
      type: {
        name: "boolean"
      }
    },
    description: "Check is inline label is visible"
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }, {
    name: "renderControlReadOnly",
    docblock: "Renders Checkbox that is read only\n@param {object} breakpointProps - breakpoint-specific props\n\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Renders Checkbox that is read only"
  }, {
    name: "baseProps",
    docblock: null,
    modifiers: ["get"],
    params: [],
    returns: null
  }, {
    name: "handleClick",
    docblock: null,
    modifiers: [],
    params: [{
      name: "evt",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "handleCheckChange",
    docblock: "Custom change handler for `checkbox` element. Uses `notifyChange` to invoke onValueChange callback\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: null,
    description: "Custom change handler for `checkbox` element. Uses `notifyChange` to invoke onValueChange callback"
  }, {
    name: "handleKeyDown",
    docblock: "Custom change handler for `checkbox` element. Uses `notifyChange` to invoke onValueChange callback\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: null,
    description: "Custom change handler for `checkbox` element. Uses `notifyChange` to invoke onValueChange callback"
  }],
  actualName: "CheckboxField",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }]
      },
      required: false,
      description: "Boolean or string value"
    },
    defaultValue: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }]
      },
      required: false,
      description: "Default value"
    },
    showInlineLabel: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, displays label inline"
    },
    detailElement: {
      type: {
        name: "node"
      },
      required: false,
      description: "Node to render when checkbox is selected, as processed by `renderContentFromMetadata` method (metadata 3.0)"
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "Children to render when checkbox is selected (metadata 3.0)"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};