import _objectWithoutProperties from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import isEmpty from "lodash/isEmpty";
import { TranslatorContext } from '@jutro/locale';
import { intlMessageShape } from '@jutro/prop-types';
import styles from "./HelpPopover.module.css";
var helpComponentPropTypes = {
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.node, intlMessageShape]),
  tag: PropTypes.elementType
};
export var HelpElement = function HelpElement(_ref) {
  var content = _ref.content,
    className = _ref.className,
    Tag = _ref.tag,
    tagProps = _objectWithoutProperties(_ref, ["content", "className", "tag"]);
  var translator = useContext(TranslatorContext);
  if (isEmpty(content)) {
    return null;
  }
  var classes = cx(styles.helpComponent, className);
  return React.createElement(Tag, _extends({
    className: classes
  }, tagProps), translator(content));
};
HelpElement.propTypes = helpComponentPropTypes;
HelpElement.defaultProps = {
  tag: 'div'
};
HelpElement.__docgenInfo = {
  componentName: "HelpElement",
  packageName: "@jutro/components",
  description: "The `HelpComponent` component is designed as a simple component presenting text inside the Help dropdown on Application Header.",
  displayName: "HelpElement",
  methods: [],
  actualName: "HelpElement",
  metadataType: "element",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for component"
    },
    content: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: false
              },
              defaultMessage: {
                name: "string",
                required: false
              },
              args: {
                name: "shape",
                value: {},
                required: false
              }
            }
          }]
        }]
      },
      required: false,
      description: "The children elements to render"
    },
    tag: {
      type: {
        name: "elementType"
      },
      required: false,
      description: "Tag to render text in",
      defaultValue: {
        value: "'div'",
        computed: false
      }
    }
  }
};