import _objectWithoutProperties from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import React, { useRef, useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import { messages } from '../LookupField.messages';
import { GenericSelectComponents as components } from '../../GenericSelectControl/GenericSelectControl';
export var LookupMenu = function LookupMenu(_ref) {
  var children = _ref.children,
    props = _objectWithoutProperties(_ref, ["children"]);
  var translator = useContext(TranslatorContext);
  var showRecentBar = useRef(true);
  if (showRecentBar.current) {
    var _props$selectProps = props.selectProps,
      inputValue = _props$selectProps.inputValue,
      showRecent = _props$selectProps.showRecent;
    showRecentBar.current = !inputValue && showRecent;
  }
  var customStyles = props.selectProps.customStyles;
  return React.createElement(components.Menu, props, showRecentBar.current && React.createElement("div", {
    className: customStyles.recentBar
  }, translator(messages.recentlyViewed)), children);
};
LookupMenu.__docgenInfo = {
  componentName: "LookupMenu",
  packageName: "@jutro/components",
  description: "",
  displayName: "LookupMenu",
  methods: [],
  actualName: "LookupMenu"
};