import _defineProperty from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import React from 'react';
import PropTypes from 'prop-types';
import { createHOC } from '@jutro/platform';
import { intlMessageShape } from '@jutro/prop-types';
import { ValueComponentWrapper } from './ValueComponentWrapper';
import { FormattedDate, formattedDatePropTypes } from './FormattedDate';
var DateValueInternal = createHOC({
  component: FormattedDate,
  wrapper: ValueComponentWrapper,
  displayName: 'DateValue'
});
var dateValuePropTypes = _objectSpread({
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  path: PropTypes.string,
  tag: PropTypes.string,
  prefix: intlMessageShape,
  suffix: intlMessageShape
}, formattedDatePropTypes);
export var DateValue = function DateValue(props) {
  return React.createElement(DateValueInternal, props);
};
DateValue.propTypes = dateValuePropTypes;
DateValue.defaultProps = _objectSpread({
  tag: 'div'
}, FormattedDate.defaultProps);
DateValue.__docgenInfo = {
  componentName: "DateValue",
  packageName: "@jutro/components",
  description: "Renders a formatted date using the `tag` property to wrap\nthe value.",
  displayName: "DateValue",
  methods: [],
  actualName: "DateValue",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "Used to identify the component"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    },
    path: {
      type: {
        name: "string"
      },
      required: false,
      description: "Hint path to value"
    },
    tag: {
      type: {
        name: "string"
      },
      required: false,
      description: "The html tag to use when rendering the outermost element of this component",
      defaultValue: {
        value: "'div'",
        computed: false
      }
    },
    prefix: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Optional prefix message to be attached in front of the value"
    },
    suffix: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Optional suffix message to be attached at the end the value"
    }
  },
  composes: ["./FormattedDate"]
};