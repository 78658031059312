import _extends2 from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/extends";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';
import { intlMessageShape } from '@jutro/prop-types';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';
import { messages } from './AlertModal.messages';
var defaultConfirmButtonText = messages.confirmButtonText;
var alertModalPropTypes = {
  isOpen: PropTypes.bool,
  onResolve: PropTypes.func.isRequired,
  parentSelector: PropTypes.func,
  icon: PropTypes.string,
  title: intlMessageShape.isRequired,
  message: intlMessageShape,
  status: PropTypes.oneOf(['info', 'warning', 'error', 'success']),
  confirmButtonText: intlMessageShape
};
export var AlertModal = function AlertModal(_ref) {
  var props = _extends2({}, _ref);
  var cancelButtonText = props.cancelButtonText,
    alertProps = _objectWithoutProperties(props, ["cancelButtonText"]);
  return React.createElement(ConfirmationModal, _extends({}, alertProps, {
    cancelButtonText: null
  }));
};
AlertModal.propTypes = alertModalPropTypes;
AlertModal.defaultProps = {
  confirmButtonText: defaultConfirmButtonText
};
AlertModal.__docgenInfo = {
  componentName: "AlertModal",
  packageName: "@jutro/components",
  description: "AlertModal",
  displayName: "AlertModal",
  methods: [],
  actualName: "AlertModal",
  props: {
    isOpen: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Boolean denoting if the modal is open"
    },
    onResolve: {
      type: {
        name: "func"
      },
      required: true,
      description: "Callback function called when the modal is confirmed, cancelled or closed"
    },
    parentSelector: {
      type: {
        name: "func"
      },
      required: false,
      description: "Function to find the modal's parent selector"
    },
    icon: {
      type: {
        name: "string"
      },
      required: false,
      description: "Material Icon name (mi-*)"
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: true,
      description: "Text to display for the title"
    },
    message: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "The message to be displayed in the modal"
    },
    status: {
      type: {
        name: "enum",
        value: [{
          value: "'info'",
          computed: false
        }, {
          value: "'warning'",
          computed: false
        }, {
          value: "'error'",
          computed: false
        }, {
          value: "'success'",
          computed: false
        }]
      },
      required: false,
      description: "The status of the warning. One of 'info', 'warning', 'error', 'success' or blank for default."
    },
    confirmButtonText: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "The text or message shape of the confirm button",
      defaultValue: {
        value: "messages.confirmButtonText",
        computed: true
      }
    }
  }
};