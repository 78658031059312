import _defineProperty2 from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty2(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import { mergeStyles } from '@jutro/platform';
import { FieldComponent } from '../FieldComponent/FieldComponent';
import textAreaStyles from "./TextAreaField.module.css";
import inputStyles from "../InputField/InputField.module.css";
export var TextAreaField = function (_FieldComponent) {
  _inherits(TextAreaField, _FieldComponent);
  var _super = _createSuper(TextAreaField);
  function TextAreaField(props) {
    var _this;
    _classCallCheck(this, TextAreaField);
    _this = _super.call(this, props);
    _defineProperty(_assertThisInitialized(_this), "onKeyPress", function (evt) {
      if (evt.key === 'Enter' && evt.altKey) {
        var _evt$target = evt.target,
          selectionStart = _evt$target.selectionStart,
          selectionEnd = _evt$target.selectionEnd;
        var _this$props$value = _this.props.value,
          value = _this$props$value === void 0 ? '' : _this$props$value;
        var newValue = "".concat(value.substring(0, selectionStart), "\n").concat(value.substring(selectionEnd));
        _this.textAreaRef.current.value = newValue;
        _this.textAreaRef.current.selectionEnd = selectionStart + 1;
        _this.notifyChange(newValue);
      }
    });
    _this.textAreaRef = React.createRef();
    return _this;
  }
  _createClass(TextAreaField, [{
    key: "render",
    value: function render() {
      return _get(_getPrototypeOf(TextAreaField.prototype), "render", this).call(this);
    }
  }, {
    key: "renderControlReadOnly",
    value: function renderControlReadOnly(breakpointProps) {
      var modifiedProps = _objectSpread({}, breakpointProps, {
        className: cx(breakpointProps.className, textAreaStyles.textAreaReadOnly)
      });
      return _get(_getPrototypeOf(TextAreaField.prototype), "renderControlReadOnly", this).call(this, modifiedProps);
    }
  }, {
    key: "renderControl",
    value: function renderControl(breakpointProps, options) {
      var id = breakpointProps.id,
        value = breakpointProps.value,
        disabled = breakpointProps.disabled,
        required = breakpointProps.required,
        maxLength = breakpointProps.maxLength,
        rows = breakpointProps.rows,
        controlClassName = breakpointProps.controlClassName;
      var focusHandlers = this.getInputFocusHandlers();
      var styles = mergeStyles(inputStyles, textAreaStyles);
      var classes = cx(styles.input, styles.textArea, {
        disabled: disabled,
        invalid: !options.isValid
      }, controlClassName);
      return React.createElement("textarea", _extends({
        id: id,
        ref: this.textAreaRef,
        className: classes,
        value: value || '',
        onChange: this.handleChange,
        onKeyPress: this.onKeyPress
      }, focusHandlers, {
        disabled: disabled,
        required: required,
        maxLength: maxLength,
        rows: rows
      }, this.generateDataPathProperty(), this.generateAccessibilityProperties()));
    }
  }]);
  return TextAreaField;
}(FieldComponent);
_defineProperty(TextAreaField, "propTypes", _objectSpread({}, FieldComponent.propTypes, {
  rows: PropTypes.number,
  maxLength: PropTypes.number
}));
_defineProperty(TextAreaField, "defaultProps", _objectSpread({}, FieldComponent.defaultProps, {
  rows: 3
}));
TextAreaField.__docgenInfo = {
  componentName: "TextAreaField",
  packageName: "@jutro/components",
  description: "Renders a multi-line text area.",
  displayName: "TextAreaField",
  methods: [{
    name: "renderControlReadOnly",
    docblock: "Render readonly control for TextArea.\n\n@param {object} breakpointProps - breakpoint-specific props\n\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render readonly control for TextArea."
  }, {
    name: "onKeyPress",
    docblock: "Custom onKeyPress handler for `textarea` element. Allows to break line when pressing Alt/Option + Enter. Uses `notifyChange` to invoke onValueChange callback\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: null,
    description: "Custom onKeyPress handler for `textarea` element. Allows to break line when pressing Alt/Option + Enter. Uses `notifyChange` to invoke onValueChange callback"
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@param {object} options - rendering options (like 'isInvalid', 'isValid', etc)\n\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "options",
      description: "rendering options (like 'isInvalid', 'isValid', etc)",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  actualName: "TextAreaField",
  metadataType: "field",
  props: {
    rows: {
      type: {
        name: "number"
      },
      required: false,
      description: "The rows attribute specifies the visible height of a text area, in lines.",
      defaultValue: {
        value: "3",
        computed: false
      }
    },
    maxLength: {
      type: {
        name: "number"
      },
      required: false,
      description: "Restricts the max length of the text in the text area field"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};