import _toConsumableArray from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _Number$isNaN from "@babel/runtime-corejs3/core-js-stable/number/is-nan";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { isSameDay, isValid, isBefore, isAfter, isEqual as dateIsEqual, format as dateFormat } from 'date-fns';
import defaultLocaleObject from 'date-fns/locale/en-US';
import cx from 'classnames';
import { DATA_TYPE_OBJECT, DATA_TYPE_DATE_TIME, intlMessageShape } from '@jutro/prop-types';
import { isEmptyValue } from '@jutro/data';
import isString from "lodash/isString";
import isNumber from "lodash/isNumber";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import { mergeStyles, getMessageProp } from '@jutro/platform';
import { messages } from './DateField.messages';
import datePickerStyles from "./DatePickerField.module.css";
import inputStyles from "../InputField/InputField.module.css";
import { FieldIcon } from '../FieldComponent/FieldIcon';
import { FieldComponent } from '../FieldComponent/FieldComponent';
import { CustomHeader } from './CustomHeader';
import { PopperContainer } from './PopperContainer';
import { DateValue } from '../../values/DateValue';
import { maxDefaultYear, minDefaultYear, getDateFormat, getTimeFormat } from './DateField.commons';
import { getPatternFromLocale, getInputDateFormats, normalizeInput, isDateShapeInRange } from './helpers';
var withoutTime = function withoutTime(date) {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
};
var minTimeOfTheDay = withoutTime(new Date());
var maxTimeOfTheDay = new Date();
maxTimeOfTheDay.setHours(23);
maxTimeOfTheDay.setMinutes(59);
maxTimeOfTheDay.setSeconds(59);
maxTimeOfTheDay.setMilliseconds(999);
export var DateFieldInternalDEPRECATED = function (_FieldComponent) {
  _inherits(DateFieldInternalDEPRECATED, _FieldComponent);
  var _super = _createSuper(DateFieldInternalDEPRECATED);
  function DateFieldInternalDEPRECATED(_props) {
    var _this;
    _classCallCheck(this, DateFieldInternalDEPRECATED);
    _this = _super.call(this, _props);
    _defineProperty(_assertThisInitialized(_this), "state", {
      isValidDate: true,
      isOpen: _this.props.isInitiallyOpen
    });
    _defineProperty(_assertThisInitialized(_this), "valueToDate", function (value) {
      var showTime = _this.props.showTime;
      var dateValue = value;
      if (value && isString(value)) {
        dateValue = showTime ? new Date(value) : withoutTime(new Date(value));
      }
      if (value && isNumber(value)) {
        dateValue = new Date(value);
      }
      if (value !== null && value !== void 0 && value.year) {
        var year = value.year,
          month = value.month,
          day = value.day,
          _value$hour = value.hour,
          hour = _value$hour === void 0 ? 0 : _value$hour,
          _value$minute = value.minute,
          minute = _value$minute === void 0 ? 0 : _value$minute;
        dateValue = showTime ? new Date(Date.UTC(year, month, day, hour, minute)) : withoutTime(new Date(Date.UTC(year, month, day)));
      }
      return dateValue;
    });
    _defineProperty(_assertThisInitialized(_this), "formatValue", function (value) {
      if (!value) {
        return undefined;
      }
      var _this$props = _this.props,
        dataType = _this$props.dataType,
        showTime = _this$props.showTime;
      if (!showTime) {
        var year = value.getTimezoneOffset() < 0 ? value.getFullYear() : value.getUTCFullYear();
        var month = value.getTimezoneOffset() < 0 ? value.getMonth() : value.getUTCMonth();
        var day = value.getTimezoneOffset() < 0 ? value.getDate() : value.getUTCDate();
        if (dataType === DATA_TYPE_OBJECT) {
          return {
            year: year,
            month: month,
            day: day
          };
        }
        var dateString = new Date(Date.UTC(year, month, day)).toISOString();
        return dataType === DATA_TYPE_DATE_TIME ? dateString : dateString.substr(0, 10);
      }
      if (dataType === DATA_TYPE_OBJECT) {
        return {
          year: value.getUTCFullYear(),
          month: value.getUTCMonth(),
          day: value.getUTCDate(),
          hour: value.getUTCHours(),
          minute: value.getUTCMinutes()
        };
      }
      value.setSeconds(0);
      value.setMilliseconds(0);
      return value.toISOString();
    });
    _defineProperty(_assertThisInitialized(_this), "handleChange", function (date) {
      if (!isEmptyValue(_this.userInput)) {
        date = new Date(new Date(_this.userInput).toUTCString());
        _this.userInput = undefined;
      }
      var _this$props2 = _this.props,
        minDate = _this$props2.minDate,
        maxDate = _this$props2.maxDate,
        showTime = _this$props2.showTime;
      var isEmptyDate = isEmptyValue(date);
      var adjustedDate = _this.adjustDate(date, _this.valueToDate(minDate), _this.valueToDate(maxDate), showTime);
      var isValidDate = isEmptyDate || isValid(adjustedDate) && _this.isInRange(adjustedDate);
      var value = !isEmptyDate && isValid(adjustedDate) ? adjustedDate : undefined;
      _this.setState({
        isValidDate: isValidDate
      });
      _this.notifyChange(value ? _this.formatValue(value) : value);
    });
    _defineProperty(_assertThisInitialized(_this), "handleChangeRaw", function (evt) {
      if (!isEmptyValue(evt.target.value)) {
        var userInput = normalizeInput(evt.target.value, _this.localePattern);
        var date = new Date(userInput);
        _this.userInput = userInput;
        if (_this.props.showTime || _Number$isNaN(date.getTime()) || !_this.isInRange(date)) {
          _this.handleChange(date);
        }
      }
    });
    _defineProperty(_assertThisInitialized(_this), "isInRange", function (date) {
      var _this$props3 = _this.props,
        minDate = _this$props3.minDate,
        maxDate = _this$props3.maxDate;
      return isDateShapeInRange(date, minDate, maxDate);
    });
    _defineProperty(_assertThisInitialized(_this), "renderPopperContainer", function (labelId) {
      return function (props) {
        return React.createElement(PopperContainer, _extends({}, props, {
          labelId: labelId,
          parentId: _this.props.id
        }));
      };
    });
    _defineProperty(_assertThisInitialized(_this), "renderCustomHeader", function (props) {
      var _this$props4 = _this.props,
        minDate = _this$props4.minDate,
        maxDate = _this$props4.maxDate,
        locale = _this$props4.locale;
      var min = minDate ? _this.valueToDate(minDate).getUTCFullYear() : minDefaultYear;
      var max = maxDate ? _this.valueToDate(maxDate).getUTCFullYear() : maxDefaultYear;
      return React.createElement(CustomHeader, _extends({}, props, {
        locale: locale,
        minYear: min,
        maxYear: max
      }));
    });
    _defineProperty(_assertThisInitialized(_this), "handleOnBlur", function (evt) {
      _this.focusPristine = false;
      _this.handleBlur(evt);
    });
    _defineProperty(_assertThisInitialized(_this), "setFocus", function () {
      var _this$datePickerRef, _this$datePickerRef$c;
      (_this$datePickerRef = _this.datePickerRef) === null || _this$datePickerRef === void 0 ? void 0 : (_this$datePickerRef$c = _this$datePickerRef.current) === null || _this$datePickerRef$c === void 0 ? void 0 : _this$datePickerRef$c.setFocus();
    });
    _defineProperty(_assertThisInitialized(_this), "renderTodayButton", function (todayButtonText) {
      var _assertThisInitialize = _assertThisInitialized(_this),
        translator = _assertThisInitialize.translator;
      return React.createElement("button", {
        className: datePickerStyles.todayButton,
        "aria-label": translator(todayButtonText || messages.today)
      }, translator(todayButtonText || messages.today));
    });
    _defineProperty(_assertThisInitialized(_this), "handleKeyDown", function (e) {
      if (_this.state.isOpen && e.key === 'Tab' && e.target.id === _this.props.id) {
        e.preventDefault();
        var selectedDay = _this.datePickerRef.current.calendar.componentNode && _this.datePickerRef.current.calendar.componentNode.querySelector('.react-datepicker__day[tabindex="0"]');
        if (selectedDay) {
          selectedDay.focus({
            preventScroll: true
          });
        }
      }
    });
    var _locale = _props.locale;
    _this.datePickerRef = React.createRef();
    _this.localePattern = getPatternFromLocale(!isEmpty(_locale) ? _locale : defaultLocaleObject);
    registerLocale(_locale.code, _locale);
    return _this;
  }
  _createClass(DateFieldInternalDEPRECATED, [{
    key: "render",
    value: function render() {
      return _get(_getPrototypeOf(DateFieldInternalDEPRECATED.prototype), "render", this).call(this);
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps) {
      var prevValue = prevProps.value,
        prevMessages = prevProps.validationMessages,
        prevLocale = prevProps.locale;
      var _this$props5 = this.props,
        value = _this$props5.value,
        validationMessages = _this$props5.validationMessages,
        locale = _this$props5.locale;
      if (!isEqual(prevValue, value) || !isEqual(prevMessages, validationMessages)) {
        this.validate(value);
      }
      if (!isEqual(prevLocale, locale)) {
        registerLocale(locale.code, locale);
      }
    }
  }, {
    key: "adjustDate",
    value: function adjustDate(date, minDate, maxDate, showTime) {
      if (!showTime) {
        return date;
      }
      if (isSameDay(minDate, date) && isAfter(minDate, date)) {
        return minDate;
      }
      if (isSameDay(maxDate, date) && isBefore(maxDate, date)) {
        return maxDate;
      }
      return date;
    }
  }, {
    key: "getTimeLimits",
    value: function getTimeLimits(selectedDate, minDate, maxDate) {
      var selectedDateWithoutTime = selectedDate ? withoutTime(selectedDate) : null;
      var minDateWithoutTime = minDate ? withoutTime(minDate) : null;
      var maxDateWithoutTime = maxDate ? withoutTime(maxDate) : null;
      var minTime;
      if (dateIsEqual(selectedDateWithoutTime, minDateWithoutTime)) {
        minTime = minDate;
      } else if (isBefore(selectedDateWithoutTime, minDateWithoutTime)) {
        minTime = maxTimeOfTheDay;
      } else {
        minTime = minTimeOfTheDay;
      }
      var maxTime = maxTimeOfTheDay;
      if (dateIsEqual(selectedDateWithoutTime, maxDateWithoutTime)) {
        maxTime = maxDate;
      } else if (isAfter(selectedDateWithoutTime, maxDateWithoutTime)) {
        minTime = maxTimeOfTheDay;
      }
      return {
        minTime: minTime,
        maxTime: maxTime
      };
    }
  }, {
    key: "getValidationMessages",
    value: function getValidationMessages() {
      var isValidDate = this.state.isValidDate;
      var incorrectInput = getMessageProp('incorrectInput', this.withThememessageProps, messages);
      var validationMessages = _get(_getPrototypeOf(DateFieldInternalDEPRECATED.prototype), "getValidationMessages", this).call(this) || [];
      return !isValidDate && isEmpty(validationMessages) ? [this.translator(incorrectInput)] : validationMessages;
    }
  }, {
    key: "getPlaceholderOrDefault",
    value: function getPlaceholderOrDefault(placeholder, translator) {
      var translatedLocalPattern = this.localePattern.replace('dd', this.translator(messages.dd)).replace('MM', this.translator(messages.mm)).replace('yyyy', this.translator(messages.yy));
      if (placeholder) {
        return translator(placeholder, {
          format: translatedLocalPattern
        });
      }
      var locale = this.props.locale;
      var now = new Date();
      var minute = now.getMinutes() >= 30 ? 30 : 0;
      now.setMinutes(minute);
      var time = dateFormat(now, 'p', {
        locale: locale
      });
      return "".concat(translatedLocalPattern, " ").concat(time);
    }
  }, {
    key: "renderControlReadOnly",
    value: function renderControlReadOnly(breakpointProps) {
      var id = breakpointProps.id,
        value = breakpointProps.value,
        showTime = breakpointProps.showTime,
        format = breakpointProps.format;
      var classes = cx(datePickerStyles.datePicker, datePickerStyles.readOnly);
      return React.createElement(DateValue, {
        id: id,
        className: classes,
        value: value,
        format: format,
        showTime: showTime
      });
    }
  }, {
    key: "renderControl",
    value: function renderControl(breakpointProps, options) {
      var _this2 = this;
      var combinedStyles = mergeStyles(inputStyles, datePickerStyles);
      var id = breakpointProps.id,
        disabled = breakpointProps.disabled,
        controlClassName = breakpointProps.controlClassName,
        required = breakpointProps.required,
        placeholder = breakpointProps.placeholder,
        minDate = breakpointProps.minDate,
        maxDate = breakpointProps.maxDate,
        autoComplete = breakpointProps.autoComplete,
        format = breakpointProps.format,
        showTime = breakpointProps.showTime,
        todayButtonText = breakpointProps.todayButtonText,
        popperPlacement = breakpointProps.popperPlacement,
        value = breakpointProps.value,
        intl = breakpointProps.intl,
        locale = breakpointProps.locale;
      var translator = this.translator;
      var _this$state = this.state,
        calendarId = _this$state.calendarId,
        isOpen = _this$state.isOpen;
      var minDateWithoutTime = minDate ? withoutTime(this.valueToDate(minDate)) : null;
      var maxDateWithoutTime = maxDate ? withoutTime(this.valueToDate(maxDate)) : null;
      var nowWithoutTime = withoutTime(new Date());
      var todayOutOfRange = isAfter(minDateWithoutTime, nowWithoutTime) || isBefore(maxDateWithoutTime, nowWithoutTime);
      var inputClasses = cx({
        disabled: disabled,
        invalid: !options.isValid
      }, combinedStyles.input, combinedStyles.datePicker, controlClassName);
      var calendarClass = cx({
        todayOutOfRange: todayOutOfRange
      });
      var selectedDate = this.valueToDate(value);
      var minDateObject = !isEmptyValue(minDate) ? this.valueToDate(minDate) : new Date(minDefaultYear, 0);
      var maxDateObject = !isEmptyValue(maxDate) ? this.valueToDate(maxDate) : new Date(maxDefaultYear, 0);
      var timeLimit = showTime ? this.getTimeLimits(selectedDate, minDateObject, maxDateObject) : {};
      var dateFormats = [getDateFormat(format)].concat(_toConsumableArray(getInputDateFormats(this.localePattern)));
      var inputElement = React.createElement(DatePicker, _extends({
        id: id,
        ref: this.datePickerRef,
        selected: typeof selectedDate === 'object' && isValid(selectedDate) ? selectedDate : null,
        onChange: this.handleChange,
        onChangeRaw: this.handleChangeRaw,
        onKeyDown: this.handleKeyDown,
        onSelect: this.setFocus,
        todayButton: this.renderTodayButton(todayButtonText),
        className: inputClasses,
        locale: locale.code,
        showTimeSelect: showTime,
        calendarClassName: calendarClass,
        minDate: minDateObject,
        maxDate: maxDateObject,
        minTime: timeLimit.minTime,
        maxTime: timeLimit.maxTime,
        popperPlacement: popperPlacement,
        popperModifiers: {
          preventOverflow: {
            enabled: true
          }
        },
        customInput: React.createElement("input", _extends({
          inputMode: "none"
        }, this.generateAccessibilityProperties())),
        dateFormat: showTime ? _mapInstanceProperty(dateFormats).call(dateFormats, function (pattern) {
          return "".concat(pattern).concat(getTimeFormat());
        }) : dateFormats,
        timeFormat: getTimeFormat(),
        isClearable: false,
        popperContainer: this.renderPopperContainer(options.labelId),
        renderCustomHeader: this.renderCustomHeader,
        required: required,
        disabled: disabled,
        autoComplete: autoComplete ? 'on' : 'off',
        placeholderText: this.getPlaceholderOrDefault(placeholder, translator, intl)
      }, this.generateDataPathProperty(), this.generateAccessibilityProperties(intl), this.getInputFocusHandlers(), {
        onBlur: this.handleOnBlur,
        open: isOpen,
        onCalendarOpen: function onCalendarOpen() {
          return _this2.setState({
            isOpen: true
          });
        },
        onCalendarClose: function onCalendarClose() {
          return _this2.setState({
            isOpen: false
          });
        }
      }));
      return React.createElement("div", {
        className: combinedStyles.datePicker,
        "aria-expanded": "true",
        "aria-haspopup": "true",
        "aria-controls": calendarId
      }, React.createElement(FieldIcon, breakpointProps, inputElement));
    }
  }]);
  return DateFieldInternalDEPRECATED;
}(FieldComponent);
_defineProperty(DateFieldInternalDEPRECATED, "displayName", 'DateFieldInternalDEPRECATED');
DateFieldInternalDEPRECATED.__docgenInfo = {
  componentName: "DateFieldInternalDEPRECATED",
  packageName: "@jutro/components",
  description: "Allows the user to either type or select a date. Displays them according to locale settings.",
  displayName: "DateFieldInternalDEPRECATED",
  methods: [{
    name: "valueToDate",
    docblock: "Convert the value which can be either string or object to a date object.\n\n@param {string | object} value - The string or object representation of the date\n@returns {object} - date object",
    modifiers: [],
    params: [{
      name: "value",
      description: "The string or object representation of the date",
      type: {
        name: "union",
        elements: [{
          name: "string"
        }, {
          name: "object"
        }]
      },
      optional: false
    }],
    returns: {
      description: "date object",
      type: {
        name: "object"
      }
    },
    description: "Convert the value which can be either string or object to a date object."
  }, {
    name: "formatValue",
    docblock: "Convert the date object returned by the datepicker to\nthe expected format based on dataType and showTime props.\n\n@param {object} [value] - date object\n@returns {object} - string or date object, always a UTC date",
    modifiers: [],
    params: [{
      name: "value"
    }],
    returns: null,
    description: "Convert the date object returned by the datepicker to\nthe expected format based on dataType and showTime props."
  }, {
    name: "adjustDate",
    docblock: null,
    modifiers: [],
    params: [{
      name: "date",
      optional: undefined,
      type: null
    }, {
      name: "minDate",
      optional: undefined,
      type: null
    }, {
      name: "maxDate",
      optional: undefined,
      type: null
    }, {
      name: "showTime",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "getTimeLimits",
    docblock: null,
    modifiers: [],
    params: [{
      name: "selectedDate",
      optional: undefined,
      type: null
    }, {
      name: "minDate",
      optional: undefined,
      type: null
    }, {
      name: "maxDate",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "handleChange",
    docblock: "Handles user triggered date changes\n\n@param {object} [date] the date that the value was changed to",
    modifiers: [],
    params: [{
      name: "date"
    }],
    returns: null,
    description: "Handles user triggered date changes"
  }, {
    name: "handleChangeRaw",
    docblock: "Called with a value when the input is edited manually\n\n@param {object} [evt] - the event the triggers the change",
    modifiers: [],
    params: [{
      name: "evt"
    }],
    returns: null,
    description: "Called with a value when the input is edited manually"
  }, {
    name: "isInRange",
    docblock: "Check if date is in range\n\n@param {object} [date] - the date to process\n@returns {boolean} `true` if date is in the specified range",
    modifiers: [],
    params: [{
      name: "date"
    }],
    returns: null,
    description: "Check if date is in range"
  }, {
    name: "getValidationMessages",
    docblock: "Get the validation messages to display.\nIf field is 'required' and no validation message is provided, it is added\n\n@returns {Array<any>} validation messages",
    modifiers: [],
    params: [],
    returns: {
      description: "validation messages",
      type: {
        name: "Array",
        elements: [{
          name: "any"
        }]
      }
    },
    description: "Get the validation messages to display.\nIf field is 'required' and no validation message is provided, it is added"
  }, {
    name: "getPlaceholderOrDefault",
    docblock: "Get the placeholder to display.\nIf no placeholder is provided it returns date format for locale.\n\n@param {intlMessageShape} [placeholder] placeholder provided by props\n@param {object} [translator] instance of translator for placeholder provided by props\n@returns {string} placeholder",
    modifiers: [],
    params: [{
      name: "placeholder"
    }, {
      name: "translator"
    }],
    returns: null,
    description: "Get the placeholder to display.\nIf no placeholder is provided it returns date format for locale."
  }, {
    name: "renderPopperContainer",
    docblock: "Renders wrapped calendar element\n\n@param {string} [labelId] - id of the label element assigned to this field\n@returns {Function} container component for calendar popper",
    modifiers: [],
    params: [{
      name: "labelId"
    }],
    returns: null,
    description: "Renders wrapped calendar element"
  }, {
    name: "renderCustomHeader",
    docblock: "Renders custom header.\n\n@param {object} [props] - props for custom header\n\n@returns {React.ReactElement} - the resulting rendered header",
    modifiers: [],
    params: [{
      name: "props"
    }],
    returns: null,
    description: "Renders custom header."
  }, {
    name: "handleOnBlur",
    docblock: "Sets focusPristine to false when input loses focus through clicking on datepicker\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: null,
    description: "Sets focusPristine to false when input loses focus through clicking on datepicker"
  }, {
    name: "setFocus",
    docblock: "set focus on select to prevent mishandling of focus state when clicking on elements in the custom header",
    modifiers: [],
    params: [],
    returns: null,
    description: "set focus on select to prevent mishandling of focus state when clicking on elements in the custom header"
  }, {
    name: "renderControlReadOnly",
    docblock: "Render readonly control for this component.\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render readonly control for this component."
  }, {
    name: "renderTodayButton",
    docblock: null,
    modifiers: [],
    params: [{
      name: "todayButtonText",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "handleKeyDown",
    docblock: null,
    modifiers: [],
    params: [{
      name: "e",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "renderControl",
    docblock: "Render the control\n\n@param {object} breakpointProps - breakpoint-specific props\n@param {object} [options] - some options?\n@returns {React.ReactElement} - The resulting rendered DateField",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "options"
    }],
    returns: null,
    description: "Render the control"
  }],
  actualName: "DateFieldInternalDEPRECATED",
  metadataType: "field"
};