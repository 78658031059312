import _defineProperty from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import { SET_FILTER_VALUE, SET_PAGE, SET_PAGE_SIZE, SET_SORTED, SET_COLUMNS, SET_EDITED_ROW } from './types';
export var reducer = function reducer(state, action) {
  var type = action.type;
  switch (type) {
    case SET_FILTER_VALUE:
      {
        var newState = _objectSpread({}, state, {
          filterValue: action.filterValue
        });
        if (action.resetPage) {
          newState.page = 0;
        }
        return newState;
      }
    case SET_PAGE:
      {
        return _objectSpread({}, state, {
          page: action.page
        });
      }
    case SET_PAGE_SIZE:
      {
        var _newState = _objectSpread({}, state, {
          pageSize: action.pageSize
        });
        if (action.resetPage) {
          _newState.page = 0;
        }
        return _newState;
      }
    case SET_SORTED:
      {
        var sorted = action.sorted;
        var _newState2 = _objectSpread({}, state, {
          sorted: sorted === null || sorted === void 0 ? void 0 : _mapInstanceProperty(sorted).call(sorted, function (method) {
            return _objectSpread({}, method);
          })
        });
        if (action.resetPage) {
          _newState2.page = 0;
        }
        return _newState2;
      }
    case SET_COLUMNS:
      {
        return _objectSpread({}, state, {
          columns: action.columns
        });
      }
    case SET_EDITED_ROW:
      {
        return _objectSpread({}, state, {
          editedRow: action.rowId
        });
      }
    default:
      return state;
  }
};