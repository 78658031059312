import _defineProperty from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import React from 'react';
import PropTypes from 'prop-types';
import { FieldValue } from '@jutro/components';
import { simpleDatatypeComponentMap } from '@jutro/uiconfig';
import get from "lodash/get";
import { TableColumn } from '../table/TableColumn';
import { FieldColumn } from './FieldColumn';
import styles from "../DataTable.module.css";
import { defaultColumnFilter } from '../helper';
export var DisplayColumn = function DisplayColumn() {
  if (false) {
    throw new Error('Component <DisplayColumn /> should never render');
  }
  return React.createElement(React.Fragment, null);
};
DisplayColumn.propTypes = _objectSpread({}, TableColumn.propTypes, {
  fieldDatatype: PropTypes.oneOf(_Object$keys(simpleDatatypeComponentMap)),
  onAccessor: PropTypes.func
});
DisplayColumn.defaultCell = function (row, rowId, _ref) {
  var fieldDatatype = _ref.fieldDatatype,
    path = _ref.path,
    visible = _ref.visible,
    id = _ref.id;
  if (visible === false) {
    return null;
  }
  return React.createElement(FieldValue, {
    id: "".concat(id, "_").concat(rowId),
    datatype: fieldDatatype,
    value: get(row, path)
  });
};
DisplayColumn.editCell = function (props) {
  return FieldColumn.editCell(props);
};
DisplayColumn.editCellClass = styles.editedCell;
DisplayColumn.displayName = 'DisplayColumn';
DisplayColumn.defaultProps = {
  renderCell: DisplayColumn.defaultCell,
  renderEditCell: DisplayColumn.editCell,
  editCellClass: DisplayColumn.editCellClass,
  onFilter: defaultColumnFilter,
  columnProportion: 1,
  fieldDatatype: 'string'
};
DisplayColumn.__docgenInfo = {
  componentName: "DisplayColumn",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "DisplayColumn",
  methods: [],
  actualName: "DisplayColumn",
  metadataType: "container",
  props: {
    fieldDatatype: {
      type: {
        name: "enum",
        computed: true,
        value: "Object.keys(simpleDatatypeComponentMap)"
      },
      required: false,
      description: "",
      defaultValue: {
        value: "'string'",
        computed: false
      }
    },
    onAccessor: {
      type: {
        name: "func"
      },
      required: false,
      description: "Returns a value that will be used by the column to access the correct data of each row"
    },
    renderCell: {
      defaultValue: {
        value: "DisplayColumn.defaultCell",
        computed: true
      },
      required: false
    },
    renderEditCell: {
      defaultValue: {
        value: "DisplayColumn.editCell",
        computed: true
      },
      required: false
    },
    editCellClass: {
      defaultValue: {
        value: "DisplayColumn.editCellClass",
        computed: true
      },
      required: false
    },
    onFilter: {
      defaultValue: {
        value: "defaultColumnFilter",
        computed: true
      },
      required: false
    },
    columnProportion: {
      defaultValue: {
        value: "1",
        computed: false
      },
      required: false
    }
  },
  composes: ["../table/TableColumn"]
};