import _toConsumableArray from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import range from "lodash/range";
export function getVisiblePages(page, totalPages) {
  var numberOfSequentialPages = 5;
  var currentPage = page + 1;
  if (totalPages <= numberOfSequentialPages + 2) {
    return range(1, totalPages + 1);
  }
  if (currentPage < numberOfSequentialPages) {
    return [].concat(_toConsumableArray(range(1, numberOfSequentialPages + 1)), [totalPages]);
  }
  if (currentPage + numberOfSequentialPages - 1 > totalPages) {
    return [1].concat(_toConsumableArray(range(totalPages - numberOfSequentialPages + 2, totalPages + 1)));
  }
  return [1].concat(_toConsumableArray(range(currentPage - 2, currentPage + numberOfSequentialPages - 2)), [totalPages]);
}