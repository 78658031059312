import _objectWithoutProperties from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import _defineProperty2 from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray2 from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty2(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Array$isArray from "@babel/runtime-corejs3/core-js-stable/array/is-array";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _Number$isFinite from "@babel/runtime-corejs3/core-js-stable/number/is-finite";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React from 'react';
import PropTypes from 'prop-types';
import RCSlider, { Range as RCRange } from 'rc-slider';
import { FormattedNumber } from 'react-intl';
import cx from 'classnames';
import capitalize from "lodash/capitalize";
import { error, warning } from '@jutro/logger';
import { DATA_TYPE_NUMBER } from '@jutro/prop-types';
import styles from "./Slider.module.css";
import { FieldComponent } from '../FieldComponent/FieldComponent';
import { sliderValuePropType } from './sliderUtils';
import { SliderIndicatorsContainer } from './SliderIndicatorsContainer';
import { SliderHandle } from './SliderHandle';
var HANDLE_TOOLTIP_VISIBILITY = ['always', 'ondrag', 'onfocus', 'onblur'];
export var Slider = function (_FieldComponent) {
  _inherits(Slider, _FieldComponent);
  var _super = _createSuper(Slider);
  function Slider() {
    var _this;
    _classCallCheck(this, Slider);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    _this = _super.call.apply(_super, [this].concat(args));
    _defineProperty(_assertThisInitialized(_this), "renderValue", function (value) {
      return React.createElement(FormattedNumber, {
        value: value
      });
    });
    _defineProperty(_assertThisInitialized(_this), "renderValueRange", function (_ref3) {
      var _ref4 = _slicedToArray2(_ref3, 2),
        start = _ref4[0],
        end = _ref4[1];
      return React.createElement(React.Fragment, null, _this.renderValue(start), "-", _this.renderValue(end));
    });
    _defineProperty(_assertThisInitialized(_this), "onValueChanged", function (value) {
      _this.notifyChange(value);
    });
    return _this;
  }
  _createClass(Slider, [{
    key: "renderControlReadOnly",
    value: function renderControlReadOnly(breakpointProps) {
      var id = breakpointProps.id,
        range = breakpointProps.range,
        className = breakpointProps.className;
      var value = this.value;
      return React.createElement("div", {
        id: id,
        className: className
      }, range ? this.renderValueRange(value) : this.renderValue(value));
    }
  }, {
    key: "validateProps",
    value: function validateProps() {
      var _this$props = this.props,
        min = _this$props.min,
        step = _this$props.step,
        minimumRange = _this$props.minimumRange,
        max = _this$props.max,
        range = _this$props.range;
      var value = this.value;
      if (range) {
        if (value[0] < min || value[0] > max) {
          warning("Value prop: First value is not between min: ".concat(min, " and max: ").concat(max));
        }
        if (value[1] < min || value[1] > max) {
          warning("Value prop: Second value is not between min: ".concat(min, " and max: ").concat(max));
        }
        if (value[0] > value[1]) {
          error("Value prop: First value is greater than second value");
        }
      } else if (value < min || value > max) {
        warning("Value prop: Value is not between min: ".concat(min, " and max: ").concat(max));
      }
      if (!_Number$isFinite(max) || !_Number$isFinite(min)) {
        error("Min and max must be finite numbers.");
      }
      if (min > max) {
        error("Min prop: Value ".concat(min, " is greater than max prop value: ").concat(max, "."));
      }
      if (step < 0) {
        error("Step prop: ".concat(step, " is not valid."));
      }
      if (step > max - min) {
        warning("Step prop: ".concat(step, " is not valid."));
      }
      if (minimumRange < 0 || minimumRange > max - min) {
        error("MinimumRange prop: ".concat(minimumRange, " is not valid."));
      }
    }
  }, {
    key: "renderControl",
    value: function renderControl(breakpointProps, _ref5) {
      var _this2 = this;
      var labelId = _ref5.labelId;
      this.validateProps();
      var min = breakpointProps.min,
        max = breakpointProps.max,
        showIndicators = breakpointProps.showIndicators,
        step = breakpointProps.step,
        showNotches = breakpointProps.showNotches,
        range = breakpointProps.range,
        minimumRange = breakpointProps.minimumRange,
        handleTooltip = breakpointProps.handleTooltip,
        onUpdate = breakpointProps.onUpdate,
        disabled = breakpointProps.disabled,
        className = breakpointProps.className;
      var value = this.value;
      this.styles = styles;
      var handleTooltipClassName = "tooltip".concat(capitalize(handleTooltip));
      var classes = cx(this.styles.slider, _defineProperty2({}, this.styles.disabled, disabled), this.styles[handleTooltipClassName], className);
      var renderHandle = function renderHandle(_ref6) {
        var index = _ref6.index,
          dragging = _ref6.dragging,
          handleProps = _objectWithoutProperties(_ref6, ["index", "dragging"]);
        var props = _this2.props;
        var id = props.id;
        var handleId = index === 0 ? id : "".concat(id, "-").concat(index);
        return React.createElement(SliderHandle, _extends({
          id: handleId,
          key: index
        }, handleProps, {
          className: styles.handle,
          tooltipClassName: styles.tooltip,
          labelId: labelId
        }));
      };
      var componentProps = {
        min: min,
        max: max,
        step: step,
        value: value,
        pushable: minimumRange,
        disabled: disabled,
        dots: showNotches,
        onChange: this.onValueChanged,
        onUpdate: onUpdate,
        handle: renderHandle,
        allowCross: false,
        labelId: labelId
      };
      var Component = range ? RCRange : RCSlider;
      var indicatorsProps = {
        min: min,
        max: max,
        value: value,
        range: range,
        containerClassName: this.styles.indicators,
        indicatorClassName: this.styles.indicator
      };
      return React.createElement("div", {
        className: classes,
        "data-testid": "slider-container"
      }, React.createElement(Component, componentProps), showIndicators && React.createElement(SliderIndicatorsContainer, indicatorsProps));
    }
  }, {
    key: "render",
    value: function render() {
      return _get(_getPrototypeOf(Slider.prototype), "render", this).call(this);
    }
  }, {
    key: "value",
    get: function get() {
      var _this$props2 = this.props,
        min = _this$props2.min,
        max = _this$props2.max,
        range = _this$props2.range,
        value = _this$props2.value;
      if (!range) {
        return _Number$isFinite(value) ? value : min;
      }
      var _ref = _Array$isArray(value) ? value : [],
        _ref2 = _slicedToArray(_ref, 2),
        _ref2$ = _ref2[0],
        start = _ref2$ === void 0 ? min : _ref2$,
        _ref2$2 = _ref2[1],
        end = _ref2$2 === void 0 ? max : _ref2$2;
      return [start, end];
    }
  }]);
  return Slider;
}(FieldComponent);
_defineProperty(Slider, "propTypes", _objectSpread({}, FieldComponent.propTypes, {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: sliderValuePropType.isRequired,
  defaultValue: sliderValuePropType,
  showIndicators: PropTypes.bool,
  step: PropTypes.number,
  showNotches: PropTypes.bool,
  range: PropTypes.bool,
  minimumRange: PropTypes.number,
  handleTooltip: PropTypes.oneOf(HANDLE_TOOLTIP_VISIBILITY),
  onUpdate: PropTypes.func
}));
_defineProperty(Slider, "defaultProps", _objectSpread({}, FieldComponent.defaultProps, {
  showIndicators: true,
  range: false,
  handleTooltip: HANDLE_TOOLTIP_VISIBILITY[0],
  dataType: DATA_TYPE_NUMBER
}));
Slider.__docgenInfo = {
  componentName: "Slider",
  packageName: "@jutro/components",
  description: "",
  displayName: "Slider",
  methods: [{
    name: "value",
    docblock: "Returns value\n@returns {number | number[]}",
    modifiers: ["get"],
    params: [],
    returns: {
      description: null,
      type: {
        name: "union",
        elements: [{
          name: "number"
        }, {
          name: "Array",
          elements: [{
            name: "number"
          }]
        }]
      }
    },
    description: "Returns value"
  }, {
    name: "renderValue",
    docblock: "Renders formatted number value\n@param {number} value\n@returns {React.ReactElement}",
    modifiers: [],
    params: [{
      name: "value",
      description: null,
      type: {
        name: "number"
      },
      optional: false
    }],
    returns: {
      description: null,
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Renders formatted number value"
  }, {
    name: "renderValueRange",
    docblock: "@param {[ number, number ]} range\n@returns {React.ReactElement}",
    modifiers: [],
    params: [{
      name: "[start, end]"
    }],
    returns: {
      description: null,
      type: {
        name: "React.ReactElement"
      }
    },
    description: null
  }, {
    name: "renderControlReadOnly",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpointProps",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "onValueChanged",
    docblock: "Handles value change\n@param {number} value",
    modifiers: [],
    params: [{
      name: "value",
      description: null,
      type: {
        name: "number"
      },
      optional: false
    }],
    returns: null,
    description: "Handles value change"
  }, {
    name: "validateProps",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "renderControl",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpointProps",
      optional: undefined,
      type: null
    }, {
      name: "{ labelId }",
      optional: undefined,
      type: null
    }],
    returns: null
  }],
  actualName: "Slider",
  metadataType: "field",
  props: {
    min: {
      type: {
        name: "number"
      },
      required: true,
      description: "Minimum available slider value"
    },
    max: {
      type: {
        name: "number"
      },
      required: true,
      description: "Maximum available slider value"
    },
    value: {
      type: {
        name: "custom",
        raw: "sliderValuePropType"
      },
      required: true,
      description: "Current slider value (to create fully controlled component)"
    },
    defaultValue: {
      type: {
        name: "custom",
        raw: "sliderValuePropType"
      },
      required: false,
      description: "Initial slider value (to create uncontrolled component)"
    },
    showIndicators: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Prop to specify whether indicators should be shown or not",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    step: {
      type: {
        name: "number"
      },
      required: false,
      description: "Step between consecutive values"
    },
    showNotches: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Prop to specify whether notches should be shown or not"
    },
    range: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If set - range selector will be shown",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    minimumRange: {
      type: {
        name: "number"
      },
      required: false,
      description: "Prop to specify minimum range size in case of range selection"
    },
    handleTooltip: {
      type: {
        name: "enum",
        value: [{
          value: "'always'",
          computed: false
        }, {
          value: "'ondrag'",
          computed: false
        }, {
          value: "'onfocus'",
          computed: false
        }, {
          value: "'onblur'",
          computed: false
        }]
      },
      required: false,
      description: "Prop to specify when handle tooltip should be visible\n- ondrag - tooltip is visible only when user drags handle\n- onfocus - tooltip is visible only when handle is focused\n- onblur - tooltip is visible only when handle is blured\n- always - tooltip is always visible",
      defaultValue: {
        value: "HANDLE_TOOLTIP_VISIBILITY[0]",
        computed: true
      }
    },
    onUpdate: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback when value changing is completed (to create uncontrolled component)"
    },
    dataType: {
      defaultValue: {
        value: "DATA_TYPE_NUMBER",
        computed: true
      },
      required: false
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};