import _defineProperty from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _Promise from "@babel/runtime-corejs3/core-js-stable/promise";
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isArray from "lodash/isArray";
import cx from 'classnames';
import { isPromise, uniqueInnerId } from '@jutro/platform';
import { TranslatorContext } from '@jutro/locale';
import { intlMessageShape } from '@jutro/prop-types';
import { LoadingIcon } from '../loadingIcon/LoadingIcon';
import { Icon } from '../../Icon/Icon';
import styles from "./InlineLoader.module.css";
import { messages } from './InlineLoader.messages';
var inlineLoaderDefaultProps = {
  successIcon: 'check'
};
export var inlineLoaderPropTypes = {
  className: PropTypes.string,
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.instanceOf(_Promise)), PropTypes.instanceOf(_Promise)]),
  loadingIcon: PropTypes.string,
  loadingMessage: intlMessageShape,
  successIcon: PropTypes.string,
  successMessage: intlMessageShape
};
export var InlineLoader = function InlineLoader(_ref) {
  var className = _ref.className,
    loading = _ref.loading,
    loadingIcon = _ref.loadingIcon,
    loadingMessage = _ref.loadingMessage,
    successIcon = _ref.successIcon,
    successMessage = _ref.successMessage;
  var translator = useContext(TranslatorContext);
  var _useState = useState(!!loading),
    _useState2 = _slicedToArray(_useState, 2),
    isLoading = _useState2[0],
    setIsLoading = _useState2[1];
  useEffect(function () {
    if (isPromise(loading) || isArray(loading)) {
      var loaderPromise = isArray(loading) ? _Promise.all(loading) : loading;
      setIsLoading(true);
      loaderPromise.then(function () {
        return setIsLoading(false);
      });
      return;
    }
    setIsLoading(loading);
  }, [loading]);
  var loaderStyle = cx(className, styles.inlineLoader);
  var _uniqueInnerId = uniqueInnerId('InlineLoader', 'iconLabelId'),
    iconLabelId = _uniqueInnerId.iconLabelId;
  var renderLoading = function renderLoading() {
    var loaderMessageStyle = cx(_defineProperty({}, styles.withLoadingMessage, loadingMessage));
    return React.createElement(React.Fragment, null, React.createElement(LoadingIcon, {
      className: loaderMessageStyle,
      icon: loadingIcon,
      "aria-labelledby": iconLabelId
    }), React.createElement("div", {
      id: iconLabelId,
      className: styles.screenReaderOnly
    }, translator(messages.loading)), loadingMessage && React.createElement("div", {
      className: styles.withMessage
    }, translator(loadingMessage)));
  };
  var renderLoadingSuccess = function renderLoadingSuccess() {
    return successMessage && React.createElement(React.Fragment, null, React.createElement(Icon, {
      className: styles.withSuccessMessage,
      icon: successIcon
    }), React.createElement("div", {
      className: styles.withMessage
    }, translator(successMessage)));
  };
  return React.createElement("div", {
    className: loaderStyle,
    role: "alert"
  }, isLoading ? renderLoading() : renderLoadingSuccess());
};
InlineLoader.propTypes = inlineLoaderPropTypes;
InlineLoader.defaultProps = inlineLoaderDefaultProps;
InlineLoader.__docgenInfo = {
  componentName: "InlineLoader",
  packageName: "@jutro/components",
  description: "InlineLoader",
  displayName: "InlineLoader",
  methods: [],
  actualName: "InlineLoader",
  metadataType: "element",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    },
    loading: {
      type: {
        name: "union",
        value: [{
          name: "bool"
        }, {
          name: "arrayOf",
          value: {
            name: "instanceOf",
            value: "Promise"
          }
        }, {
          name: "instanceOf",
          value: "Promise"
        }]
      },
      required: false,
      description: "Controls whether the Inline Loader is shown;\nFor boolean values, if `true` is passed then the Inline Loader is shown;\nFor Promise value(s), the Inline Loader will be shown until the promise has completed."
    },
    loadingIcon: {
      type: {
        name: "string"
      },
      required: false,
      description: "Material Icon name (mi-*) (or deprecated FontAwesome icon short name when used with FA animation effect) to be used when executing trigger/promise"
    },
    loadingMessage: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "The message shown when executing trigger/promise"
    },
    successIcon: {
      type: {
        name: "string"
      },
      required: false,
      description: "Material Icon name (mi-*) (or deprecated FontAwesome icon short name when used with FA animation effect) to be shown when execution trigger/promise is successfully resolved",
      defaultValue: {
        value: "'check'",
        computed: false
      }
    },
    successMessage: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "The message shown when execution trigger/promise is succesfully done"
    }
  }
};