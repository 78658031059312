import _defineProperty from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _everyInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/every";
import { SELECT, TOGGLE, SET_ALL, CLEAR_ALL } from './types';
import { toggleValue } from './helper';
export function reducer(state, _ref) {
  var type = _ref.type,
    payload = _ref.payload;
  switch (type) {
    case SELECT:
      if (areEqual([payload], state.rows)) {
        return _objectSpread({}, state, {
          all: false
        });
      }
      return _objectSpread({}, state, {
        all: false,
        rows: [payload]
      });
    case TOGGLE:
      {
        var rows = state.rows;
        var newRows = toggleValue(rows, payload);
        return _objectSpread({}, state, {
          all: false,
          rows: newRows
        });
      }
    case SET_ALL:
      if (areEqual(payload, state.rows)) {
        return _objectSpread({}, state, {
          all: true
        });
      }
      return _objectSpread({}, state, {
        all: true,
        rows: payload
      });
    case CLEAR_ALL:
      if (!state.rows.length) {
        return _objectSpread({}, state, {
          all: false
        });
      }
      return _objectSpread({}, state, {
        all: false,
        rows: []
      });
    default:
      return state;
  }
}
function areEqual(array1, array2) {
  if (array1.length !== array2.length) {
    return false;
  }
  return _everyInstanceProperty(array1).call(array1, function (item) {
    return _includesInstanceProperty(array2).call(array2, item);
  });
}