import _defineProperty from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _forEachInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/for-each";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useContext, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { intlMessageShape, appSwitcherRoutePropTypes, appSwitcherRouteGroupPropTypes, separatorPropType } from '@jutro/prop-types';
import { TranslatorContext } from '@jutro/locale';
import cx from 'classnames';
import { uniqueInnerId } from '@jutro/platform';
import { Popover, PopoverContainer, Link, InputField } from '@jutro/components';
import { AppSwitcherButton, availableIconColors } from './AppSwitcherButton';
import { AppSwitcherContent } from './AppSwitcherContent';
import { isGroup, isApp } from './helpers';
import styles from "./AppSwitcher.module.css";
import { messages } from './AppSwitcher.messages';
var appSwitcherPropTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.oneOfType([appSwitcherRoutePropTypes, appSwitcherRouteGroupPropTypes, separatorPropType]).isRequired).isRequired,
  className: PropTypes.string,
  onRouteClick: PropTypes.func,
  iconColor: PropTypes.oneOf(availableIconColors),
  iconClassName: PropTypes.string,
  footerText: intlMessageShape,
  footerUrl: intlMessageShape,
  onFooterClick: PropTypes.func,
  searchEnabled: PropTypes.bool,
  collapsibleGroupsThreshold: PropTypes.number,
  hideFooter: PropTypes.bool
};
function renderLink(_ref) {
  var label = _ref.label,
    onClick = _ref.onClick,
    href = _ref.href;
  if (!label || !(onClick || href)) {
    return null;
  }
  var props = {
    children: label,
    href: href,
    onClick: onClick
  };
  return React.createElement(Link, props);
}
export var AppSwitcher = function AppSwitcher(_ref2) {
  var id = _ref2.id,
    className = _ref2.className,
    items = _ref2.items,
    onRouteClick = _ref2.onRouteClick,
    iconColor = _ref2.iconColor,
    iconClassName = _ref2.iconClassName,
    footerText = _ref2.footerText,
    footerUrl = _ref2.footerUrl,
    onFooterClick = _ref2.onFooterClick,
    isDropdown = _ref2.isDropdown,
    searchEnabled = _ref2.searchEnabled,
    collapsibleGroupsThreshold = _ref2.collapsibleGroupsThreshold,
    hideFooter = _ref2.hideFooter;
  var translator = useContext(TranslatorContext);
  var _useState = useState(''),
    _useState2 = _slicedToArray(_useState, 2),
    searchPhrase = _useState2[0],
    setSearchPhrase = _useState2[1];
  var containerClasses = cx(styles.appSwitcher, className);
  var _uniqueInnerId = uniqueInnerId(id, ['button']),
    AppSwitcherButtonId = _uniqueInnerId.button;
  var itemsCount = useMemo(function () {
    return _reduceInstanceProperty(items).call(items, function (numberOfItems, item) {
      return numberOfItems + (isGroup(item) ? item.items.length + 1 : 0) + (isApp(item) ? 1 : 0);
    }, 0);
  }, [items]);
  var itemsWithId = useMemo(function () {
    return _mapInstanceProperty(items).call(items, function (item, index) {
      var itemId = "".concat(index);
      if (isGroup(item)) {
        var _context;
        return _objectSpread({}, item, {
          items: _mapInstanceProperty(_context = item.items).call(_context, function (itemInner, indexInner) {
            return _objectSpread({}, itemInner, {
              id: "".concat(itemId, "_").concat(indexInner)
            });
          }),
          id: itemId
        });
      }
      return _objectSpread({}, item, {
        id: itemId
      });
    });
  }, [items]);
  var matchesSearchPhrase = function matchesSearchPhrase(app) {
    var _context2;
    var translatedTitle = translator(app.title);
    return typeof translatedTitle === 'string' ? _includesInstanceProperty(_context2 = translatedTitle.toLowerCase()).call(_context2, searchPhrase.toLowerCase()) : false;
  };
  var filteredItems = useMemo(function () {
    return !searchPhrase ? itemsWithId : _reduceInstanceProperty(itemsWithId).call(itemsWithId, function (apps, item) {
      if (isGroup(item)) {
        var _context3;
        _forEachInstanceProperty(_context3 = item.items).call(_context3, function (app) {
          if (matchesSearchPhrase(app)) apps.push(app);
        });
      }
      if (isApp(item) && matchesSearchPhrase(item)) {
        apps.push(item);
      }
      return apps;
    }, []);
  }, [searchPhrase, itemsWithId]);
  var onFooterClickCallback = function onFooterClickCallback(event) {
    if (onFooterClick) {
      onFooterClick(event);
    }
    if (onRouteClick) {
      onRouteClick();
    }
  };
  var footerLinkProps = {
    label: footerText || '',
    href: footerUrl,
    onClick: onFooterClickCallback
  };
  var handleRenderTrigger = useCallback(function (toggle, ref, hasShownPopover) {
    return React.createElement(AppSwitcherButton, {
      id: AppSwitcherButtonId,
      togglePopover: toggle,
      ref: ref,
      hasShownPopover: hasShownPopover,
      iconColor: iconColor,
      iconClassName: iconClassName
    });
  }, []);
  var displaySearch = searchEnabled && itemsCount >= collapsibleGroupsThreshold;
  var onSearchValueChange = function onSearchValueChange(newValue) {
    setSearchPhrase(newValue);
  };
  var resetSearch = function resetSearch() {
    setSearchPhrase('');
  };
  var renderSearch = function renderSearch() {
    return React.createElement(InputField, {
      id: "app-switcher-search",
      placeholder: translator(messages.searchPlaceholder),
      icon: "mi-search",
      iconPosition: "right",
      value: searchPhrase,
      onValueChange: onSearchValueChange,
      className: styles.appSwitcherSearch
    });
  };
  var lessThanCollapsibleGroupsThreshold = itemsCount < collapsibleGroupsThreshold;
  var renderContent = function renderContent(closePopover) {
    return React.createElement(AppSwitcherContent, {
      items: filteredItems,
      displaySearch: displaySearch,
      renderSearch: renderSearch,
      resetSearch: resetSearch,
      onRouteClick: onRouteClick,
      closePopover: closePopover,
      isCollapsible: !lessThanCollapsibleGroupsThreshold
    });
  };
  return isDropdown ? React.createElement(Popover, {
    id: id,
    renderTrigger: handleRenderTrigger,
    isFlipEnabled: false,
    className: containerClasses
  }, function (closePopover) {
    return React.createElement(PopoverContainer, {
      className: styles.appSwitcherPopoverContainer,
      hideFooter: hideFooter,
      hideHeader: true,
      internalClassNames: {
        body: styles.appSwitcherPopoverBody,
        footer: styles.appSwitcherPopoverFooter
      },
      footerLink: footerLinkProps
    }, renderContent(closePopover));
  }) : React.createElement(React.Fragment, null, React.createElement("div", {
    className: className
  }, renderContent()), !hideFooter && React.createElement("div", {
    className: styles.footerModal
  }, renderLink(footerLinkProps)));
};
AppSwitcher.displayName = 'AppSwitcher';
AppSwitcher.propTypes = appSwitcherPropTypes;
AppSwitcher.defaultProps = {
  isDropdown: true,
  searchEnabled: true,
  items: [],
  collapsibleGroupsThreshold: 15,
  hideFooter: false
};
renderLink.__docgenInfo = {
  componentName: "renderLink",
  packageName: "@jutro/router",
  description: "",
  displayName: "renderLink",
  methods: [],
  actualName: "renderLink"
};
AppSwitcher.__docgenInfo = {
  componentName: "AppSwitcher",
  packageName: "@jutro/router",
  description: "",
  displayName: "AppSwitcher",
  methods: [],
  actualName: "AppSwitcher",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "Used to identify this component."
    },
    items: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "shape",
            value: {
              title: {
                name: "custom",
                raw: "intlMessageShape.isRequired",
                description: "Title that will be displayed below the icon",
                required: true
              },
              to: {
                name: "custom",
                raw: "intlToShape",
                description: "Internal path to the application",
                required: false
              },
              href: {
                name: "custom",
                raw: "intlMessageShape",
                description: "External path",
                required: false
              },
              imageUrl: {
                name: "string",
                description: "Source of the image",
                required: false
              },
              imageAlt: {
                name: "string",
                description: "Alternative text of the image",
                required: false
              },
              exact: {
                name: "bool",
                description: "Flag for the exact path matching in react-router",
                required: false
              },
              onClick: {
                name: "func",
                description: "Callback on item click event handler",
                required: false
              },
              isFocused: {
                name: "bool",
                description: "Flag to indicate if the current item is focused",
                required: false
              }
            }
          }, {
            name: "shape",
            value: {
              title: {
                name: "custom",
                raw: "intlMessageShape.isRequired",
                description: "Title of the application group",
                required: true
              },
              items: {
                name: "arrayOf",
                value: {
                  name: "custom",
                  raw: "appSwitcherRoutePropTypes.isRequired"
                },
                description: "Array of the route items",
                required: true
              },
              isInitiallyCollapsed: {
                name: "bool",
                description: "Default state for the expandable application group",
                required: false
              }
            }
          }, {
            name: "shape",
            value: {
              separator: {
                name: "bool",
                required: false
              }
            }
          }]
        }
      },
      required: false,
      description: "Array of Items to be displayed in the menu",
      defaultValue: {
        value: "[]",
        computed: false
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for the wrapper div"
    },
    onRouteClick: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback invoked when any route is clicked"
    },
    iconColor: {
      type: {
        name: "enum",
        computed: true,
        value: "availableIconColors"
      },
      required: false,
      description: "The color of trigger icon"
    },
    iconClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for trigger icon"
    },
    footerText: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Text displayed on footer link"
    },
    footerUrl: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "URL to which footer link leads"
    },
    onFooterClick: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback which will be triggered when footer link is clicked"
    },
    searchEnabled: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Enables apps search field if the number of items equals or is above `collapsibleGroupsThreshold` value",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    collapsibleGroupsThreshold: {
      type: {
        name: "number"
      },
      required: false,
      description: "Number of items from which the groups are collapsible and the search is displayed",
      defaultValue: {
        value: "15",
        computed: false
      }
    },
    hideFooter: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Prop to specify if the footer should be displayed or not",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    isDropdown: {
      defaultValue: {
        value: "true",
        computed: false
      },
      required: false
    }
  }
};