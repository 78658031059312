import _objectWithoutProperties2 from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _Array$isArray from "@babel/runtime-corejs3/core-js-stable/array/is-array";
import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { warning } from '@jutro/logger';
import { TranslatorContext } from '@jutro/locale';
import { sanitizeUrl } from '@jutro/platform';
import { PageTitle } from '../pageHead/PageTitle';
import { resolveComponentFromMap } from '../resolveComponentFromMap';
export var NavRoutes = function NavRoutes(_ref) {
  var routes = _ref.routes,
    resolveComponentMap = _ref.resolveComponentMap,
    resolveComponentCallback = _ref.resolveComponent;
  var translator = useContext(TranslatorContext);
  var routesArray = _Array$isArray(routes) ? routes : routes.routes;
  function renderComponentRoute(routeProps) {
    var component = routeProps.component,
      _routeProps$component = routeProps.componentProps,
      componentProps = _routeProps$component === void 0 ? {} : _routeProps$component,
      navLink = routeProps.navLink,
      title = routeProps.title,
      showOnNavBar = routeProps.showOnNavBar,
      other = _objectWithoutProperties(routeProps, ["component", "componentProps", "navLink", "title", "showOnNavBar"]);
    var ResolvedComponent = resolveComponentCallback(component, resolveComponentMap);
    return React.createElement(Route, _extends({
      key: other.path || title
    }, other), React.createElement(React.Fragment, null, React.createElement(PageTitle, {
      title: translator(title)
    }), React.createElement(ResolvedComponent, componentProps)));
  }
  function renderRedirectRoute(_ref2) {
    var redirect = _ref2.redirect,
      title = _ref2.title,
      showOnNavBar = _ref2.showOnNavBar,
      other = _objectWithoutProperties2(_ref2, ["redirect", "title", "showOnNavBar"]);
    return React.createElement(Route, _extends({
      key: redirect || title
    }, other, {
      render: function render() {
        return React.createElement(Redirect, {
          to: sanitizeUrl(redirect)
        });
      }
    }));
  }
  function renderNavRoute(routeProps) {
    if (routeProps.routes) {
      var _context;
      return _mapInstanceProperty(_context = routeProps.routes).call(_context, renderNavRoute);
    }
    if (routeProps.redirect) {
      return renderRedirectRoute(routeProps);
    }
    if (routeProps.component) {
      return renderComponentRoute(routeProps);
    }
    if (routeProps.href) {
      return undefined;
    }
    warning('Either redirect, component or href (for external links) must be provided for every route definition');
    return undefined;
  }
  return React.createElement(Switch, null, routesArray === null || routesArray === void 0 ? void 0 : _mapInstanceProperty(routesArray).call(routesArray, renderNavRoute));
};
NavRoutes.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  resolveComponentMap: PropTypes.object.isRequired,
  resolveComponent: PropTypes.func
};
NavRoutes.defaultProps = {
  resolveComponent: resolveComponentFromMap
};
NavRoutes.__docgenInfo = {
  componentName: "NavRoutes",
  packageName: "@jutro/router",
  description: "",
  displayName: "NavRoutes",
  methods: [],
  actualName: "NavRoutes",
  props: {
    routes: {
      type: {
        name: "arrayOf",
        value: {
          name: "object"
        }
      },
      required: true,
      description: "Routing metadata object"
    },
    resolveComponentMap: {
      type: {
        name: "object"
      },
      required: true,
      description: "Map of component name to component classes (required if using route metadata in json files)"
    },
    resolveComponent: {
      type: {
        name: "func"
      },
      required: false,
      description: "Optional callback function to resolve component; const Component = resolveComponent(componentName, componentMap)",
      defaultValue: {
        value: "resolveComponentFromMap",
        computed: true
      }
    }
  }
};