import _defineProperty from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from 'react';
import cx from 'classnames';
import { GenericSelectComponents as components } from '../../GenericSelectControl/GenericSelectControl';
export var LookupControl = function LookupControl(props) {
  var customStyles = props.selectProps.customStyles,
    isFocused = props.isFocused;
  return React.createElement(components.Control, _extends({}, props, {
    className: cx(_defineProperty({}, customStyles.controlFocused, customStyles.controlFocused && isFocused), customStyles.control)
  }));
};
LookupControl.__docgenInfo = {
  componentName: "LookupControl",
  packageName: "@jutro/components",
  description: "",
  displayName: "LookupControl",
  methods: [],
  actualName: "LookupControl"
};