import _defineProperty from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectWithoutProperties2 from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _Array$isArray from "@babel/runtime-corejs3/core-js-stable/array/is-array";
import _Promise from "@babel/runtime-corejs3/core-js-stable/promise";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _parseInt from "@babel/runtime-corejs3/core-js-stable/parse-int";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useContext, useEffect, useMemo, useCallback, useRef, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Creatable from 'react-select/creatable';
import AsyncCreatable from 'react-select/async-creatable';
import cx from 'classnames';
import identity from "lodash/identity";
import constant from "lodash/constant";
import { TranslatorContext } from '@jutro/locale';
import { intlMessageShape } from '@jutro/prop-types';
import { GenericSelectControlClearIndicator as ClearIndicator } from './components/GenericSelectControlClearIndicator';
import { GenericSelectControlControl as Control } from './components/GenericSelectControlControl';
import { GenericSelectControlDropdownIndicator as DropdownIndicator } from './components/GenericSelectControlDropdownIndicator';
import { GenericSelectControlInput as Input } from './components/GenericSelectControlInput';
import { GenericSelectControlLoadingIndicator as LoadingIndicator } from './components/GenericSelectControlLoadingIndicator';
import { GenericSelectControlMenu as Menu } from './components/GenericSelectControlMenu';
import { GenericSelectControlMenuList as MenuList } from './components/GenericSelectControlMenuList';
import { GenericSelectControlMultiValue as MultiValue } from './components/GenericSelectControlMultiValue';
import { GenericSelectControlOption as Option } from './components/GenericSelectControlOption';
import { GenericSelectControlPlaceholder as Placeholder } from './components/GenericSelectControlPlaceholder';
import { GenericSelectControlSingleValue as SingleValue } from './components/GenericSelectControlSingleValue';
import { GenericSelectControlValueContainer as ValueContainer } from './components/GenericSelectControlValueContainer';
import styles from "./GenericSelectControl.module.css";
import { messages } from './GenericSelectControl.messages';
var defaultComponents = {
  ClearIndicator: ClearIndicator,
  Control: Control,
  DropdownIndicator: DropdownIndicator,
  Input: Input,
  LoadingIndicator: LoadingIndicator,
  Menu: Menu,
  MenuList: MenuList,
  MultiValue: MultiValue,
  Option: Option,
  Placeholder: Placeholder,
  SingleValue: SingleValue,
  ValueContainer: ValueContainer
};
export { defaultComponents as GenericSelectComponents };
export var genericSelectInternalClassNamesShape = PropTypes.shape({
  control: PropTypes.string,
  controlFocused: PropTypes.string,
  menuList: PropTypes.string,
  option: PropTypes.string,
  selectIcon: PropTypes.string
});
var genericSelectControlPropTypes = {
  availableValues: PropTypes.arrayOf(PropTypes.any),
  className: PropTypes.string,
  components: PropTypes.shape({
    ClearIndicator: PropTypes.elementType,
    Control: PropTypes.elementType,
    DropdownIndicator: PropTypes.elementType,
    IndicatorSeparator: PropTypes.elementType,
    Input: PropTypes.elementType,
    LoadingIndicator: PropTypes.elementType,
    Menu: PropTypes.elementType,
    MenuList: PropTypes.elementType,
    Option: PropTypes.elementType,
    Placeholder: PropTypes.elementType,
    Value: PropTypes.elementType,
    ValueContainer: PropTypes.elementType
  }),
  internalClassNames: genericSelectInternalClassNamesShape,
  createNewMessage: intlMessageShape,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  filter: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  id: PropTypes.string,
  isClearable: PropTypes.bool,
  allowNewValue: PropTypes.func,
  labelId: PropTypes.string,
  loadValues: PropTypes.func,
  noOptionsMessage: intlMessageShape,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onCreateOption: PropTypes.func,
  onFocus: PropTypes.func,
  onInputChange: PropTypes.func,
  onMenuClose: PropTypes.func,
  onMenuOpen: PropTypes.func,
  onKeyDown: PropTypes.func,
  onValueChange: PropTypes.func,
  placeholder: intlMessageShape,
  readOnly: PropTypes.bool,
  readOnlySeparator: PropTypes.string,
  required: PropTypes.bool,
  searchable: PropTypes.bool,
  multiSelect: PropTypes.bool,
  stickyIndicator: PropTypes.bool,
  valid: PropTypes.bool,
  value: PropTypes.any,
  usePortal: PropTypes.bool,
  isInitiallyOpen: PropTypes.bool,
  controlShouldRenderValue: PropTypes.bool,
  backspaceRemovesValue: PropTypes.bool
};
export var GenericSelectControl = function GenericSelectControl(_ref2) {
  var availableValues = _ref2.availableValues,
    className = _ref2.className,
    components = _ref2.components,
    createNewMessage = _ref2.createNewMessage,
    defaultValue = _ref2.defaultValue,
    disabled = _ref2.disabled,
    filter = _ref2.filter,
    getOptionLabel = _ref2.getOptionLabel,
    getOptionValue = _ref2.getOptionValue,
    id = _ref2.id,
    internalClassNames = _ref2.internalClassNames,
    isClearable = _ref2.isClearable,
    allowNewValue = _ref2.allowNewValue,
    labelId = _ref2.labelId,
    loadValues = _ref2.loadValues,
    noOptionsMessage = _ref2.noOptionsMessage,
    onBlur = _ref2.onBlur,
    onClick = _ref2.onClick,
    onCreateOption = _ref2.onCreateOption,
    onFocus = _ref2.onFocus,
    onInputChange = _ref2.onInputChange,
    onMenuClose = _ref2.onMenuClose,
    onMenuOpen = _ref2.onMenuOpen,
    onKeyDown = _ref2.onKeyDown,
    onValueChange = _ref2.onValueChange,
    placeholder = _ref2.placeholder,
    readOnly = _ref2.readOnly,
    readOnlySeparator = _ref2.readOnlySeparator,
    required = _ref2.required,
    searchable = _ref2.searchable,
    multiSelect = _ref2.multiSelect,
    stickyIndicator = _ref2.stickyIndicator,
    valid = _ref2.valid,
    value = _ref2.value,
    usePortal = _ref2.usePortal,
    isInitiallyOpen = _ref2.isInitiallyOpen,
    customProps = _objectWithoutProperties2(_ref2, ["availableValues", "className", "components", "createNewMessage", "defaultValue", "disabled", "filter", "getOptionLabel", "getOptionValue", "id", "internalClassNames", "isClearable", "allowNewValue", "labelId", "loadValues", "noOptionsMessage", "onBlur", "onClick", "onCreateOption", "onFocus", "onInputChange", "onMenuClose", "onMenuOpen", "onKeyDown", "onValueChange", "placeholder", "readOnly", "readOnlySeparator", "required", "searchable", "multiSelect", "stickyIndicator", "valid", "value", "usePortal", "isInitiallyOpen"]);
  var translator = useContext(TranslatorContext);
  var _useState = useState('bottom'),
    _useState2 = _slicedToArray(_useState, 2),
    menuPlacement = _useState2[0],
    setMenuPlacement = _useState2[1];
  var selectRef = useRef(null);
  useEffect(function () {
    var _selectRef$current;
    var inputRef = (_selectRef$current = selectRef.current) === null || _selectRef$current === void 0 ? void 0 : _selectRef$current.select.select.inputRef;
    if (!searchable && inputRef) {
      if (required) {
        inputRef.setAttribute('required', 'true');
        inputRef.setAttribute('aria-required', 'true');
      } else {
        inputRef.removeAttribute('required');
        inputRef.removeAttribute('aria-required');
      }
    }
  }, [searchable, required]);
  useLayoutEffect(function () {
    var _selectRef$current2;
    var dropdownNode = (_selectRef$current2 = selectRef.current) === null || _selectRef$current2 === void 0 ? void 0 : _selectRef$current2.select.select.controlRef;
    var dropdownMenuMaxHeight = dropdownNode ? getComputedStyle(document.body).getPropertyValue('--GW-GENERIC-SELECT-MENU-MAX-HEIGHT') : '0px';
    var distanceToBottom = document.body.clientHeight - (dropdownNode === null || dropdownNode === void 0 ? void 0 : dropdownNode.getBoundingClientRect().bottom);
    var countMenuPlacement = distanceToBottom > _parseInt(dropdownMenuMaxHeight, 10) ? 'bottom' : 'top';
    setMenuPlacement(countMenuPlacement);
  }, []);
  var genericSelectStyles = useMemo(function () {
    return _objectSpread({}, styles, {
      control: cx(styles.control, internalClassNames.control),
      controlFocused: cx(styles.controlFocused, internalClassNames.controlFocused),
      menuList: cx(styles.menuList, internalClassNames.menuList),
      option: cx(styles.option, internalClassNames.option),
      selectIcon: cx(styles.selectIcon, internalClassNames.selectIcon, _defineProperty({}, styles.stickySelectIcon, stickyIndicator))
    });
  }, [stickyIndicator]);
  var selectClasses = cx(genericSelectStyles.genericSelect, {
    disabled: disabled
  }, className);
  var creatableComponents = useMemo(function () {
    var _ref = components || {},
      Value = _ref.Value,
      additionalComponents = _objectWithoutProperties(_ref, ["Value"]);
    var valueComponent = multiSelect ? {
      MultiValue: Value
    } : {
      SingleValue: Value
    };
    return _objectSpread({
      IndicatorSeparator: null
    }, defaultComponents, {}, additionalComponents, {}, Value && valueComponent);
  }, [multiSelect, components]);
  var formatCreateLabel = useCallback(function (newOptionValue) {
    return translator(createNewMessage || messages.createNewMessage, {
      message: newOptionValue
    });
  }, [translator, createNewMessage]);
  var translatedPlaceholder = useMemo(function () {
    return translator(placeholder) || null;
  }, [placeholder, translator]);
  var translatedNoOptionsMessage = useMemo(function () {
    return constant(translator(noOptionsMessage));
  }, [noOptionsMessage, translator]);
  var filterOption = useMemo(function () {
    return filter && function (_ref3, text) {
      var data = _ref3.data;
      return data.__isNew__ || filter(data, text);
    };
  }, [filter]);
  var loadOptions = useMemo(function () {
    if (!loadValues) {
      return undefined;
    }
    return function () {
      return _Promise.resolve(loadValues.apply(void 0, arguments));
    };
  }, [loadValues]);
  if (readOnly) {
    if (!value) {
      return null;
    }
    var values = _Array$isArray(value) ? value : [value];
    var stringValue = _mapInstanceProperty(values).call(values, getOptionLabel).join(readOnlySeparator) || null;
    return stringValue && React.createElement("span", {
      id: id
    }, stringValue);
  }
  var SelectComponent = loadValues ? AsyncCreatable : Creatable;
  var selectComponentId = "".concat(id, "-wrapper");
  return React.createElement(SelectComponent, _extends({
    id: selectComponentId,
    inputId: id,
    "aria-labelledby": labelId,
    className: selectClasses,
    components: creatableComponents,
    defaultValue: defaultValue,
    filterOption: filterOption,
    formatCreateLabel: formatCreateLabel,
    getOptionLabel: getOptionLabel,
    getOptionValue: getOptionValue,
    isClearable: isClearable,
    isDisabled: disabled,
    isMulti: multiSelect,
    isValidNewOption: allowNewValue,
    isSearchable: searchable,
    loadOptions: loadOptions,
    noOptionsMessage: translatedNoOptionsMessage,
    onBlur: onBlur,
    onChange: onValueChange,
    onClick: onClick,
    onCreateOption: onCreateOption,
    onFocus: onFocus,
    onInputChange: onInputChange,
    onMenuClose: onMenuClose,
    onMenuOpen: onMenuOpen,
    onKeyDown: onKeyDown,
    options: availableValues,
    placeholder: translatedPlaceholder,
    required: required,
    ref: selectRef,
    value: value,
    defaultMenuIsOpen: isInitiallyOpen,
    genericSelectStyles: genericSelectStyles,
    invalid: !valid,
    styles: usePortal && {
      menuPortal: function menuPortal(style) {
        return _objectSpread({}, style, {
          zIndex: 'var(--GW-Z-INDEX-4)'
        });
      }
    },
    menuPortalTarget: usePortal && document.querySelector('body'),
    menuPlacement: menuPlacement
  }, customProps));
};
GenericSelectControl.propTypes = genericSelectControlPropTypes;
GenericSelectControl.defaultProps = {
  getOptionLabel: function getOptionLabel(value) {
    return value.toString();
  },
  getOptionValue: identity,
  internalClassNames: {},
  noOptionsMessage: messages.noOptionsMessage,
  createNewMessage: messages.createNewMessage,
  readOnlySeparator: ', ',
  valid: true,
  usePortal: true,
  allowNewValue: function allowNewValue() {
    return false;
  }
};
GenericSelectControl.__docgenInfo = {
  componentName: "GenericSelectControl",
  packageName: "@jutro/components",
  description: "Generic component for dropdowns.\nYou can make your custom dropdown component inherit from the generic field component.\nThat way, your component will have default FieldComponent props, such as \u2019required' and methods (e.g. validation)`.",
  displayName: "GenericSelectControl",
  methods: [],
  actualName: "GenericSelectControl",
  props: {
    availableValues: {
      type: {
        name: "arrayOf",
        value: {
          name: "any"
        }
      },
      required: false,
      description: "Array of choice objects to display"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional style to apply to the component"
    },
    components: {
      type: {
        name: "shape",
        value: {
          ClearIndicator: {
            name: "elementType",
            required: false
          },
          Control: {
            name: "elementType",
            required: false
          },
          DropdownIndicator: {
            name: "elementType",
            required: false
          },
          IndicatorSeparator: {
            name: "elementType",
            required: false
          },
          Input: {
            name: "elementType",
            required: false
          },
          LoadingIndicator: {
            name: "elementType",
            required: false
          },
          Menu: {
            name: "elementType",
            required: false
          },
          MenuList: {
            name: "elementType",
            required: false
          },
          Option: {
            name: "elementType",
            required: false
          },
          Placeholder: {
            name: "elementType",
            required: false
          },
          Value: {
            name: "elementType",
            required: false
          },
          ValueContainer: {
            name: "elementType",
            required: false
          }
        }
      },
      required: false,
      description: "Components to customize separate parts of select"
    },
    internalClassNames: {
      type: {
        name: "custom",
        raw: "genericSelectInternalClassNamesShape"
      },
      required: false,
      description: "Map of CSS class names for individual parts of component's styles",
      defaultValue: {
        value: "{}",
        computed: false
      }
    },
    createNewMessage: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "The message to display when a new options is being created by the user.",
      defaultValue: {
        value: "messages.createNewMessage",
        computed: true
      }
    },
    defaultValue: {
      type: {
        name: "any"
      },
      required: false,
      description: "Set the default field value on render if there is a default value; needs onValueChange to work"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, this field is disabled"
    },
    filter: {
      type: {
        name: "func"
      },
      required: false,
      description: "Function to perform custom item filtering"
    },
    getOptionLabel: {
      type: {
        name: "func"
      },
      required: false,
      description: "Function to get label for each option",
      defaultValue: {
        value: "value => value.toString()",
        computed: false
      }
    },
    getOptionValue: {
      type: {
        name: "func"
      },
      required: false,
      description: "Function to get value for each option",
      defaultValue: {
        value: "identity",
        computed: true
      }
    },
    id: {
      type: {
        name: "string"
      },
      required: false,
      description: "Used to identify the component. applied to control and referenced by label"
    },
    isClearable: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true ClearIndicator will be shown"
    },
    allowNewValue: {
      type: {
        name: "func"
      },
      required: false,
      description: "Function to determine if creating new values is allowed",
      defaultValue: {
        value: "() => false",
        computed: false
      }
    },
    labelId: {
      type: {
        name: "string"
      },
      required: false,
      description: "Id of label element for this field"
    },
    loadValues: {
      type: {
        name: "func"
      },
      required: false,
      description: "Function for asynchronous data loading"
    },
    noOptionsMessage: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "The message to display where there are no options that match\nthe text the user enters.",
      defaultValue: {
        value: "messages.noOptionsMessage",
        computed: true
      }
    },
    onBlur: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback when blur event is fired"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback when click event is fired"
    },
    onCreateOption: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback when new option created\n(when new option created GenericSelectControl won't invoke onValueChange() if onCreateOption() provided)"
    },
    onFocus: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback when focus event is fired"
    },
    onInputChange: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback to be called when text input value changed"
    },
    onMenuClose: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback to be called when menu closed"
    },
    onMenuOpen: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback to be called when menu opened"
    },
    onKeyDown: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback to be called when key is pressed"
    },
    onValueChange: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback when value is changed; receives new value and (model or path) for this component"
    },
    placeholder: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Placeholder to display on empty component"
    },
    readOnly: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, this field is readonly"
    },
    readOnlySeparator: {
      type: {
        name: "string"
      },
      required: false,
      description: "Separator for the readonly value list",
      defaultValue: {
        value: "', '",
        computed: false
      }
    },
    required: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Specifies that the input field must be filled"
    },
    searchable: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Allows you to type in dropdown field to find options."
    },
    multiSelect: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Should component allow a multiple selection"
    },
    stickyIndicator: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Determines if dropdown indicator will be sticky"
    },
    valid: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If false component will be highlighted as invalid",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    value: {
      type: {
        name: "any"
      },
      required: false,
      description: "Value to display in control"
    },
    usePortal: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Default true; and if set to false, it will bypass menuPortalTarget prop in SelectComponent",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    isInitiallyOpen: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Should dropdown be initially opened"
    },
    controlShouldRenderValue: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Should control render value"
    },
    backspaceRemovesValue: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Should backspace remove value"
    }
  }
};