import _defineProperty from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import React, { createContext, useContext } from 'react';
import format from 'string-format';
import { createContextConsumerHOC } from '@jutro/platform';
import { IntlContext } from './IntlProvider';
var createTranslator = function createTranslator(intl) {
  return function (key, args) {
    if (!key) {
      return key;
    }
    if (typeof key !== 'string') {
      var mergedArgs = key.args ? _objectSpread({}, args, {}, key.args) : args;
      if (intl && key.id) {
        var formattedMessage = intl.formatMessage && intl.formatMessage(key, mergedArgs);
        return formattedMessage === key.id && key.defaultMessage === '' ? '' : formattedMessage;
      }
      if (typeof key.defaultMessage === 'string') {
        return mergedArgs !== undefined && mergedArgs !== null ? format(key.defaultMessage, mergedArgs) : key.defaultMessage;
      }
    }
    return typeof key === 'string' && args !== undefined && args !== null ? format(key, args) : key;
  };
};
export var TranslatorContext = createContext(createTranslator());
export var TranslatorProvider = function TranslatorProvider(_ref) {
  var children = _ref.children;
  var intl = useContext(IntlContext);
  return React.createElement(TranslatorContext.Provider, {
    value: createTranslator(intl)
  }, children);
};
export var withTranslator = function withTranslator(ComponentToWrap) {
  return createContextConsumerHOC({
    component: ComponentToWrap,
    context: TranslatorContext,
    displayName: "WithTranslator".concat(ComponentToWrap.displayName || ComponentToWrap.name),
    mapContextToProps: function mapContextToProps(props, translator) {
      return {
        translator: translator
      };
    }
  });
};
TranslatorProvider.__docgenInfo = {
  componentName: "TranslatorProvider",
  packageName: "@jutro/locale",
  description: "",
  displayName: "TranslatorProvider",
  methods: [],
  actualName: "TranslatorProvider"
};