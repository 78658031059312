import _defineProperty from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import omit from "lodash/omit";
import findKey from "lodash/findKey";
import { COLLAPSE, TOGGLE, COLLAPSE_ALL } from './types';
export function reducer(state, _ref) {
  var type = _ref.type,
    payload = _ref.payload;
  switch (type) {
    case COLLAPSE:
      {
        var viewIndex = findKey(state, function (_ref2) {
          var rowId = _ref2.rowId;
          return rowId === payload;
        });
        if (viewIndex === undefined) {
          return state;
        }
        return omit(state, [viewIndex]);
      }
    case TOGGLE:
      {
        var rowId = payload.rowId,
          _viewIndex = payload.viewIndex,
          allowMultiple = payload.allowMultiple;
        var oldExpanded = allowMultiple ? state : {};
        return state[_viewIndex] ? omit(state, [_viewIndex]) : _objectSpread({}, oldExpanded, _defineProperty({}, _viewIndex, {
          rowId: rowId
        }));
      }
    case COLLAPSE_ALL:
      return {};
    default:
      return state;
  }
}