import _defineProperty2 from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty2(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _someInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/some";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _Object$values from "@babel/runtime-corejs3/core-js-stable/object/values";
import { log } from '@jutro/logger';
import { HttpRequest } from './HttpRequest';
import { fastOptionsMerge } from './helper';
export var REQUEST_HANDLER_TYPE;
(function (REQUEST_HANDLER_TYPE) {
  REQUEST_HANDLER_TYPE["AUTH"] = "onAuth";
  REQUEST_HANDLER_TYPE["FETCH"] = "onFetch";
  REQUEST_HANDLER_TYPE["ENCODE"] = "onEncode";
  REQUEST_HANDLER_TYPE["ERROR"] = "onErrorResponse";
  REQUEST_HANDLER_TYPE["EXCEPTION"] = "onException";
  REQUEST_HANDLER_TYPE["RESPONSE"] = "onResponse";
  REQUEST_HANDLER_TYPE["TRACE"] = "onTrace";
})(REQUEST_HANDLER_TYPE || (REQUEST_HANDLER_TYPE = {}));
var REQUEST_HANDLER_TYPE_VALUES = _Object$values(REQUEST_HANDLER_TYPE);
export var HttpRequestBuilder = function HttpRequestBuilder() {
  var _this = this;
  var baseUrl = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  _classCallCheck(this, HttpRequestBuilder);
  _defineProperty(this, "baseUrl", void 0);
  _defineProperty(this, "options", void 0);
  _defineProperty(this, "handlers", void 0);
  _defineProperty(this, "addOptions", function (options) {
    _this.options.push(options);
    return _this;
  });
  _defineProperty(this, "addHandler", function (event, callback) {
    if (typeof event === 'string') {
      if (_includesInstanceProperty(REQUEST_HANDLER_TYPE_VALUES).call(REQUEST_HANDLER_TYPE_VALUES, event) && callback) {
        _this.handlers.push(_defineProperty2({}, event, callback));
      } else {
        log.warning("Invalid event type \"".concat(event, "\" supplied to \"HttpRequestBuilder.addHandler()\""));
      }
    } else if (_someInstanceProperty(REQUEST_HANDLER_TYPE_VALUES).call(REQUEST_HANDLER_TYPE_VALUES, function (t) {
      return event[t];
    })) {
      _this.handlers.push(event);
    } else {
      log.warning("Invalid event type supplied to \"HttpRequestBuilder.addHandler()\"");
    }
    return _this;
  });
  _defineProperty(this, "build", function () {
    var _context, _context2;
    var options = _this.options.length ? _reduceInstanceProperty(_context = _this.options).call(_context, function (list, values) {
      return fastOptionsMerge(list, values);
    }) : undefined;
    var handlers = _this.handlers.length ? _reduceInstanceProperty(_context2 = _this.handlers).call(_context2, function (list, values) {
      return _objectSpread({}, list, {}, values);
    }) : undefined;
    return new HttpRequest(_this.baseUrl, options, handlers);
  });
  this.baseUrl = baseUrl;
  this.handlers = [];
  this.options = [];
};