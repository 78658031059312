import _defineProperty from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from 'react';
import cx from 'classnames';
import { components } from 'react-select';
export var GenericSelectControlOption = function GenericSelectControlOption(props) {
  var genericSelectStyles = props.selectProps.genericSelectStyles;
  var optionClasses = cx(genericSelectStyles.option, _defineProperty(_defineProperty({}, genericSelectStyles.optionFocused, props.isFocused), genericSelectStyles.optionSelected, props.isSelected));
  var innerProps = _objectSpread({
    role: 'option'
  }, props.isSelected && {
    'aria-selected': 'true'
  }, {}, props.innerProps);
  return React.createElement(components.Option, _extends({}, props, {
    className: optionClasses,
    innerProps: innerProps
  }));
};
GenericSelectControlOption.__docgenInfo = {
  componentName: "GenericSelectControlOption",
  packageName: "@jutro/components",
  description: "",
  displayName: "GenericSelectControlOption",
  methods: [],
  actualName: "GenericSelectControlOption"
};