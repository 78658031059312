import _toConsumableArray from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import { useContext } from 'react';
var ContextConsumer = function ContextConsumer(_ref) {
  var contexts = _ref.contexts,
    renderProp = _ref.children,
    _ref$args = _ref.args,
    args = _ref$args === void 0 ? [] : _ref$args;
  return renderProp.apply(void 0, _toConsumableArray(_mapInstanceProperty(contexts).call(contexts, function (context) {
    return useContext(context);
  })).concat(_toConsumableArray(args)));
};
export default ContextConsumer;