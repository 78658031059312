import _defineProperty from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useEffect, useState } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { layoutShape } from '../../../layouts/Layout';
import { getLayoutComponent } from '../helpers/modalHelpers';
import styles from "./ModalBody.module.css";
var defaultLayout = {
  component: 'div'
};
var modalBodyPropTypes = {
  id: PropTypes.string,
  autoFocus: PropTypes.bool,
  contentLayout: PropTypes.oneOfType([PropTypes.shape({
    component: PropTypes.string.isRequired,
    componentProps: PropTypes.object
  }), layoutShape]),
  children: PropTypes.node
};
var focusableSelector = "input:not([disabled]), select:not([disabled]), textarea:not([disabled])";
export var ModalBody = function ModalBody(_ref) {
  var id = _ref.id,
    autoFocus = _ref.autoFocus,
    contentLayout = _ref.contentLayout,
    children = _ref.children;
  var modalBodyRef = React.createRef();
  var Layout = getLayoutComponent(contentLayout.component);
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    hasVerticalScrollbar = _useState2[0],
    setHasVerticalScrollbar = _useState2[1];
  var bodyClasses = cx(styles.modalBody, _defineProperty({}, styles.hasVerticalScrollbar, hasVerticalScrollbar));
  useEffect(function () {
    var modalBodyElement = modalBodyRef.current;
    setHasVerticalScrollbar(modalBodyElement.scrollHeight > modalBodyElement.clientHeight);
    if (modalBodyElement) {
      disableBodyScroll(modalBodyElement, {
        reserveScrollBarGap: true
      });
    }
    return function () {
      if (modalBodyElement) {
        enableBodyScroll(modalBodyElement);
      }
    };
  }, [modalBodyRef]);
  useEffect(function () {
    if (autoFocus) {
      var _modalBodyRef$current, _modalBodyRef$current2;
      (_modalBodyRef$current = modalBodyRef.current) === null || _modalBodyRef$current === void 0 ? void 0 : (_modalBodyRef$current2 = _modalBodyRef$current.querySelector(focusableSelector)) === null || _modalBodyRef$current2 === void 0 ? void 0 : _modalBodyRef$current2.focus();
    }
  }, [autoFocus, modalBodyRef.current]);
  return React.createElement("div", {
    id: id,
    ref: modalBodyRef,
    className: bodyClasses
  }, React.createElement(Layout, contentLayout.componentProps, children));
};
ModalBody.propTypes = modalBodyPropTypes;
ModalBody.defaultProps = {
  contentLayout: defaultLayout,
  autoFocus: true
};
ModalBody.__docgenInfo = {
  componentName: "ModalBody",
  packageName: "@jutro/components",
  description: "ModalBody",
  displayName: "ModalBody",
  methods: [],
  actualName: "ModalBody",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: false,
      description: "Used to identify modal body component."
    },
    autoFocus: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Optional flag indicating whether the ModalBody will autofocus to itself on open",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    contentLayout: {
      type: {
        name: "union",
        value: [{
          name: "shape",
          value: {
            component: {
              name: "string",
              required: true
            },
            componentProps: {
              name: "object",
              required: false
            }
          }
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              description: "Used to identify the component",
              required: false
            },
            repeat: {
              name: "union",
              value: [{
                name: "enum",
                value: [{
                  value: "'auto-fit'",
                  computed: false
                }, {
                  value: "'auto-fill'",
                  computed: false
                }]
              }, {
                name: "number"
              }, {
                name: "string"
              }],
              description: "repeat columns: 'auto-fit' or 'auto-fill' or number",
              required: false
            },
            gap: {
              name: "enum",
              computed: true,
              value: "Object.keys(gaps)",
              description: "gap between rows and columns: 'none', 'small', 'medium', 'large'",
              required: false
            },
            columns: {
              name: "array",
              description: "define explicit columns",
              required: false
            },
            colSpan: {
              name: "union",
              value: [{
                name: "number"
              }, {
                name: "string"
              }],
              required: false
            },
            colStart: {
              name: "union",
              value: [{
                name: "number"
              }, {
                name: "string"
              }],
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Defines the layout to be used with a 'component' property set to either Flex or Grid and 'componentProps' property to set properties for that layout component.",
      defaultValue: {
        value: "{\n    component: 'div',\n}",
        computed: false
      }
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "The component children wrapped by the modal body component."
    }
  }
};