import _slicedToArray from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createLocation } from 'history';
import memoize from 'memoize-one';
import { Link } from '@jutro/components';
import { intlMessageShape, intlToShape, IntlToShape } from '@jutro/prop-types';
import { metadataTypes } from '@jutro/uimetadata';
import { withAsyncAction } from '../AsyncAction/AsyncAction';
var getHref = function getHref(to, history) {
  if (!to) {
    return undefined;
  }
  var locationTo = typeof to === 'string' ? createLocation(to, null, null, history.location) : to;
  return history.createHref(locationTo);
};
var isRecalculationNeeded = function isRecalculationNeeded(_ref, _ref2) {
  var _ref3 = _slicedToArray(_ref, 2),
    to1 = _ref3[0],
    history1 = _ref3[1];
  var _ref4 = _slicedToArray(_ref2, 2),
    to2 = _ref4[0],
    history2 = _ref4[1];
  return to1 === to2 && history1.location === history2.location;
};
var AsyncLinkInternal = function (_Component) {
  _inherits(AsyncLinkInternal, _Component);
  var _super = _createSuper(AsyncLinkInternal);
  function AsyncLinkInternal() {
    var _this;
    _classCallCheck(this, AsyncLinkInternal);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    _this = _super.call.apply(_super, [this].concat(args));
    _defineProperty(_assertThisInitialized(_this), "getHref", memoize(getHref, isRecalculationNeeded));
    return _this;
  }
  _createClass(AsyncLinkInternal, [{
    key: "render",
    value: function render() {
      var _this$props = this.props,
        onClick = _this$props.onClick,
        to = _this$props.to,
        history = _this$props.history,
        children = _this$props.children,
        loc = _this$props.location,
        match = _this$props.match,
        loading = _this$props.loading,
        onTrigger = _this$props.onTrigger,
        rest = _objectWithoutProperties(_this$props, ["onClick", "to", "history", "children", "location", "match", "loading", "onTrigger"]);
      return React.createElement(Link, _extends({
        onClick: onClick,
        href: this.getHref(to, history)
      }, rest), children);
    }
  }]);
  return AsyncLinkInternal;
}(Component);
_defineProperty(AsyncLinkInternal, "propTypes", {
  failTo: intlToShape,
  failToMessage: PropTypes.string,
  message: intlMessageShape,
  replace: PropTypes.bool,
  to: intlToShape,
  toMessage: PropTypes.string,
  onTrigger: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string
});
_defineProperty(AsyncLinkInternal, "metadataType", metadataTypes.ACTION);
_defineProperty(AsyncLinkInternal, "displayName", 'AsyncLink');
export var AsyncLink = withAsyncAction(AsyncLinkInternal);
AsyncLinkInternal.__docgenInfo = {
  componentName: "AsyncLink",
  packageName: "@jutro/router",
  description: "AsyncLink is a Jutro component,\ndesigned following the principles of Link (React Router 4).\nAsyncLink invokes the trigger and wait for promise to be resolved or rejected.\nMeanwhile the promise is in execution, the component updates its content within the message provided by property {message}\nCase promise has been resolved, AsyncLink attempts to navigate to destination provided by property {to}\nCase promise has been rejected, AsyncLink attempts to navigate to destination provided by property {failTo}\n\nAsyncLink automatically adds the href for anchor tag having the to property defined; It follows the react-router-4 implementation:\nhttps://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/modules/Link.js",
  displayName: "AsyncLink",
  methods: [],
  actualName: "AsyncLinkInternal",
  metadataType: "action",
  props: {
    failTo: {
      type: {
        name: "custom",
        raw: "intlToShape"
      },
      required: false,
      description: "The destination path when promise is rejected; can be an object like <Link to>"
    },
    failToMessage: {
      type: {
        name: "string"
      },
      required: false,
      description: "The message shown when promise is rejected; shown if 'failTo' is not provided"
    },
    message: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "The message shown when executing trigger/promise"
    },
    replace: {
      type: {
        name: "bool"
      },
      required: false,
      description: "The replace prop will replace the current entry in the history stack"
    },
    to: {
      type: {
        name: "custom",
        raw: "intlToShape"
      },
      required: false,
      description: "The destination path when promise is resolved; can be an object like <Link to>"
    },
    toMessage: {
      type: {
        name: "string"
      },
      required: false,
      description: "The message shown when promise is resolved; shown if 'to' is not provided"
    },
    onTrigger: {
      type: {
        name: "func"
      },
      required: true,
      description: "The method used to trigger the promise"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: false,
      description: "The method to be called before (instead in case of event.preventDefault been called) built-in onClick handler"
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "The children elements to render inside of the AsyncLink"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    }
  }
};