import _defineProperty2 from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty2(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _Object$entries from "@babel/runtime-corejs3/core-js-stable/object/entries";
import _forEachInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/for-each";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import isObject from "lodash/isObject";
import cx from 'classnames';
import { InlineLoader } from '@jutro/components';
import { intlMessageShape, intlToShape, IntlToShape } from '@jutro/prop-types';
import { isPromise } from '@jutro/platform';
import { TranslatorContext, withTranslator, getUrlTranslatorAndSanitizer } from '@jutro/locale';
import styles from "./AsyncAction.module.css";
import { handleAction } from '../actionHelper';
export var propTypes = {
  failTo: intlToShape,
  failToMessage: PropTypes.string,
  message: intlMessageShape,
  replace: PropTypes.bool,
  to: intlToShape,
  toMessage: PropTypes.string,
  translator: PropTypes.func,
  onTrigger: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  allowNoLeadingSlash: PropTypes.bool
};
var defaultProps = {
  replace: false,
  allowNoLeadingSlash: false
};
var createHandleClick = function createHandleClick(instance) {
  return function (event) {
    var _instance$props = instance.props,
      onClick = _instance$props.onClick,
      target = _instance$props.target,
      allowNoLeadingSlash = _instance$props.allowNoLeadingSlash;
    if (onClick) {
      onClick(event);
    }
    if (!event.defaultPrevented && (event.button === undefined || event.button === 0) && !target) {
      var _context;
      event.preventDefault();
      var loading = instance.state.loading;
      if (loading) {
        return;
      }
      var _instance$props2 = instance.props,
        deprecatedTrigger = _instance$props2.trigger,
        onTrigger = _instance$props2.onTrigger,
        to = _instance$props2.to,
        failTo = _instance$props2.failTo,
        message = _instance$props2.message,
        toMessage = _instance$props2.toMessage,
        failToMessage = _instance$props2.failToMessage;
      var translator = instance.translator,
        urlTranslatorAndSanitizer = instance.urlTranslatorAndSanitizer;
      var trigger = onTrigger || deprecatedTrigger;
      var result = false;
      var resultException;
      try {
        result = trigger();
      } catch (ex) {
        resultException = ex;
      }
      var isActionObject = isObject(result) && !isPromise(result);
      var actionObject = isActionObject ? result : {
        name: 'unknown',
        result: result,
        resultException: resultException
      };
      var translatedTo = urlTranslatorAndSanitizer(to, allowNoLeadingSlash);
      var translatedFailTo = urlTranslatorAndSanitizer(failTo, allowNoLeadingSlash);
      _forEachInstanceProperty(_context = _Object$entries({
        progress: {
          message: message
        },
        success: {
          path: translatedTo,
          message: toMessage
        },
        failure: {
          path: translatedFailTo,
          message: failToMessage
        }
      })).call(_context, function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 2),
          key = _ref3[0],
          value = _ref3[1];
        if (!actionObject[key]) {
          actionObject[key] = value;
        }
      });
      handleAction(actionObject, instance, translator);
    }
  };
};
export var withAsyncAction = function withAsyncAction(WrappedComponent) {
  var AsyncAction = function (_Component) {
    _inherits(AsyncAction, _Component);
    var _super = _createSuper(AsyncAction);
    function AsyncAction() {
      var _this;
      _classCallCheck(this, AsyncAction);
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      _this = _super.call.apply(_super, [this].concat(args));
      _defineProperty(_assertThisInitialized(_this), "state", {
        loading: false
      });
      _defineProperty(_assertThisInitialized(_this), "translator", _this.context);
      _defineProperty(_assertThisInitialized(_this), "urlTranslatorAndSanitizer", getUrlTranslatorAndSanitizer(_this.context));
      _defineProperty(_assertThisInitialized(_this), "handleClick", createHandleClick(_assertThisInitialized(_this)));
      _defineProperty(_assertThisInitialized(_this), "renderContent", function () {
        var children = _this.props.children;
        var loading = _this.state.loading;
        if (!loading) {
          return children;
        }
        return React.createElement(React.Fragment, null, React.createElement("div", {
          className: styles.underLoader
        }, _this.translator(children)), _this.renderLoaderContent());
      });
      return _this;
    }
    _createClass(AsyncAction, [{
      key: "renderLoaderContent",
      value: function renderLoaderContent() {
        var _context2;
        var message = this.state.message || this.props.message;
        var loading = this.state.loading;
        if (!message) {
          return React.createElement(InlineLoader, {
            loading: loading,
            className: styles.loaderOnly
          });
        }
        var ellipsisClasses = _mapInstanceProperty(_context2 = [null, styles.ellipsisSecondDot, styles.ellipsisThirdDot]).call(_context2, function (specificClassName) {
          return cx(styles.ellipsis, specificClassName);
        });
        return React.createElement("div", {
          className: styles.loader,
          role: "alert"
        }, "".concat(this.translator(message), " "), _mapInstanceProperty(ellipsisClasses).call(ellipsisClasses, function (dotClassName, index) {
          return React.createElement("span", {
            key: index,
            className: dotClassName
          }, ".");
        }));
      }
    }, {
      key: "render",
      value: function render() {
        var _this$props = this.props,
          children = _this$props.children,
          staticContext = _this$props.staticContext,
          replace = _this$props.replace,
          props = _objectWithoutProperties(_this$props, ["children", "staticContext", "replace"]);
        var _ref = this.state || this.props,
          loading = _ref.loading;
        return React.createElement(WrappedComponent, _extends({}, props, {
          onClick: this.handleClick,
          loading: loading
        }), this.renderContent());
      }
    }]);
    return AsyncAction;
  }(Component);
  _defineProperty(AsyncAction, "propTypes", _objectSpread({}, propTypes, {}, WrappedComponent.propTypes));
  _defineProperty(AsyncAction, "defaultProps", _objectSpread({}, defaultProps, {}, WrappedComponent.defaultProps));
  _defineProperty(AsyncAction, "contextType", TranslatorContext);
  var wrapped = withRouter(withTranslator(AsyncAction));
  wrapped.displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  wrapped.WrappedComponent = WrappedComponent;
  return wrapped;
};