import _defineProperty from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _extends from "@babel/runtime-corejs3/helpers/extends";
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import isString from "lodash/isString";
import isNumber from "lodash/isNumber";
import isPlainObject from "lodash/isPlainObject";
import { FormattedDate as IntlFormattedDate, FormattedTime as IntlFormattedTime } from 'react-intl';
import { isDate } from 'util';
import { dateValueShape, intlMessageShape } from '@jutro/prop-types';
import { TranslatorContext } from '@jutro/locale';
import { FieldComponent } from '../inputs/FieldComponent/FieldComponent';
var NUMERIC = 'numeric';
var SHORT = 'short';
var LONG = 'long';
var vshortDateFormat = {
  year: NUMERIC,
  month: NUMERIC,
  day: NUMERIC
};
var shortDateFormat = {
  year: NUMERIC,
  month: SHORT,
  day: NUMERIC
};
var longDateFormat = {
  year: NUMERIC,
  month: LONG,
  day: NUMERIC
};
var abbreviatedDateFormat = {
  year: NUMERIC,
  month: SHORT,
  day: NUMERIC,
  weekday: SHORT
};
var fullDateFormat = {
  year: NUMERIC,
  month: LONG,
  day: NUMERIC,
  weekday: LONG
};
var dateFormatMap = {
  vshort: vshortDateFormat,
  short: shortDateFormat,
  long: longDateFormat,
  abbreviated: abbreviatedDateFormat,
  full: fullDateFormat
};
var timeFormat = {
  hour: NUMERIC,
  minute: NUMERIC
};
function getDateFormat(format) {
  return dateFormatMap[format] || shortDateFormat;
}
function getTimeFormat() {
  return timeFormat;
}
function getDateToFormat(date, value, showTime) {
  var dateValue = date || value;
  if (!dateValue) {
    return undefined;
  }
  var hasTime;
  if (isString(dateValue)) {
    hasTime = dateValue.length > 10;
    dateValue = new Date(dateValue);
  } else if (isNumber(dateValue)) {
    dateValue = new Date(dateValue);
  } else if (isPlainObject(dateValue) && !isDate(dateValue)) {
    var _dateValue = dateValue,
      year = _dateValue.year,
      month = _dateValue.month,
      day = _dateValue.day,
      _dateValue$hour = _dateValue.hour,
      hour = _dateValue$hour === void 0 ? 0 : _dateValue$hour,
      _dateValue$minute = _dateValue.minute,
      minute = _dateValue$minute === void 0 ? 0 : _dateValue$minute;
    if (year || month || day) {
      dateValue = new Date(Date.UTC(year, month, day, hour, minute));
    }
  } else if (!isDate(dateValue)) {
    return undefined;
  }
  if (!(hasTime || showTime)) {
    var timestamp = dateValue.getTime() + dateValue.getTimezoneOffset() * 60000;
    dateValue = new Date(timestamp);
  }
  return dateValue;
}
function getFormattedDateProps(props, date) {
  var _FormattedDate$defaul = _objectSpread({}, FormattedDate.defaultProps, {}, props),
    propDate = _FormattedDate$defaul.date,
    value = _FormattedDate$defaul.value,
    placeholder = _FormattedDate$defaul.placeholder,
    format = _FormattedDate$defaul.format,
    showTime = _FormattedDate$defaul.showTime,
    onlyTime = _FormattedDate$defaul.onlyTime;
  var dateObj = getDateToFormat(date || propDate, value, showTime);
  if (!dateObj) {
    return {
      placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : FieldComponent.defaultROEmptyValue
    };
  }
  var dateProps = getDateFormat(format);
  var timeProps = showTime && getTimeFormat();
  return {
    dateObj: dateObj,
    dateProps: dateProps,
    timeProps: timeProps,
    onlyTime: onlyTime
  };
}
export var formattedDatePropTypes = {
  date: PropTypes.instanceOf(Date),
  value: dateValueShape,
  placeholder: intlMessageShape,
  format: PropTypes.oneOf([SHORT, LONG, 'vshort', 'abbreviated', 'full']),
  showTime: PropTypes.bool,
  onlyTime: PropTypes.bool
};
export var FormattedDate = function FormattedDate(props) {
  var _getFormattedDateProp = getFormattedDateProps(props),
    placeholder = _getFormattedDateProp.placeholder,
    dateObj = _getFormattedDateProp.dateObj,
    dateProps = _getFormattedDateProp.dateProps,
    timeProps = _getFormattedDateProp.timeProps,
    onlyTime = _getFormattedDateProp.onlyTime;
  var translator = useContext(TranslatorContext);
  if (placeholder) {
    return translator(placeholder);
  }
  if (onlyTime) {
    return React.createElement(IntlFormattedTime, _extends({
      value: dateObj
    }, timeProps));
  }
  return React.createElement(IntlFormattedDate, _extends({
    value: dateObj
  }, dateProps, timeProps));
};
FormattedDate.propTypes = formattedDatePropTypes;
FormattedDate.defaultProps = {
  format: LONG,
  placeholder: FieldComponent.defaultROEmptyValue,
  showTime: false,
  onlyTime: false
};
export function formatDate(intl, props, date) {
  var _getFormattedDateProp2 = getFormattedDateProps(props, date),
    placeholder = _getFormattedDateProp2.placeholder,
    dateObj = _getFormattedDateProp2.dateObj,
    dateProps = _getFormattedDateProp2.dateProps,
    timeProps = _getFormattedDateProp2.timeProps;
  if (placeholder) {
    return placeholder;
  }
  return intl.formatDate(dateObj, _objectSpread({}, dateProps, {}, timeProps));
}
FormattedDate.__docgenInfo = {
  componentName: "FormattedDate",
  packageName: "@jutro/components",
  description: "The `FormattedDate` component is used to render `Date` values\nlocalized and in specific preset formats, e.g. short or long.",
  displayName: "FormattedDate",
  methods: [],
  actualName: "FormattedDate",
  metadataType: "element",
  props: {
    date: {
      type: {
        name: "instanceOf",
        value: "Date"
      },
      required: false,
      description: "The date to display"
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }, {
          name: "instanceOf",
          value: "Date"
        }, {
          name: "shape",
          value: {
            year: {
              name: "number",
              required: true
            },
            month: {
              name: "number",
              required: true
            },
            day: {
              name: "number",
              required: true
            },
            hour: {
              name: "number",
              required: false
            },
            minute: {
              name: "number",
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Value to display in the format of string|number|object"
    },
    placeholder: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "The string to display if 'date' is undefined/null",
      defaultValue: {
        value: "FieldComponent.defaultROEmptyValue",
        computed: true
      }
    },
    format: {
      type: {
        name: "enum",
        value: [{
          value: "'short'",
          computed: false
        }, {
          value: "'long'",
          computed: false
        }, {
          value: "'vshort'",
          computed: false
        }, {
          value: "'abbreviated'",
          computed: false
        }, {
          value: "'full'",
          computed: false
        }]
      },
      required: false,
      description: "The date format: 'vshort', 'short', 'long', 'abbreviated', 'full'",
      defaultValue: {
        value: "'long'",
        computed: false
      }
    },
    showTime: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, displays the time with the date",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    onlyTime: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, displays only time",
      defaultValue: {
        value: "false",
        computed: false
      }
    }
  }
};