import _defineProperty from "/home/sa_jenkins/workspace/PROD10_Frontend_Build_CEAMP/V11.4.1/CEAMP/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useContext, useState, useCallback } from 'react';
import cx from 'classnames';
import { LookupField } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import styles from "./SearchField.module.css";
export var SearchField = function SearchField(props) {
  var isDarkTheme = props.isDarkTheme,
    onSearchValueChange = props.onSearchValueChange,
    onLoadValues = props.onLoadValues,
    searchAvailableValues = props.searchAvailableValues,
    searchFieldPlaceholder = props.searchFieldPlaceholder;
  var translator = useContext(TranslatorContext);
  var _useState = useState('a'),
    _useState2 = _slicedToArray(_useState, 2),
    searchValue = _useState2[0],
    setSearchValue = _useState2[1];
  var onValueChange = useCallback(function (val) {
    setSearchValue(val);
    if (onSearchValueChange) {
      onSearchValueChange(val);
    }
  }, [onSearchValueChange]);
  return onSearchValueChange && React.createElement(LookupField, {
    id: "search",
    label: "search",
    labelId: "search_63202513",
    value: searchValue,
    onValueChange: onValueChange,
    onLoadValues: onLoadValues,
    layout: "full-width",
    className: cx(_defineProperty(_defineProperty({}, styles.darkSearchContainer, isDarkTheme), styles.searchContainer, !isDarkTheme)),
    availableValues: searchAvailableValues,
    placeholder: translator(searchFieldPlaceholder) || null,
    hideLabel: true,
    internalClassNames: styles
  });
};
SearchField.__docgenInfo = {
  componentName: "SearchField",
  packageName: "@jutro/router",
  description: "",
  displayName: "SearchField",
  methods: [],
  actualName: "SearchField"
};